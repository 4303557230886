import React, { useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { useMetadata } from '../../../providers/MetadataContext';

function SponsorDesignationField({ value, setValue }) {
  const metadata = useMetadata();

  const [selected, setSelected] = useState(
    (metadata?.sponsor?.lists?.designations || []).find(
      (option) => option.entity_id === value
    )
  );

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue.entity_id);
  };
  console.log(value);
  console.log(selected);
  return (
    <Box>
      <Autocomplete
        sx={{ minWidth: '300px' }}
        id='designation-field'
        options={metadata?.sponsor?.lists?.designations || []}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        renderInput={(params) => <TextField {...params} label='Designation' />}
      />
    </Box>
  );
}

export default SponsorDesignationField;
