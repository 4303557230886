import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingMask from '../../shared/LoadingMask';
import { useUser } from '../../../providers/UserContext';

import { getDeal } from '../../../actions/deal';
import DealDesktop from './DealDesktop';
import DealMobile from './DealMobile';

function Deal() {
  const user = useUser();
  const navigate = useNavigate();
  const { deal_id } = useParams();
  const [deal, setDeal] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user.entity_id) {
      if (deal_id && deal === null) {
        getDeal(deal_id)
          .then((payload) => {
            setDeal(payload);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            navigate('/404');
          });
      } else {
        setLoading(false);
      }
    }
  }, [user, deal_id, deal, navigate]);

  if (loading) {
    return <LoadingMask />;
  }

  if (user?.entity_id && deal.accredited_only && !user?.accredited) {
    return (
      <Box sx={{ width: '100%' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                Only accredited investors can view this deal.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Container sx={{ padding: '15px' }}>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
          <DealDesktop deal={deal} />
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
          <DealMobile deal={deal} />
        </Box>
      </Container>
    </Box>
  );
}

export default Deal;
