import React, { memo, useCallback } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { IconButton, Box, Paper } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { deleteAsset } from '../../../../actions/assets';

const Image = memo(function Image({
  id,
  url,
  moveImage,
  findImage,
  removeImage,
}) {
  const handleDelete = useCallback(async () => {
    const path = url.match(/^https:\/\/.*\/static\/(?<path>.*)$/)?.groups?.path;
    if (path) {
      await deleteAsset(path);
    }

    removeImage(id);
  }, [removeImage, url, id]);
  const originalIndex = findImage(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'image',
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveImage(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveImage]
  );
  const [, drop] = useDrop(
    () => ({
      accept: 'image',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findImage(id);
          moveImage(draggedId, overIndex);
        }
      },
    }),
    [findImage, moveImage]
  );
  const opacity = isDragging ? 0 : 1;
  return (
    <Grid
      xs={4}
      ref={(node) => drag(drop(node))}
      sx={{
        cursor: 'move',
        opacity,
      }}
    >
      <Paper
        sx={{ width: '100%', height: '100%', padding: '10px' }}
        variant='outlined'
      >
        <Box display='flex' justifyContent='flex-end'>
          <IconButton onClick={handleDelete}>
            <HighlightOffOutlinedIcon color={'error'} />
          </IconButton>
        </Box>
        <img
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
          }}
          alt={id}
          src={url}
        />
      </Paper>
    </Grid>
  );
});
export default Image;
