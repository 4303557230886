import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../../providers/actionTypes';
import SponsorAssetTypeField from './SponsorAssetTypesField';
import SponsorInvestmentBenefitsField from './SponsorInvestmentBenefitsField';
import SponsorInvestmentTypesField from './SponsorInvestmentTypesField';
import SponsorInvestmentStrategiesField from './SponsorInvestmentStrategiesField';
import SponsorPrimaryMarketsField from './SponsorPrimaryMarketsField';
import STATUSES from '../../../shared/statuses';

function SponsorInvestmentThesis({ admin, sponsor, setValues }) {
  const [revision, setRevision] = useState(0);
  const formErrorDispatch = useFormErrorDispatch();
  const [asset_sub_types, setAssetSubTypes] = useState(
    sponsor ? sponsor.asset_sub_types : []
  );
  const [investment_types, setInvestmentTypes] = useState(
    sponsor ? sponsor.investment_types : []
  );
  const [investment_benefits, setInvestmentBenefits] = useState(
    sponsor ? sponsor.investment_benefits : []
  );
  const [investment_strategies, setInvestmentStrategies] = useState(
    sponsor ? sponsor.investment_strategies : []
  );
  const [primary_markets, setPrimaryMarkets] = useState(
    sponsor ? sponsor.primary_markets : []
  );

  useEffect(() => {
    if (false) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
      setValues({
        asset_sub_types,
        investment_types,
        investment_benefits,
        investment_strategies,
        primary_markets,
      });
    }
  }, [
    formErrorDispatch,
    setValues,
    asset_sub_types,
    investment_types,
    investment_benefits,
    investment_strategies,
    primary_markets,
  ]);

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [sponsor]);

  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 600,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Investment Specialties</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorInvestmentBenefitsField
                  key={`investment_benefits_${revision}`}
                  value={investment_benefits}
                  setValue={setInvestmentBenefits}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorInvestmentTypesField
                  key={`investment_types_${revision}`}
                  value={investment_types}
                  setValue={setInvestmentTypes}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorInvestmentStrategiesField
                  key={`investment_strategies_${revision}`}
                  value={investment_strategies}
                  setValue={setInvestmentStrategies}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorPrimaryMarketsField
                  key={`primary_markets_${revision}`}
                  value={primary_markets}
                  setValue={setPrimaryMarkets}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorAssetTypeField
                  key={`asset_sub_types_${revision}`}
                  value={asset_sub_types}
                  setValue={setAssetSubTypes}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SponsorInvestmentThesis;
