const STATUSES = {
  ACTIVE: 'active',
  PENDING: 'pending',
  DRAFT: 'draft',
  ARCHIVED: 'archived',
  NEW: 'new',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export default STATUSES;
