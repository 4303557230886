import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useStytchUser } from '@stytch/react';

import { FormErrorProvider } from '../../../providers/FormErrorContext';
import AccountContactForm from '../AccountContactForm';
import InvestorDetailsForm from '../InvestorDetailsForm';
import { USER_FETCH } from '../../../providers/actionTypes';
import NewUserDesktopWizard from './NewUserDesktopWizard';
import NewUserMobileWizard from './NewUserMobileWizard';
import { useUser, useUserDispatch } from '../../../providers/UserContext';
import { updateUser } from '../../../actions/user';

import TermsAndConditions from '../TermsAndConditions';
import LoadingMask from '../../shared/LoadingMask';

function NewUserOnboarding() {
  const [revision, setRevision] = useState(0);
  const navigate = useNavigate();
  const user = useUser();
  const { user: stytchUser } = useStytchUser();

  const [userUpdate, setUserUpdate] = useState({});
  const userDispatch = useUserDispatch();

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [user]);

  const handleUserUpdate = async () => {
    const payload = Object.fromEntries(
      Object.entries({ ...user, ...userUpdate }).filter(
        ([k, v]) => v !== undefined && v !== ''
      )
    );
    const updated_user = await updateUser(payload);
    userDispatch({ type: USER_FETCH, payload: updated_user });
  };

  const steps = [
    {
      name: 'Contact Info',
      component: (
        <AccountContactForm
          key={`account_contact_${revision}`}
          setValues={setUserUpdate}
        />
      ),
    },
    {
      name: 'Investor Details',
      component: (
        <InvestorDetailsForm
          key={`account_investor_details_${revision}`}
          setValues={setUserUpdate}
        />
      ),
      optional: true,
    },
    {
      name: 'Terms and Conditions',
      component: (
        <TermsAndConditions
          key={`terms_and_conditions_${revision}`}
          setValues={setUserUpdate}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!stytchUser) {
      navigate('/login');
    }

    if (user.terms_version) {
      navigate('/');
    }
  }, [stytchUser, user, navigate]);

  if (!user.entity_id) {
    return <LoadingMask />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={4}>
          <Grid xs={12} md={12} lg={12}>
            <Typography variant='h4'>Account Signup</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <FormErrorProvider>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                <NewUserDesktopWizard steps={steps} onNext={handleUserUpdate} />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
                <NewUserMobileWizard steps={steps} onNext={handleUserUpdate} />{' '}
              </Box>
            </FormErrorProvider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default NewUserOnboarding;
