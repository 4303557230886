import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SponsorNameField({
  value,
  error,
  setValue,
  setError,
  ...rest
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (/[#^$<>+%!`&*'"|{}?/\\:=@]/.test(newValue)) {
      setError('Name cannot contain #^$<>+%!`&*\'"|{}?/\\:=@');
    } else if (newValue === '') {
      setError('Name cannot be blank');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          {...rest}
          label='Name'
          id='name'
          aria-describedby='name-helper-text'
          onChange={handleChange}
          error={Boolean(error || value === '')}
          value={value}
          variant='standard'
          required
        />
        {error ? (
          <FormHelperText id='name-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='name-helper-text'>
            Enter the sponsor name
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
