import React, { useState } from 'react';

import {
  Accordion,
  Typography,
  AccordionSummary,
  Stack,
  AccordionDetails,
  AccordionActions,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

import InvestmentStrategyFilter from './InvestmentStrategyFilter';
import AssetTypeFilter from './AssetTypeFilter';
import InvestmentBenefitsFilter from './InvestmentBenefitsFilter';

import DesignationFilter from './DesignationFilter';
import InvestmentTypesFilter from './InvestmentTypesFilter';
import PrimaryMarketsFilter from './PrimaryMarketsFilter';

function SponsorSearchFilters({ values, setValues }) {
  const [revision, setRevision] = useState(0);
  const [asset_sub_types, setAssetSubTypes] = useState(
    values?.asset_sub_types || []
  );
  const [investment_types, setInvestmentTypes] = useState(
    values?.investment_types || []
  );
  const [investment_benefits, setInvestmentBenefits] = useState(
    values?.investment_benefits || []
  );
  const [investment_strategies, setInvestmentStrategies] = useState(
    values?.investment_strategies || []
  );
  const [primary_markets, setPrimaryMarkets] = useState(
    values?.primary_markets || []
  );
  const [designations, setDesignations] = useState(values?.designations || []);
  const [expanded, setExpanded] = useState(false);

  const setFilters = () => {
    setValues({
      asset_sub_types,
      investment_types,
      investment_benefits,
      investment_strategies,
      primary_markets,
      designations,
    });
    setExpanded(false);
    setRevision((prevState) => prevState + 1);
  };

  const resetFilters = () => {
    setAssetSubTypes(values?.asset_sub_types || []);
    setInvestmentTypes(values?.investment_types || []);
    setInvestmentBenefits(values?.investment_benefits || []);
    setInvestmentStrategies(values?.investment_strategies || []);
    setPrimaryMarkets(values?.primary_markets || []);
    setDesignations(values?.designations || []);
    setExpanded(false);
    setRevision((prevState) => prevState + 1);
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Stack direction='row' spacing={2} alignItems={'center'}>
          <FilterListOutlinedIcon />
          <Typography variant='h6' paragraph gutterBottom>
            Filters
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} xs={12} md={12} lg={12}>
          <Grid lg={8} md={12} xs={12}>
            <AssetTypeFilter
              key={`asset_sub_types_filter_${revision}`}
              value={asset_sub_types}
              setValue={setAssetSubTypes}
            />
          </Grid>
          <Grid lg={4} md={4} xs={12}>
            <InvestmentTypesFilter
              key={`investment_types_filter_${revision}`}
              value={investment_types}
              setValue={setInvestmentTypes}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <InvestmentStrategyFilter
              key={`investment_strategies_filter_${revision}`}
              value={investment_strategies}
              setValue={setInvestmentStrategies}
            />
          </Grid>

          <Grid lg={3} md={4} xs={12}>
            <InvestmentBenefitsFilter
              key={`investment_benefits_filter_${revision}`}
              value={investment_benefits}
              setValue={setInvestmentBenefits}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <DesignationFilter
              key={`designations_filter_${revision}`}
              value={designations}
              setValue={setDesignations}
            />
          </Grid>

          <Grid lg={3} md={4} xs={12}>
            <PrimaryMarketsFilter
              key={`primary_markets_filter_${revision}`}
              value={primary_markets}
              setValue={setPrimaryMarkets}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button variant='outlined' color='inherit' onClick={resetFilters}>
          Reset
        </Button>
        <Button variant='outlined' color='primary' onClick={setFilters}>
          Apply
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

export default SponsorSearchFilters;
