import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { getSponsorTeamMembers } from '../../../actions/sponsorTeamMember';
import SponsorTeamMemberCard from '../SponsorForm/SponsorTeam/SponsorTeamMembers/SponsorTeamMemberCard';

function SponsorAbout({ sponsor }) {
  const [team_members, setTeamMembers] = useState(undefined);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (sponsor?.entity_id && !team_members && !fetching) {
      setFetching(true);
      getSponsorTeamMembers(sponsor.entity_id).then((payload) => {
        setTeamMembers(payload);
        setFetching(false);
      });
    }
  }, [team_members, fetching, setFetching, sponsor]);
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h4'>Overview</Typography>
      </Grid>
      <Grid xs={12} md={8} lg={8}>
        <Typography variant='h6'>About</Typography>
        <br />
        <Typography variant='body1'>
          <Markdown>{sponsor.about}</Markdown>
        </Typography>
      </Grid>

      <Grid xs={12} md={4} lg={4}>
        <Typography
          sx={{ display: { xs: 'flex', md: 'none' } }}
          variant='h6'
          gutterBottom
        >
          Team
        </Typography>
        <Grid container spacing={2}>
          {team_members?.map((team_member) => {
            return (
              <Grid xs={12} md={12} lg={12}>
                <SponsorTeamMemberCard team_member={team_member} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SponsorAbout;
