import React, { useState } from 'react';

import { Box, Chip, TextField, Autocomplete } from '@mui/material';
import { useMetadata } from '../../../../providers/MetadataContext';

function SponsorAssetTypesField({ value, setValue, ...rest }) {
  const metadata = useMetadata();
  const [selected, setSelected] = useState(
    value.map((entity_id) => {
      return (metadata?.sponsor?.lists?.asset_types || []).find(
        (option) => option.entity_id === entity_id
      );
    })
  );

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue.map((option) => option.entity_id));
  };

  return (
    <Box>
      <Autocomplete
        {...rest}
        multiple
        id='asset-types-field'
        options={metadata?.sponsor?.lists?.asset_types || []}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        groupBy={(option) => option.group}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={`${option.group}:${option.name}`}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} label='Asset Types' />}
      />
    </Box>
  );
}

export default SponsorAssetTypesField;
