import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import { deleteUser } from '../../../actions/user';
import { useStytch } from '@stytch/react';
import { useUser, useUserDispatch } from '../../../providers/UserContext';
import { USER_LOGOUT } from '../../../providers/actionTypes';

export default function AccountDelete() {
  const navigate = useNavigate();
  const user = useUser();
  const dispatch = useUserDispatch();
  const [loading, setLoading] = useState(false);
  const stytch = useStytch();

  const handleDelete = async () => {
    setLoading(true);
    await deleteUser();
    stytch.session.revoke();
    dispatch({ type: USER_LOGOUT });
    setLoading(false);
    navigate('/');
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid xs={12} md={12} lg={12}>
          <Typography variant='h6' color='error'>
            Delete Account
          </Typography>
          <Divider />
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          {user.primary_sponsor ? (
            <Typography variant='body1'>
              All active deals must be closed and Sponsor organizations removed
              before account can be deleted.
            </Typography>
          ) : (
            <Typography variant='body1'>
              Deleting your account will permanently clear all data associated
              with your account. This action is not recoverable.
            </Typography>
          )}
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <LoadingButton
            loading={loading}
            disabled={Boolean(user.primary_sponsor)}
            onClick={handleDelete}
            variant='contained'
            color='error'
          >
            Delete your account
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
