import React, { useState } from 'react';
import Markdown from 'react-markdown';

import { Tabs, Box, Tab, Typography } from '@mui/material';
import DealManagement from './DealManagement';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import GroupsIcon from '@mui/icons-material/Groups';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import DealMarketAnalysis from './DealMarketAnalysis';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function DealNarrative({ deal }) {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          width: '100%',
          bgcolor: 'background.paper',
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='Narrative Details'
        >
          <Tab label='Summary' />
          <Tab label='Business Plan' />
          <Tab label='Property' />
          <Tab label='Market' />
          <Tab label='Management' />
          <Tab label='Other' />
        </Tabs>
        <CustomTabPanel value={tab} index={0}>
          <Typography variant='body1'>
            <Markdown>{deal.exec_summary}</Markdown>
          </Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <Typography variant='body1'>
            <Markdown>{deal.business_plan}</Markdown>
          </Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <Typography variant='body1'>
            <Markdown>{deal.property_description}</Markdown>
          </Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3}>
          <DealMarketAnalysis deal={deal} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={4}>
          <DealManagement deal={deal} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={5}>
          <Typography variant='body1'>
            <Markdown>{deal.other_narrative}</Markdown>
          </Typography>
        </CustomTabPanel>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          width: '100%',
          bgcolor: 'background.paper',
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label='Narrative Details'
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          <Tab icon={<SummarizeOutlinedIcon />} label='Summary' />
          <Tab icon={<AddBusinessIcon />} label='Business Plan' />
          <Tab icon={<BusinessIcon />} label='Property' />
          <Tab icon={<LocationCityIcon />} label='Market' />
          <Tab icon={<GroupsIcon />} label='Management' />
          <Tab icon={<TextSnippetOutlinedIcon />} label='Other' />
        </Tabs>
        <CustomTabPanel value={tab} index={0}>
          <Typography variant='body1'>{deal.exec_summary}</Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <Typography variant='body1'>{deal.business_plan}</Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <Typography variant='body1'>{deal.property_description}</Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3}>
          <DealMarketAnalysis deal={deal} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={4}>
          <DealManagement deal={deal} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={5}>
          <Typography variant='body1'>{deal.other_narrative}</Typography>
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default DealNarrative;
