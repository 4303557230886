const flattenAssetTypes = (asset_types) => {
  const asset_sub_types = [];
  asset_types.forEach((asset_type) => {
    asset_type.sub_types.forEach(({ entity_id, name }) => {
      asset_sub_types.push({
        entity_id,
        name,
        group: asset_type.name,
      });
    });
  });
  return asset_sub_types;
};
export default flattenAssetTypes;
