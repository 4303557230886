import api from '../apis/api';

export const getSponsors = async () => {
  const resp = await api.get('/sponsor');
  return resp.data;
};

export const getSponsor = async (sponsor_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}`);
  return resp.data;
};

export const createSponsor = async (values) => {
  const resp = await api.post(`/sponsor/`, values);
  return resp.data;
};

export const updateSponsor = async (sponsor_id, values) => {
  const resp = await api.put(`/sponsor/${sponsor_id}`, values);
  return resp.data;
};

export const deleteSponsor = async (sponsor_id) => {
  const resp = await api.delete(`/sponsor/${sponsor_id}`);
  return resp.data;
};

export const submitSponsor = async (sponsor_id) => {
  const resp = await api.patch(`/sponsor/${sponsor_id}/submit`);
  return resp.data;
};

export const withdrawSponsor = async (sponsor_id) => {
  const resp = await api.patch(`/sponsor/${sponsor_id}/withdraw`);
  return resp.data;
};

export const getActiveSponsors = async (
  limit = 20,
  offset = 0,
  filters = {}
) => {
  const params_list = [
    ['limit', limit],
    ['offset', offset],
  ];
  Object.entries(filters).forEach(([k, v]) => {
    if (v?.length) {
      v.forEach((id) => {
        params_list.push([k, id]);
      });
    }
  });
  const params = new URLSearchParams(params_list);
  const resp = await api.get(`/search/sponsor?${params.toString()}`);

  return resp.data;
};
