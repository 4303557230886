import React, { useState } from 'react';

import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import UploadButton from '../../../shared/UploadButton';
import { deleteAsset, putAsset } from '../../../../actions/assets';
import getFile from '../../../../util/getFile';

export default function SponsorLogoRectangleField({
  sponsor_id,
  value,
  setValue,
  ...rest
}) {
  const [image, setImage] = useState();

  const handleChange = async (e) => {
    const image = await getFile(e.target.files[0]);
    const suffix = image.name.split('.')[1];
    const file_name = suffix ? `logo_rectangle.${suffix}` : 'logo_rectangle';
    const path = await putAsset(
      `sponsor/${sponsor_id}/${file_name}`,
      image.type,
      image.contents
    );
    const old_path = value?.match(/^https:\/\/.*\/static\/(?<path>.*)$/)?.groups
      ?.path;
    if (old_path && old_path !== path) {
      await deleteAsset(old_path);
    }
    setValue(`${process.env.REACT_APP_API_URL}/v1/static/${path}`);
    setImage(image);
  };

  if (value) {
    return (
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Typography variant='body1'>Background Photo</Typography>
        </Grid>
        <Grid xs={12} md={6} lg={6}>
          <img
            style={{
              width: { lg: '600px', md: '400px', sm: '400px' },
              height: { lg: '150px', md: '100px', sm: '100px' },
              maxWidth: '100%',
            }}
            alt='uploaded'
            src={value}
          />
        </Grid>
        <Grid xs={12} md={12} lg={12} display='flex' alignItems='center'>
          <UploadButton {...rest} name='Upload Again' onChange={handleChange} />
        </Grid>
      </Grid>
    );
  }
  if (image) {
    return (
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Typography variant='body1'>Background Photo</Typography>
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <img
            style={{
              width: { lg: '600px', md: '400px', sm: '400px' },
              height: { lg: '150px', md: '100px', sm: '100px' },
              maxWidth: '100%',
            }}
            alt='uploaded'
            src={image.base64contents}
          />
        </Grid>
        <Grid xs={12} md={12} lg={12} display='flex' alignItems='center'>
          <UploadButton {...rest} name='Upload Again' onChange={handleChange} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant='body1'>Background Photo</Typography>
      <Paper
        variant='outlined'
        sx={{
          width: { lg: '600px', md: '400px', sm: '400px' },
          height: { lg: '150px', md: '100px', sm: '100px' },
          minHeight: '100px',
          maxWidth: '100%',
          alignContent: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UploadButton {...rest} name='Upload File' onChange={handleChange} />
      </Paper>
    </>
  );
}
