import React, { useState, forwardRef } from 'react';

import {
  Container,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// import { useStytchUser } from '@stytch/react';
// import { useUser } from '../../providers/UserContext';

import SponsorList from './SponsorList';
import { useMetadata } from '../../../providers/MetadataContext';
import LoadingMask from '../../shared/LoadingMask';
import SponsorSearchFilters from './SponsorSearchFilters';
import LoginBox from '../../LoginBox';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function SponsorSearch() {
  const [filters, setFilters] = useState({});
  const [login_dialog_open, setLoginDialogOpen] = useState(false);

  const metadata = useMetadata();

  if (!metadata?.sponsor) {
    return <LoadingMask />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={2}>
          <Grid lg={6} md={6} xs={12}>
            <Typography variant='h4' gutterBottom>
              Find Sponsors
            </Typography>
          </Grid>
          <Grid lg={12} md={12} xs={12}>
            <SponsorSearchFilters values={filters} setValues={setFilters} />
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <SponsorList
              filters={filters}
              openLoginDialog={() => setLoginDialogOpen(true)}
            />
          </Grid>
        </Grid>
        <Dialog
          onClose={() => setLoginDialogOpen(false)}
          open={login_dialog_open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle>
            {'Login or Create an Invest On Main Account to Continue'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              <Typography variant='body1'>
                Invest on Main a regulated crowdfunding platform and Alternative
                Asset marketplace that allows sponsors to distribute tokenized
                real estate and alternative asset investment products in an easy
                to use and legally compliant way for US investors and non-US
                investors.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
            <LoginBox />
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}

export default SponsorSearch;
