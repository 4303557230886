import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Box, Container, Typography, Button, Alert } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getSponsor, updateSponsor } from '../../../actions/sponsor';
import { FormErrorProvider } from '../../../providers/FormErrorContext';
import SponsorDesktopWizard from './SponsorDesktopWizard';
import SponsorMobileWizard from './SponsorMobileWizard';
import { useUser } from '../../../providers/UserContext';
import SponsorBasicInfo from './SponsorBasicInfo';
import SponsorInvestmentThesis from './SponsorInvestmentThesis';
import SponsorTrackRecord from './SponsorTrackRecord';
import SponsorTeam from './SponsorTeam';
import LoadingMask from '../../shared/LoadingMask';
import {
  adminGetSponsor,
  adminUpdateSponsor,
} from '../../../actions/adminSponsor';
import STATUSES from '../../shared/statuses';

function SponsorForm({ admin }) {
  const [revision, setRevision] = useState(0);
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [sponsor, setSponsor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sponsorUpdate, setSponsorUpdate] = useState({});

  const sp = new URLSearchParams(location.search);
  const sponsor_id = admin ? sp.get('sponsor') : undefined;

  useEffect(() => {
    if (admin && sponsor === null) {
      adminGetSponsor(sponsor_id)
        .then((payload) => {
          setSponsor(payload);
          setLoading(false);
        })
        .catch((error) => {
          navigate('/404');
        });
    } else if (!admin && user.primary_sponsor && sponsor === null) {
      getSponsor(user.primary_sponsor)
        .then((payload) => {
          setSponsor(payload);
          setLoading(false);
        })
        .catch((error) => {
          navigate('/404');
        });
    } else {
      setLoading(false);
    }
  }, [admin, sponsor_id, sponsor, user, navigate]);

  const handleSponsorUpdate = useCallback(async () => {
    const payload = Object.fromEntries(
      Object.entries({ ...(sponsor || {}), ...sponsorUpdate }).filter(
        ([k, v]) => v !== undefined && v !== ''
      )
    );
    console.log(payload);
    let updated_sponsor;
    if (admin) {
      updated_sponsor = await adminUpdateSponsor(sponsor_id, payload);
    } else {
      if (sponsor.status === STATUSES.PENDING) {
        updated_sponsor = sponsor;
      } else {
        updated_sponsor = await updateSponsor(user.primary_sponsor, payload);
      }
    }
    setSponsor(updated_sponsor);
    setSponsorUpdate({});
  }, [admin, sponsor_id, user, sponsor, sponsorUpdate]);

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [sponsor]);

  if (!admin && user.entity_id && !user.primary_sponsor) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                You must enable deal Listing in your account settings to view
                this page.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                component={Link}
                to='/account'
                variant='contained'
                color='primary'
              >
                My Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (loading) {
    return <LoadingMask />;
  }

  const steps = [
    {
      name: 'Basic Info',
      component: (
        <SponsorBasicInfo
          key={`sponsor_basic_info_${revision}`}
          sponsor={sponsor}
          setValues={setSponsorUpdate}
          admin={admin}
        />
      ),
    },
    {
      name: 'Investment Thesis',
      component: (
        <SponsorInvestmentThesis
          key={`sponsor_investment_thesis_${revision}`}
          sponsor={sponsor}
          setValues={setSponsorUpdate}
          admin={admin}
        />
      ),
    },
    {
      name: 'Track Record',
      component: (
        <SponsorTrackRecord
          key={`sponsor_track_record_${revision}`}
          sponsor={sponsor}
          setValues={setSponsorUpdate}
          admin={admin}
        />
      ),
    },
    {
      name: 'Team',
      component: (
        <SponsorTeam
          key={`sponsor_team_${revision}`}
          sponsor={sponsor}
          setValues={setSponsorUpdate}
          admin={admin}
        />
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={4}>
          <Grid xs={12} md={12} lg={12}>
            {!admin && sponsor.status === STATUSES.PENDING ? (
              <Alert severity='warning'>
                Sponsor is pending approval. Editing is disabled during approval
                process.
              </Alert>
            ) : undefined}
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <Typography variant='h4'>Edit {sponsor.name}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <FormErrorProvider>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                <SponsorDesktopWizard
                  admin={admin}
                  steps={steps}
                  sponsor={sponsor}
                  onNext={handleSponsorUpdate}
                />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
                <SponsorMobileWizard
                  admin={admin}
                  steps={steps}
                  sponsor={sponsor}
                  onNext={handleSponsorUpdate}
                />
              </Box>
            </FormErrorProvider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SponsorForm;
