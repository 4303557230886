import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import { useFormError } from '../../../providers/FormErrorContext';

function DealMobileWizard({ steps, onNext }) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const formError = useFormError();
  const navigate = useNavigate();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setLoading(true);
    await onNext();
    setLoading(false);
    if (activeStep === steps.length - 1) {
      navigate('/deals');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = async () => {
    if (activeStep === 0) {
      navigate('/deals');
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!steps[activeStep].optional) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Container sx={{ padding: '15px' }}>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={4}>
          <Grid xs={12} md={12} lg={12}>
            <Stepper activeStep={activeStep} orientation='vertical'>
              {steps.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{step.name}</StepLabel>
                    <StepContent>{step.component}</StepContent>
                  </Step>
                );
              })}
            </Stepper>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {steps[activeStep].optional && (
                <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Tooltip
                title={
                  Boolean(formError.error) ? 'Fix form errors to continue.' : ''
                }
              >
                <span>
                  <LoadingButton
                    loading={loading}
                    onClick={handleNext}
                    disabled={Boolean(formError.error)}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </LoadingButton>
                </span>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default DealMobileWizard;
