import React from 'react';

import { FormControlLabel, Switch, Box, FormHelperText } from '@mui/material';

export default function InvestorDetailsAccreditedField({
  value,
  error,
  setValue,
  setError,
}) {
  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
        }
        label='I am an Accredited Investor.'
      />
      <FormHelperText>
        You must meet the{' '}
        <a
          href='https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor'
          target='_blank'
          rel='noreferrer'
        >
          SEC requirements
        </a>{' '}
        to enable this.
      </FormHelperText>
    </Box>
  );
}
