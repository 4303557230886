import React from 'react';
import { Container, Box } from '@mui/material';

import LoginBox from '../LoginBox';

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const Login = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <LoginBox />
      </Container>
    </Box>
  );
};

export default Login;
