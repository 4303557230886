import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getSponsor } from '../../../actions/sponsor';
import { FormErrorProvider } from '../../../providers/FormErrorContext';
import DealDesktopWizard from './DealDesktopWizard';
import DealMobileWizard from './DealMobileWizard';
import LoadingMask from '../../shared/LoadingMask';
import { useUser } from '../../../providers/UserContext';
import {
  createSponsorDeal,
  getSponsorDeal,
  updateSponsorDeal,
} from '../../../actions/sponsorDeal';
import STATUSES from '../../shared/statuses';
import DealOverview from './DealOverview';
import DealNarrative from './DealNarrative';
import DealMediaDocuments from './DealMediaDocuments';
import DealManagement from './DealManagement';

function DealForm({ admin }) {
  const [revision, setRevision] = useState(0);
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [sponsor, setSponsor] = useState(undefined);
  const [deal, setDeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dealUpdate, setDealUpdate] = useState({});

  useEffect(() => {
    if (sponsor && sponsor.status === STATUSES.ACTIVE) {
      const sp = new URLSearchParams(location.search);
      const deal_id = sp.get('deal');
      if (deal_id && deal === null) {
        getSponsorDeal(sponsor.entity_id, deal_id)
          .then((payload) => {
            setDeal(payload);
            setLoading(false);
          })
          .catch((error) => {
            navigate('/404');
          });
      } else {
        setLoading(false);
      }
    }
  }, [sponsor, location, deal, navigate]);

  useEffect(() => {
    if (user.primary_sponsor && !sponsor) {
      getSponsor(user.primary_sponsor).then((payload) => {
        setSponsor(payload);
      });
    }
  }, [user, sponsor]);

  const handleDealUpdate = async () => {
    const update = Object.fromEntries(
      Object.entries({ ...dealUpdate }).filter(
        ([k, v]) => v !== undefined && v !== ''
      )
    );

    if (Object.keys(update).length !== 0) {
      const payload = { ...(deal || {}), ...update };

      let updated_deal;
      if (!deal) {
        updated_deal = await createSponsorDeal(user.primary_sponsor, payload);
      } else {
        updated_deal = await updateSponsorDeal(
          user.primary_sponsor,
          deal.entity_id,
          payload
        );
      }
      setDeal(updated_deal);
    }
    setDealUpdate({});
  };
  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [deal]);

  if (user.entity_id && !user.primary_sponsor) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                You must create a sponsor to list deals.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                component={Link}
                to='/account'
                variant='contained'
                color='primary'
              >
                My Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
  if (sponsor && sponsor.status !== STATUSES.ACTIVE) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                Only accounts with active sponsors can list deals.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                component={Link}
                to='/account'
                variant='contained'
                color='primary'
              >
                My Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
  if (loading) {
    return <LoadingMask />;
  }

  const steps = [
    {
      name: 'Deal Overview',
      component: (
        <DealOverview
          key={`deal_overview_${revision}`}
          deal={deal}
          setValues={setDealUpdate}
        />
      ),
    },
    {
      name: 'Narrative',
      component: (
        <DealNarrative
          key={`deal_narrative_${revision}`}
          deal={deal}
          setValues={setDealUpdate}
        />
      ),
    },
    {
      name: 'Media and Documents',
      component: (
        <DealMediaDocuments
          key={`deal_media_${revision}`}
          deal={deal}
          setValues={setDealUpdate}
        />
      ),
    },
    {
      name: 'Management',
      component: (
        <DealManagement
          key={`deal_management_${revision}`}
          deal={deal}
          setValues={setDealUpdate}
        />
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={4}>
          <Grid xs={12} md={12} lg={12}>
            <Typography variant='h4'>
              {deal ? `Edit ${deal.name} Listing` : 'New Deal Listing'}
            </Typography>
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <FormErrorProvider>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                <DealDesktopWizard steps={steps} onNext={handleDealUpdate} />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
                <DealMobileWizard steps={steps} onNext={handleDealUpdate} />
              </Box>
            </FormErrorProvider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default DealForm;
