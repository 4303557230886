import React, { useState, useEffect } from 'react';

import { Box, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { adminGetUsers } from '../../../actions/adminUser';

export default function SponsorOwnerField({ value, setValue }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(value);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const users = await adminGetUsers(inputValue);

      if (active) {
        setOptions([...users]);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, inputValue]);

  useEffect(() => {
    if (open && options.length === 0) {
      setLoading(true);
    }
    if (!open) {
      setOptions([]);
    }
  }, [open, options.length]);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue);
  };

  return (
    <Box>
      <Autocomplete
        id='owner_id'
        sx={{ minWidth: '500px' }}
        aria-required
        options={options}
        value={selected}
        inputValue={inputValue}
        onInputChange={async (event, newInputValue) => {
          console.log(event);
          setInputValue(newInputValue);
          if (!loading) {
            setLoading(true);
          }
        }}
        onChange={handleChange}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) =>
          option?.entity_id === value?.entity_id
        }
        getOptionKey={(option) => option?.entity_id}
        getOptionLabel={(option) =>
          `${
            option?.entity_id
              ? option?.entity_id === '000000000000000000000000'
                ? 'Unassigned'
                : `${option.first_name} ${option.last_name} (${option.email})`
              : ''
          }`
        }
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={value === ''}
            label='Owner'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
