import React, { useEffect, useState, useMemo } from 'react';

import {
  Typography,
  Stack,
  LinearProgress,
  Box,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { getSponsorDealHistory } from '../../../actions/sponsorDealHistory';
import round from '../../../util/round';

function SponsorTrackRecord({ sponsor }) {
  const theme = useTheme();
  const [deal_history, setDealHistory] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (sponsor?.entity_id && !deal_history && !fetching) {
      setFetching(true);
      getSponsorDealHistory(sponsor.entity_id).then((payload) => {
        setDealHistory(payload);
        setLoading(false);
        setFetching(false);
      });
    } else {
      setLoading(false);
    }
  }, [deal_history, fetching, setFetching, sponsor]);

  const currency_formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'deal-history-theme--header',
        type: 'string',
        width: 100,

        // renderCell: renderName,
      },
      {
        field: 'inception_date',
        headerName: 'Inception Date',
        headerClassName: 'deal-history-theme--header',
        type: 'date',
        valueGetter: (params) => {
          if (params.row?.inception_date) {
            return new Date(params.row.inception_date);
          }
        },
        // renderCell: renderStatus,

        flex: 0.25,
      },
      {
        field: 'total_capital',
        headerName: 'Total Capital',
        headerClassName: 'deal-history-theme--header',
        type: 'number',
        renderCell: (params) => {
          return currency_formatter.format(params.row?.total_capital);
        },
        flex: 0.25,
      },
      {
        field: 'amount',
        headerName: 'Deal Amount',
        headerClassName: 'deal-history-theme--header',
        type: 'number',
        renderCell: (params) => {
          return currency_formatter.format(params.row?.amount);
        },
        flex: 0.25,
      },
      {
        field: 'realization_date',
        headerName: 'Realization Date',
        headerClassName: 'deal-history-theme--header',
        type: 'date',
        valueGetter: (params) => {
          if (params.row?.realization_date) {
            return new Date(params.row.realization_date);
          }
        },
        flex: 0.25,
      },
      {
        field: 'realized_irr',
        headerName: 'Realized IRR',
        headerClassName: 'deal-history-theme--header',
        type: 'number',
        renderCell: (params) => {
          if (params.row?.realized_irr) {
            return `${round(params.row.realized_irr * 100, 6)}%`;
          }
        },

        flex: 0.25,
      },
      {
        field: 'realized_multiple',
        headerName: 'Realized Equity Multiple',
        headerClassName: 'deal-history-theme--header',
        type: 'number',
        renderCell: (params) => {
          if (params.row?.realized_multiple) {
            return `${params.row.realized_multiple}x`;
          }
        },

        flex: 0.25,
      },
      {
        field: 'total_proceeds',
        headerName: 'Total Proceeds',
        headerClassName: 'deal-history-theme--header',
        type: 'number',
        renderCell: (params) => {
          if (params.row?.total_proceeds) {
            return currency_formatter.format(params.row.total_proceeds);
          }
        },
        flex: 0.25,
      },
    ],
    [currency_formatter]
  );

  const Toolbar = () => {
    return (
      <GridToolbarContainer sx={{ padding: '5px' }}>
        <GridToolbarColumnsButton variant='outlined' />
        <GridToolbarFilterButton variant='outlined' />
      </GridToolbarContainer>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h4'>Track Record</Typography>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>
            Amount of Active Investments
          </Typography>
          <Typography variant='h6'>
            $
            {new Intl.NumberFormat('en-US').format(
              sponsor.active_investments_amount
            )}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>
            Amount of Realized Investments
          </Typography>
          <Typography variant='h6'>
            $
            {new Intl.NumberFormat('en-US').format(
              sponsor.realized_investments_amount
            )}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>Average Hold Period</Typography>
          <Typography variant='h6'>{sponsor.average_hold_months}</Typography>
        </Stack>
      </Grid>
      {deal_history?.length ? (
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              width: '100%',
              '& .deal-history-theme--header': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
              },
              display: { md: 'inherit', xs: 'none' },
            }}
          >
            <div
              style={{ display: { md: 'flex', xs: 'none' }, height: '100%' }}
            >
              <div
                style={{
                  flexGrow: 1,
                }}
              >
                <DataGrid
                  autoHeight
                  getRowId={(row) => row.entity_id}
                  columns={columns}
                  rows={deal_history || []}
                  loading={loading}
                  slots={{
                    toolbar: Toolbar,
                    loadingOverlay: LinearProgress,
                  }}
                />
              </div>
            </div>
          </Box>
        </Grid>
      ) : undefined}
    </Grid>
  );
}

export default SponsorTrackRecord;
