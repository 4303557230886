import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useUser } from '../../providers/UserContext';

function SidebarContent({ toggleDrawer }) {
  const navigate = useNavigate();
  const user = useUser();

  return (
    <>
      <List
        component='nav'
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            Search
          </ListSubheader>
        }
      >
        <ListItemButton
          sx={{ pl: 4 }}
          onClick={() => {
            navigate('/search/deals');
            toggleDrawer();
          }}
        >
          <ListItemIcon>
            <SearchOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Find Deals' />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <SearchOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary='Find Sponsors'
            onClick={() => {
              navigate('/search/sponsors');
              toggleDrawer();
            }}
          />
        </ListItemButton>{' '}
      </List>
      {user?.entity_id ? (
        <>
          <Divider sx={{ my: 1 }} />
          <List
            component='nav'
            subheader={
              <ListSubheader component='div' id='nested-list-subheader'>
                Investments
              </ListSubheader>
            }
          >
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/activity');
                toggleDrawer();
              }}
            >
              <ListItemIcon>
                <AccountBalanceOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary='My Activity' />
            </ListItemButton>
          </List>
        </>
      ) : undefined}
      {user?.primary_sponsor ? (
        <>
          <Divider sx={{ my: 1 }} />
          <List
            component='nav'
            subheader={
              <ListSubheader component='div' id='nested-list-subheader'>
                Listings
              </ListSubheader>
            }
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary='My Deals'
                onClick={() => {
                  navigate('/deals');
                  toggleDrawer();
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <QueryStatsOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary='View Activity'
                onClick={() => {
                  navigate('/sponsor/activity');
                  toggleDrawer();
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <BusinessOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary='Manage Sponsor'
                onClick={() => {
                  navigate('/sponsor/edit');
                  toggleDrawer();
                }}
              />
            </ListItemButton>
          </List>
        </>
      ) : undefined}

      <Divider sx={{ my: 1 }} />
      <List component='nav'>
        {user.entity_id ? (
          <ListItemButton
            onClick={() => {
              navigate('/account');
              toggleDrawer();
            }}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItemButton>
        ) : undefined}
        {user.admin ? (
          <>
            <Divider sx={{ my: 1 }} />
            <ListItemButton
              onClick={() => {
                navigate('/admin');
                toggleDrawer();
              }}
            >
              <ListItemIcon>
                <EngineeringIcon />
              </ListItemIcon>
              <ListItemText primary='Admin Dashboard' />
            </ListItemButton>
          </>
        ) : undefined}
      </List>
    </>
  );
}

export default SidebarContent;
