import React from 'react';
import { useTheme, Box } from '@mui/material';
import { StytchLogin } from '@stytch/react';
import { Products } from '@stytch/vanilla-js';

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const LoginBox = () => {
  const theme = useTheme();
  const styles = {
    container: {
      width: '100%',
      display: 'flex',
    },
    buttons: {
      primary: {
        backgroundColor: theme.palette.main,
        borderColor: theme.palette.main,
      },
    },
  };

  const redirect_url = `${new URL(window.location.href).origin}/authenticate`;

  const config = {
    products: [Products.emailMagicLinks, Products.oauth],
    emailMagicLinksOptions: {
      loginRedirectURL: redirect_url,
      loginExpirationMinutes: parseInt(
        process.env.REACT_APP_LOGIN_SESSION_MINUTES
      ),
      signupRedirectURL: redirect_url,
      signupExpirationMinutes: parseInt(
        process.env.REACT_APP_LOGIN_SESSION_MINUTES
      ),
    },
    oauthOptions: {
      providers: [
        {
          type: 'google',
        },
      ],
      loginRedirectURL: redirect_url,
      loginExpirationMinutes: parseInt(
        process.env.REACT_APP_LOGIN_SESSION_MINUTES
      ),
      signupRedirectURL: redirect_url,
      signupExpirationMinutes: parseInt(
        process.env.REACT_APP_LOGIN_SESSION_MINUTES
      ),
    },
  };

  return (
    <Box justifyContent='center' display={'flex'} flexGrow={1}>
      <Box sx={{ maxWidth: '600px', width: '100%' }}>
        <StytchLogin config={config} styles={styles} />
      </Box>
    </Box>
  );
};

export default LoginBox;
