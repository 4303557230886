import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

export default function DealDocumentListEditor({ value, setValue }) {
  const removeDocument = (index) => {
    setValue((prevDocuments) => {
      const new_documents = [...prevDocuments];

      new_documents.splice(index, 1);
      return new_documents;
    });
  };
  const moveUp = (index) => {
    if (index) {
      const new_documents = [...value];
      const swap = new_documents[index];
      new_documents[index] = new_documents[index - 1];
      new_documents[index - 1] = swap;

      setValue(new_documents);
    }
  };
  const moveDown = (index) => {
    if (Boolean(index + 1 !== value.length)) {
      const new_documents = [...value];
      const swap = new_documents[index];
      new_documents[index] = new_documents[index + 1];
      new_documents[index + 1] = swap;

      setValue(new_documents);
    }
  };

  return (
    <Grid container spacing={2}>
      {value.map((document, index) => (
        <Grid xs={12}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <IconButton onClick={() => removeDocument(index)}>
              <DeleteOutlinedIcon />
            </IconButton>
            <IconButton
              disabled={!Boolean(index)}
              onClick={() => moveUp(index)}
            >
              <ArrowUpwardOutlinedIcon />
            </IconButton>

            <IconButton
              disabled={!Boolean(index + 1 !== value.length)}
              onClick={() => moveDown(index)}
            >
              <ArrowDownwardOutlinedIcon />
            </IconButton>

            {/* <DescriptionOutlinedIcon /> */}
            <Typography component={'a'} href={document.url} target='_blank'>
              {document.name}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
