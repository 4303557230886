import React from 'react';
import { Stack, Typography, Box, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useMetadata } from '../../../providers/MetadataContext';
import dayjs from 'dayjs';
import round from '../../../util/round';

function DealFinancialMetrics({ deal }) {
  const metadata = useMetadata();

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            width: '100%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: 'background.paper',

            '& svg': {
              m: 1.5,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
        >
          <Stack sx={{ padding: '10px', width: '100%' }} direction={'column'}>
            <Typography variant='body1'>
              <b>Cash Yield</b>
            </Typography>
            <Typography variant='body2'>
              {round(deal.cash_yield * 100, 4)}%
            </Typography>
          </Stack>
          <Divider orientation='vertical' variant='middle' flexItem />
          <Stack sx={{ padding: '10px', width: '100%' }} direction={'column'}>
            <Typography variant='body1'>
              <b>NOI</b>
            </Typography>
            <Typography variant='body2'>
              ${new Intl.NumberFormat('en-US').format(deal.noi)}
            </Typography>
          </Stack>
          <Divider orientation='vertical' variant='middle' flexItem />{' '}
          <Stack sx={{ padding: '10px', width: '100%' }} direction={'column'}>
            <Typography variant='body1'>
              <b>Debt</b>
            </Typography>
            <Typography variant='body2'>
              {round(deal.debt * 100, 4)}%
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            width: '100%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: 'background.paper',
          }}
        >
          <Box sx={{ my: 1, mx: 2 }}>
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Cash Yield</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  ${new Intl.NumberFormat('en-US').format(deal.noi)}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>NOI</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  ${new Intl.NumberFormat('en-US').format(deal.noi)}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Debt</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {round(deal.debt * 100, 4)}%
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Box
          sx={{
            width: '100%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: 'background.paper',
          }}
        >
          <Box sx={{ my: 1, mx: 2 }}>
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Investor IRR Range</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {round(deal.irr_min * 100, 4)}% -{' '}
                  {round(deal.irr_max * 100, 4)}%
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Equity Multiple</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {deal.multiple_min}x - {deal.multiple_max}x
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Investment Period</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {deal.investment_period} years
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Box
          sx={{
            width: '100%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: 'background.paper',
          }}
        >
          <Box sx={{ my: 1, mx: 2 }}>
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Capital Raise</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  ${new Intl.NumberFormat('en-US').format(deal.amount)}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Minimum Investment</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  $
                  {new Intl.NumberFormat('en-US').format(
                    deal.minimum_investment
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Investment Structure</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {
                    (metadata?.deal?.lists?.investment_structures || []).find(
                      (option) => option.entity_id === deal.investment_structure
                    )?.name
                  }
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />

            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Days Remaining</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {dayjs(deal.offer_end_date).diff(dayjs(), 'd')} days
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Offering Duration</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {dayjs(deal.offer_end_date).diff(
                    dayjs(deal.offer_start_date),
                    'd'
                  )}{' '}
                  days
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Offer End Date</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {deal.offer_end_date}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' flexItem />
            <Grid container alignItems='center' sx={{ padding: '5px' }}>
              <Grid xs>
                <Typography gutterBottom variant='body1' component='div'>
                  <b>Distributions</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography gutterBottom variant='body2' component='div'>
                  {
                    (
                      metadata?.deal?.lists?.distribution_frequencies || []
                    ).find(
                      (option) =>
                        option.entity_id === deal.distribution_frequency
                    )?.name
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default DealFinancialMetrics;
