import React from 'react';

import { Typography, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useMetadata } from '../../../providers/MetadataContext';

function DealCategories({ deal }) {
  const metadata = useMetadata();

  return (
    <Grid container spacing={0}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Attributes</Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        {deal.investment_benefits.map((benefit_id) => {
          const benefit = (
            metadata?.deal?.lists?.investment_benefits || []
          ).find((option) => option.entity_id === benefit_id);
          return <Chip sx={{ margin: '5px' }} label={benefit?.name} />;
        })}
        {deal.accredited_only ? (
          <Chip sx={{ margin: '5px' }} label='Accredited Only' />
        ) : (
          <Chip sx={{ margin: '5px' }} label='Non-Accredited Welcome' />
        )}
        {deal.ten_thirty_one ? (
          <Chip sx={{ margin: '5px' }} label='1031 Exchange Eligible' />
        ) : undefined}
        {deal.opportunity_zone ? (
          <Chip sx={{ margin: '5px' }} label='Qualified Opportunity Zone' />
        ) : undefined}
        {deal.ira ? (
          <Chip sx={{ margin: '5px' }} label='IRA Eligible' />
        ) : undefined}
      </Grid>
    </Grid>
  );
}

export default DealCategories;
