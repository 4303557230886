import React, { useState } from 'react';

import { ImageList, ImageListItem, Box, Button } from '@mui/material';

function DealImageList({ images }) {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <Box sx={{ width: '100%' }}>
      <img
        style={{ width: 'auto', height: '100%', maxHeight: '400px' }}
        src={`${selectedImage?.url}`}
        alt={selectedImage?.entity_id}
        loading='lazy'
      />
      <ImageList sx={{ width: 500, height: 200 }} cols={5} rowHeight={100}>
        {images.map((item) => (
          <ImageListItem key={item.entity_id}>
            <Button
              sx={{ margin: 0, padding: 0 }}
              onClick={() => setSelectedImage(item)}
            >
              <img
                style={{ width: '100px', height: '100px', overflow: 'hidden' }}
                src={`${item.url}`}
                alt={item.entity_id}
                loading='lazy'
              />
            </Button>
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

export default DealImageList;
