import api from '../apis/api';

export const adminGetUsers = async (
  name = undefined,
  limit = 10,
  offset = 0
) => {
  const resp = await api.get('/admin/user', {
    params: { name, limit, offset },
  });
  return resp.data;
};

export const adminCountUsers = async () => {
  const resp = await api.get('/admin/user/count');
  return resp?.data?.total;
};

export const adminGetUser = async (user_id) => {
  const resp = await api.get(`/admin/user/${user_id}`);
  return resp.data;
};

export const adminUpdateUser = async (user_id, values) => {
  const resp = await api.put(`/admin/user/${user_id}`, values);
  return resp.data;
};

export const adminDeleteUser = async (user_id) => {
  const resp = await api.delete(`/admin/user/${user_id}`);
  return resp.data;
};
