import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SponsorNameField from './SponsorNameField';
import { useFormErrorDispatch } from '../../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../../providers/actionTypes';
import SponsorEmailField from './SponsorEmailField';
import SponsorWebsiteField from './SponsorWebsiteField';
import SponsorPhoneField from './SponsorPhoneField';
import SponsorAddressField from './SponsorAddressField';
import SponsorAddress2Field from './SponsorAddress2Field';
import SponsorCityField from './SponsorCityField';
import SponsorStateField from './SponsorStateField';
import SponsorPostalCodeField from './SponsorPostalCodeField';
import SponsorCountryField from './SponsorCountryField';
import SponsorLinkedinField from './SponsorLinkedinField';
import SponsorVideoField from './SponsorVideoField';
import SponsorAboutField from './SponsorAboutField';
import SponsorFoundingYearField from './SponsorFoundingYearField';
import SponsorLogoSquareField from './SponsorLogoSquareField';
import SponsorLogoRectangleField from './SponsorLogoRectangleField';
import STATUSES from '../../../shared/statuses';

function SponsorBasicInfo({ admin, sponsor, setValues }) {
  const [revision, setRevision] = useState(0);
  const [name, setName] = useState(sponsor?.name || '');
  const [name_error, setNameError] = useState(false);
  const [email, setEmail] = useState(sponsor?.email || '');
  const [email_error, setEmailError] = useState(false);
  const [web, setWeb] = useState(sponsor?.web || '');
  const [web_error, setWebError] = useState(false);
  const [phone, setPhone] = useState(sponsor?.phone || '');
  const [phone_error, setPhoneError] = useState(false);
  const [address, setAddress] = useState(sponsor?.address || '');
  const [address_error, setAddressError] = useState(false);
  const [address2, setAddress2] = useState(sponsor?.address2 || '');
  const [address2_error, setAddress2Error] = useState(false);
  const [city, setCity] = useState(sponsor?.city || '');
  const [city_error, setCityError] = useState(false);
  const [state, setState] = useState(sponsor?.state || '');
  const [state_error, setStateError] = useState(false);
  const [postal_code, setPostalCode] = useState(sponsor?.postal_code || '');
  const [postal_code_error, setPostalCodeError] = useState(false);
  const [country, setCountry] = useState(sponsor?.country || '');
  const [country_error, setCountryError] = useState(false);
  const [founding_year, setFoundingYear] = useState(
    sponsor?.founding_year || ''
  );
  const [founding_year_error, setFoundingYearError] = useState(false);
  const [linkedin, setLinkedin] = useState(sponsor?.linkedin || '');
  const [linkedin_error, setLinkedinError] = useState(false);
  const [video, setVideo] = useState(sponsor?.video || '');
  const [video_error, setVideoError] = useState(false);
  const [about, setAbout] = useState(sponsor?.about || '');
  const [about_error, setAboutError] = useState(false);
  const [logo_square, setLogoSquare] = useState(sponsor?.logo_square || '');
  const [logo_rectangle, setLogoRectangle] = useState(
    sponsor?.logo_rectangle || ''
  );
  const formErrorDispatch = useFormErrorDispatch();

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [sponsor]);

  useEffect(() => {
    if (
      name_error ||
      name === '' ||
      email_error ||
      email === '' ||
      web_error ||
      web === '' ||
      phone_error ||
      phone === '' ||
      address_error ||
      address === '' ||
      city_error ||
      city === '' ||
      state_error ||
      state === '' ||
      postal_code_error ||
      postal_code === '' ||
      country_error ||
      country === '' ||
      founding_year_error ||
      founding_year === '' ||
      address2_error ||
      linkedin_error ||
      video_error ||
      about_error
    ) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
      setValues({
        name,
        email,
        web,
        phone,
        address,
        address2,
        city,
        state,
        postal_code,
        country,
        founding_year: parseInt(founding_year),
        linkedin,
        video,
        about,
        logo_square,
        logo_rectangle,
      });
    }
  }, [
    formErrorDispatch,
    setValues,
    name,
    email,
    web,
    phone,
    address,
    address2,
    city,
    state,
    postal_code,
    country,
    founding_year,
    linkedin,
    video,
    about,
    logo_square,
    logo_rectangle,
    name_error,
    email_error,
    web_error,
    phone_error,
    address_error,
    city_error,
    state_error,
    postal_code_error,
    country_error,
    founding_year_error,
    address2_error,
    linkedin_error,
    video_error,
    about_error,
  ]);

  return (
    <Box width='100%' padding='15px'>
      {/* <Paper sx={{ display: 'flex', width: '100%' }}> */}
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 600,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Contact Information</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorNameField
                  key={`name_${revision}`}
                  value={name}
                  error={name_error}
                  setValue={setName}
                  setError={setNameError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorFoundingYearField
                  key={`founding_year_${revision}`}
                  value={founding_year}
                  error={founding_year_error}
                  setValue={setFoundingYear}
                  setError={setFoundingYearError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorEmailField
                  key={`email_${revision}`}
                  value={email}
                  error={email_error}
                  setValue={setEmail}
                  setError={setEmailError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorWebsiteField
                  key={`web_${revision}`}
                  value={web}
                  error={web_error}
                  setValue={setWeb}
                  setError={setWebError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorPhoneField
                  key={`phone_${revision}`}
                  value={phone}
                  error={phone_error}
                  setValue={setPhone}
                  setError={setPhoneError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorAddressField
                  key={`address_${revision}`}
                  value={address}
                  error={address_error}
                  setValue={setAddress}
                  setError={setAddressError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorAddress2Field
                  key={`address2_${revision}`}
                  value={address2}
                  error={address2_error}
                  setValue={setAddress2}
                  setError={setAddress2Error}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <SponsorCityField
                  key={`city_${revision}`}
                  value={city}
                  error={city_error}
                  setValue={setCity}
                  setError={setCityError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <SponsorStateField
                  key={`state_${revision}`}
                  value={state}
                  error={state_error}
                  setValue={setState}
                  setError={setStateError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <SponsorPostalCodeField
                  key={`postal_code_${revision}`}
                  value={postal_code}
                  error={postal_code_error}
                  setValue={setPostalCode}
                  setError={setPostalCodeError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <SponsorCountryField
                  key={`country_${revision}`}
                  value={country}
                  error={country_error}
                  setValue={setCountry}
                  setError={setCountryError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Brand/Social Details</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorLinkedinField
                  key={`linkedin_${revision}`}
                  value={linkedin}
                  error={linkedin_error}
                  setValue={setLinkedin}
                  setError={setLinkedinError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorVideoField
                  key={`video_${revision}`}
                  value={video}
                  error={video_error}
                  setValue={setVideo}
                  setError={setVideoError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorAboutField
                  key={`about_${revision}`}
                  value={about}
                  error={about_error}
                  setValue={setAbout}
                  setError={setAboutError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorLogoSquareField
                  key={`logo_square_${revision}`}
                  sponsor_id={sponsor.entity_id}
                  value={logo_square}
                  setValue={setLogoSquare}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorLogoRectangleField
                  key={`logo_rectangle_${revision}`}
                  sponsor_id={sponsor.entity_id}
                  value={logo_rectangle}
                  setValue={setLogoRectangle}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </Paper> */}
    </Box>
  );
}

export default SponsorBasicInfo;
