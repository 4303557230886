import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { adminCreateSponsor } from '../../../actions/adminSponsor';
import SponsorNameField from '../../Sponsors/SponsorForm/SponsorBasicInfo/SponsorNameField';

function SponsorCreateDialog({ open, handleClose }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [name_error, setNameError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const sponsor = await adminCreateSponsor({ name });
    navigate({
      pathname: '/admin/sponsor/edit',
      search: `?sponsor=${sponsor.entity_id}`,
    });
  };

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs>
            Create Sponsor
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            display: 'flex',
            maxWidth: 800,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={12} md={12} lg={12} sx={{ minWidth: '400px' }}>
              <SponsorNameField
                value={name}
                error={name_error}
                setValue={setName}
                setError={setNameError}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button color='inherit' onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            variant='contained'
            color='primary'
            loading={loading}
            onClick={handleSubmit}
            disabled={name_error || name === ''}
          >
            Create
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SponsorCreateDialog;
