import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function AccountEmailField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (newValue === '') {
      setError('Email cannot be blank');
    } else if (
      !/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/.test(
        String(newValue).toLowerCase()
      )
    ) {
      setError('Email is invalid');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Email'
          id='email'
          aria-describedby='email-helper-text'
          onChange={handleChange}
          error={Boolean(error || value === '')}
          value={value}
          variant='standard'
          required
        />
        {error ? (
          <FormHelperText id='email-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='email-helper-text'>
            Enter your primary contact email
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
