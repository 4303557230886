import React from 'react';

import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

function DealDocumentList({ documents }) {
  console.log(documents);
  return (
    <Grid container spacing={1}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Documents</Typography>
      </Grid>
      {documents.map((document) => (
        <Grid xs={12} md={12} lg={12}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <DescriptionOutlinedIcon />
            <Typography component={'a'} href={document.url} target='_blank'>
              {document.name}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}

export default DealDocumentList;
