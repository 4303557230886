import React from 'react';
import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';

const UploadButton = ({
  name,
  onChange,
  disabled,
  variant = 'contained',
  color = 'primary',
  ...rest
}) => {
  let input;
  return (
    <>
      <Button
        {...rest}
        onClick={(e) => {
          e.preventDefault();
          input.click();
        }}
        disabled={disabled}
        variant={variant}
        color={color}
        endIcon={<PublishIcon />}
      >
        {name}
      </Button>

      <input
        ref={(element) => (input = element)}
        hidden
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}
        type='file'
      />
    </>
  );
};
export default UploadButton;
