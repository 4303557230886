import React, { useState } from 'react';

import {
  Accordion,
  Typography,
  AccordionSummary,
  Stack,
  AccordionDetails,
  AccordionActions,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

import InvestmentStrategyFilter from './InvestmentStrategyFilter';
import AssetTypeFilter from './AssetTypeFilter';
import InvestmentBenefitsFilter from './InvestmentBenefitsFilter';
import DistributionFequenciesFilter from './DistributionFrequenciesFilter';
import InvestmentStructureFilter from './InvestmentStructuresFilter';
import LoanToCostFilter from './LoanToCostFilter';
import MinimumHoldPeriodFilter from './MinimumHoldPeriodFilter';
import MinimumInvestmentFilter from './MinimumInvestmentFilter';
import RegionFilter from './RegionFilter';

function DealSearchFilters({ values, setValues }) {
  const [revision, setRevision] = useState(0);
  const [asset_sub_types, setAssetSubTypes] = useState(
    values?.asset_sub_types || []
  );
  const [investment_strategies, setInvestmentStrategies] = useState(
    values?.investment_strategies || []
  );
  const [distribution_frequencies, setDistributionFrequencies] = useState(
    values?.distribution_frequencies || []
  );
  const [investment_benefits, setInvestmentBenefits] = useState(
    values?.investment_benefits || []
  );
  const [investment_structures, setInvestmentStructures] = useState(
    values?.investment_structures || []
  );
  const [loan_to_cost_types, setLoanToCostTypes] = useState(
    values?.loan_to_cost_types || []
  );
  const [minimum_hold_period_types, setMinimumHoldPeriodTypes] = useState(
    values?.minimum_hold_period_types || []
  );
  const [minimum_investment_types, setMinimumInvestmentTypes] = useState(
    values?.minimum_investment_types || []
  );
  const [regions, setRegions] = useState(values?.regions || []);
  const [expanded, setExpanded] = useState(false);

  const setFilters = () => {
    setValues({
      asset_sub_types,
      distribution_frequencies,
      investment_benefits,
      investment_strategies,
      investment_structures,
      loan_to_cost_types,
      minimum_hold_period_types,
      minimum_investment_types,
      regions,
    });
    setExpanded(false);
    setRevision((prevState) => prevState + 1);
  };

  const resetFilters = () => {
    setAssetSubTypes(values?.asset_sub_types || []);
    setDistributionFrequencies(values?.distribution_frequencies || []);
    setInvestmentBenefits(values?.investment_benefits || []);
    setInvestmentStrategies(values?.investment_strategies || []);
    setInvestmentStructures(values?.investment_structures || []);
    setLoanToCostTypes(values?.loan_to_cost_types || []);
    setMinimumHoldPeriodTypes(values?.minimum_hold_period_types || []);
    setMinimumInvestmentTypes(values?.minimum_investment_types || []);
    setRegions(values?.regions || []);
    setExpanded(false);
    setRevision((prevState) => prevState + 1);
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Stack direction='row' spacing={2} alignItems={'center'}>
          <FilterListOutlinedIcon />
          <Typography variant='h6' paragraph gutterBottom>
            Filters
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} xs={12} md={12} lg={12}>
          <Grid lg={8} md={12} xs={12}>
            <AssetTypeFilter
              key={`asset_sub_types_filter_${revision}`}
              value={asset_sub_types}
              setValue={setAssetSubTypes}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <InvestmentStrategyFilter
              key={`investment_strategies_filter_${revision}`}
              value={investment_strategies}
              setValue={setInvestmentStrategies}
            />
          </Grid>
          <Grid lg={4} md={4} xs={12}>
            <DistributionFequenciesFilter
              key={`distribution_frequencies_filter_${revision}`}
              value={distribution_frequencies}
              setValue={setDistributionFrequencies}
            />
          </Grid>

          <Grid lg={3} md={4} xs={12}>
            <InvestmentBenefitsFilter
              key={`investment_benefits_filter_${revision}`}
              value={investment_benefits}
              setValue={setInvestmentBenefits}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <InvestmentStructureFilter
              key={`investment_structures_filter_${revision}`}
              value={investment_structures}
              setValue={setInvestmentStructures}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <LoanToCostFilter
              key={`loan_to_cost_types_filter_${revision}`}
              value={loan_to_cost_types}
              setValue={setLoanToCostTypes}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <MinimumHoldPeriodFilter
              key={`minimum_hold_period_types_filter_${revision}`}
              value={minimum_hold_period_types}
              setValue={setMinimumHoldPeriodTypes}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <MinimumInvestmentFilter
              key={`minimum_investment_types_filter_${revision}`}
              value={minimum_investment_types}
              setValue={setMinimumInvestmentTypes}
            />
          </Grid>
          <Grid lg={3} md={4} xs={12}>
            <RegionFilter
              key={`regions_filter_${revision}`}
              value={regions}
              setValue={setRegions}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button variant='outlined' color='inherit' onClick={resetFilters}>
          Reset
        </Button>
        <Button variant='outlined' color='primary' onClick={setFilters}>
          Apply
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

export default DealSearchFilters;
