import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function DealMarketPopulationField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl fullWidth>
        <NumericFormat
          value={value}
          onValueChange={handleChange}
          customInput={TextField}
          aria-describedby='market-population-helper-text'
          label='Metro Population'
          id='market_population'
          error={Boolean(error)}
          variant='standard'
          thousandSeparator=','
        />

        {error ? (
          <FormHelperText id='market-population-helper-text'>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id='market-population-helper-text'>
            Enter the population of the market's metro area.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
