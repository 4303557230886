import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, TextField } from '@mui/material';

export default function DealMultipleMinField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl fullWidth>
        <NumericFormat
          value={value}
          suffix='x'
          onValueChange={handleChange}
          customInput={TextField}
          id='multiple_min'
          label='Minimum'
          error={Boolean(error)}
          variant='standard'
          thousandSeparator=','
        />
      </FormControl>
    </Box>
  );
}
