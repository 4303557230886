import React, { useState } from 'react';
import MDEditor, { commands } from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

import { FormControl, Box, FormHelperText, Typography } from '@mui/material';

export default function SponsorAboutField({
  value,
  error,
  setValue,
  setError,
  disabled,
  ...rest
}) {
  const MAX_LENGTH = 3000;
  const [charCount, setCharCount] = useState(value ? value.length : 0);
  const handleChange = (text) => {
    if (text.length <= MAX_LENGTH) {
      setValue(text);
      setCharCount(text.length);
    }
  };

  return (
    <Box>
      <FormControl fullWidth aria-describedby='about-helper-text'>
        <Typography variant='caption' color={'rgba(0, 0, 0, 0.6)'}>
          Company Overview
        </Typography>
        {disabled ? (
          <div data-color-mode='light'>
            <MDEditor.Markdown {...rest} source={value} />
          </div>
        ) : (
          <MDEditor
            {...rest}
            commands={[
              commands.title3,
              commands.title4,
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.link,
              commands.unorderedListCommand,
              commands.orderedListCommand,
              commands.checkedListCommand,
            ]}
            minHeight={300}
            height={'100%'}
            preview={disabled ? 'preview' : 'edit'}
            data-color-mode='light'
            value={value}
            onChange={handleChange}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />
        )}

        <FormHelperText id='about-helper-text'>
          Enter an overview about the sponsor. This will be the first thing
          investors read about you when they are evaluating your deals. (
          {charCount}/{MAX_LENGTH})
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
