import React, { useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { useMetadata } from '../../../../providers/MetadataContext';

function DealRegionField({ value, setValue }) {
  const metadata = useMetadata();
  const [selected, setSelected] = useState(
    (metadata?.deal?.lists?.regions || []).find(
      (option) => option.entity_id === value
    )
  );

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue.entity_id);
  };

  return (
    <Box>
      <Autocomplete
        id='region-field'
        options={metadata?.deal?.lists?.regions || []}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        renderInput={(params) => <TextField {...params} label='Region' />}
      />
    </Box>
  );
}

export default DealRegionField;
