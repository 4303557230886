import React, { useState } from 'react';

import {
  styled,
  useTheme,
  Container,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import AdminSponsors from './Sponsors';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function AdminDashboard({ colorInvert = false }) {
  const [selected_page, setSelectedPage] = useState(0);

  const theme = useTheme();
  const { mode } = theme.palette;

  const pages = [
    {
      id: 'sponsor',
      label: 'Sponsors',
      component: <AdminSponsors />,
      icon: <PersonOutlineOutlinedIcon />,
    },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
        variant='permanent'
        anchor='left'
      >
        <DrawerHeader>
          <Box
            sx={{
              mr: 2,
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            <Box
              component='a'
              href='/'
              title='IOM.ai'
              width={{ xs: 100, md: 120 }}
            >
              <Box display={'flex'} alignItems={'center'} width={1}>
                <Box
                  component={'img'}
                  src={
                    mode === 'light' && !colorInvert ? '/logo.png' : '/logo.png'
                  }
                  height={1}
                  width={1}
                />
              </Box>
            </Box>
          </Box>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map((page) => {
            return (
              <ListItem key={page.id} disablePadding>
                <ListItemButton onClick={() => setSelectedPage(page.id)}>
                  <ListItemText primary={page.label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Drawer>

      <Container sx={{ padding: '15px' }}>
        {pages[selected_page].component}
      </Container>
    </Box>
  );
}

export default AdminDashboard;
