import React, { useEffect, useState, useMemo, useCallback } from 'react';

import {
  Container,
  Typography,
  Toolbar,
  Stack,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';

import { Link } from 'react-router-dom';
import { useUser } from '../../../providers/UserContext';
import LoadingMask from '../../shared/LoadingMask';
import { getSponsor } from '../../../actions/sponsor';
import STATUSES from '../../shared/statuses';
import {
  getSponsorInvestorSponsorInquiries,
  updateInvestorSponsorInquiry,
} from '../../../actions/investorSponsorInquiry';
import InvestorProfile from './InvestorProfile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

const sortInquiries = (inquiries) => {
  return inquiries.sort((a, b) => a?.created_at - b?.created_at);
};

function InvestorActivity() {
  const user = useUser();
  const [sponsor, setSponsor] = useState(undefined);
  const [inquiries, setInquiries] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [selectedInquiries, setSelectedInquiries] = useState([]);
  const [transitioningInquiries, setTransitioningInquiries] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState(undefined);
  const [profileOpen, setProfileOpen] = useState(undefined);

  useEffect(() => {
    if (sponsor && sponsor.status === STATUSES.ACTIVE) {
      if (!inquiries) {
        getSponsorInvestorSponsorInquiries(sponsor.entity_id).then(
          (payload) => {
            setInquiries(sortInquiries(payload));
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
    }
  }, [sponsor, inquiries]);

  useEffect(() => {
    if (user.primary_sponsor && !sponsor) {
      getSponsor(user.primary_sponsor).then((payload) => {
        setSponsor(payload);
      });
    }
  }, [user, sponsor]);

  const rejectInquiry = useCallback(
    async (inquiry_id) => {
      setTransitioningInquiries((prevTransitioningInquiries) => [
        ...prevTransitioningInquiries,
        inquiry_id,
      ]);
      const new_inquiry = await updateInvestorSponsorInquiry(
        sponsor.entity_id,
        inquiry_id,
        { status: STATUSES.REJECTED }
      );
      setInquiries((prevInquiries) =>
        sortInquiries([
          ...prevInquiries.filter(
            (inquiry) => inquiry.entity_id !== inquiry_id
          ),
          new_inquiry,
        ])
      );
      setTransitioningInquiries((prevTransitioningInquiries) =>
        prevTransitioningInquiries.filter(
          (transitioning_inquiry_id) => transitioning_inquiry_id !== inquiry_id
        )
      );
    },
    [sponsor]
  );

  const acceptInquiry = useCallback(
    async (inquiry_id) => {
      setTransitioningInquiries((prevTransitioningInquiries) => [
        ...prevTransitioningInquiries,
        inquiry_id,
      ]);
      const new_inquiry = await updateInvestorSponsorInquiry(
        sponsor.entity_id,
        inquiry_id,
        { status: STATUSES.ACCEPTED }
      );
      setInquiries((prevInquiries) =>
        sortInquiries([
          ...prevInquiries.filter(
            (inquiry) => inquiry.entity_id !== inquiry_id
          ),
          new_inquiry,
        ])
      );
      setTransitioningInquiries((prevTransitioningInquiries) =>
        prevTransitioningInquiries.filter(
          (transitioning_inquiry_id) => transitioning_inquiry_id !== inquiry_id
        )
      );
    },
    [sponsor]
  );

  const renderStatus = useCallback(
    (params) => {
      if (transitioningInquiries.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      if (params.row.status === STATUSES.ACCEPTED) {
        return <CheckCircleOutlineIcon color='success' />;
      }
      if (params.row.status === STATUSES.REJECTED) {
        return <CancelOutlinedIcon color='error' />;
      }
      return <NewReleasesOutlinedIcon />;
    },
    [transitioningInquiries]
  );

  const renderName = useCallback((params) => {
    return (
      <Typography
        component={Link}
        onClick={() => {
          setSelectedInvestor(params.row.investor);
          setProfileOpen(true);
        }}
        variant='body2'
      >
        {params.row.investor?.first_name} {params.row.investor?.last_name}
      </Typography>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 2,
        renderCell: renderName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        type: 'string',
        // renderCell: renderCreatedDate,
        minWidth: 150,
        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              icon={<ThumbUpAltOutlinedIcon color='primary' />}
              label='Accept'
              onClick={() => acceptInquiry(params.id)}
              showInMenu
            />
          );
          actions.push(
            <GridActionsCellItem
              icon={<ThumbDownOffAltOutlinedIcon color='error' />}
              label='Reject'
              onClick={() => rejectInquiry(params.id)}
              showInMenu
            />
          );
          return actions;
        },
      },
    ],
    [renderName, renderStatus, acceptInquiry, rejectInquiry]
  );

  const InvestorInquiriesGridToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <Stack direction='row' spacing={1}>
          <Button
            variant='outlined'
            disabled={!selectedInquiries.length}
            onClick={async () => {
              await Promise.all(
                [].concat(
                  selectedInquiries.map(async (inquiry_id) => {
                    await acceptInquiry(inquiry_id);
                  })
                )
              );
            }}
          >
            <Stack direction='row' spacing={1} alignItems='center'>
              <ThumbUpAltOutlinedIcon />
            </Stack>
          </Button>
          <Button
            variant='outlined'
            color='error'
            disabled={!selectedInquiries.length}
            onClick={async () => {
              await Promise.all(
                [].concat(
                  selectedInquiries.map(async (inquiry_id) => {
                    await rejectInquiry(inquiry_id);
                  })
                )
              );
            }}
          >
            <Stack direction='row' spacing={1} alignItems='center'>
              <ThumbDownOffAltOutlinedIcon />
            </Stack>
          </Button>
          <GridToolbarColumnsButton variant='outlined' color='inherit' />
          <GridToolbarFilterButton variant='outlined' color='inherit' />
        </Stack>
      </GridToolbarContainer>
    );
  }, [selectedInquiries, acceptInquiry, rejectInquiry]);

  if (user.entity_id && !user.primary_sponsor) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                You must create a sponsor to get investor inquiries.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                component={Link}
                to='/account'
                variant='contained'
                color='primary'
              >
                My Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (sponsor && sponsor.status !== STATUSES.ACTIVE) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                Only accounts with active sponsors can get investor inquiries.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                component={Link}
                to='/account'
                variant='contained'
                color='primary'
              >
                My Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Container sx={{ padding: '15px' }}>
      <Grid container spacing={4}>
        <Grid xs={12} md={12} lg={12}>
          <Toolbar>
            <Stack spacing={2} direction='row' alignItems='center'>
              <Typography variant='h4'>
                {sponsor.name} Investor Inquiries
              </Typography>
            </Stack>
          </Toolbar>
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  autoHeight
                  checkboxSelection
                  getRowId={(row) => row.entity_id}
                  columns={columns}
                  rows={inquiries || []}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectedInquiries(newSelectionModel);
                  }}
                  onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectedInquiries(newSelectionModel);
                  }}
                  rowSelectionModel={selectedInquiries}
                  slots={{
                    toolbar: InvestorInquiriesGridToolbar,
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <InvestorProfile
        investor={selectedInvestor}
        open={profileOpen}
        handleClose={() => {
          setProfileOpen(false);
          setSelectedInvestor(undefined);
        }}
      />
    </Container>
  );
}

export default InvestorActivity;
