import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, TextField } from '@mui/material';

export default function ClosedDealRealizedIRRField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl fullWidth>
        <NumericFormat
          value={value}
          suffix='%'
          onValueChange={handleChange}
          customInput={TextField}
          id='realized_irr'
          label='Realized IRR'
          error={Boolean(error)}
          variant='standard'
          thousandSeparator=','
        />
      </FormControl>
    </Box>
  );
}
