import api from '../apis/api';

export const getDeal = async (deal_id) => {
  const resp = await api.get(`/deal/${deal_id}`);
  return resp.data;
};

export const getActiveDeals = async (limit = 20, offset = 0, filters = {}) => {
  const params_list = [
    ['limit', limit],
    ['offset', offset],
  ];
  Object.entries(filters).forEach(([k, v]) => {
    if (v?.length) {
      v.forEach((id) => {
        params_list.push([k, id]);
      });
    }
  });
  const params = new URLSearchParams(params_list);
  const resp = await api.get(`/search/deal?${params.toString()}`);

  return resp.data;
};
