import api from '../apis/api';

export const putAsset = async (path, content_type, data) => {
  const resp = await api.put(`/static/${path}`, data, {
    headers: {
      'Content-Type': content_type,
    },
  });
  return resp.data;
};
export const deleteAsset = async (path) => {
  const resp = await api.delete(`/static/${path}`);
  return resp.data;
};
