import React from 'react';

import { FormControlLabel, Switch, Box, FormHelperText } from '@mui/material';

export default function SponsorTeamMemberLeadershipField({
  value,
  error,
  setValue,
  setError,
}) {
  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
        }
        label='Leadership team'
      />
      <FormHelperText>
        The profiles of leadership team members appear on the sponsor main page.
      </FormHelperText>
    </Box>
  );
}
