import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Oops from './Oops';
import Initializing from './Initializing';
import Account from './Account';
import Login from './Auth/Login';
import AuthCallback from './Auth/AuthCallback';
import ProtectedRoute from './ProtectedRoute';
import NewUserOnboarding from './Account/NewUserOnboarding';
import Deals from './Deals';
import DealForm from './Deals/DealForm';
import Layout from './Layout';
import SponsorForm from './Sponsors/SponsorForm';
import Deal from './Deals/Deal';
import Sponsor from './Sponsors/Sponsor';
import SponsorSearch from './Search/Sponsors';
import DealSearch from './Search/Deals';
import InvestorActivity from './Sponsors/InvestorActivity';
import Activity from './Investing/Activity';
import AdminDashboard from './AdminDashboard';

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [
        {
          path: '*',
          element: <Oops />,
          children: [],
        },
        {
          path: '/',
          element: <SponsorSearch />,
          children: [],
        },
        {
          path: '/login',
          element: <Login />,
          children: [],
        },
        {
          path: '/authenticate',
          element: <AuthCallback />,
          children: [],
        },
        {
          path: '/account',
          element: (
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/account/new',
          element: <NewUserOnboarding />,
          children: [],
        },
        {
          path: '/deals',
          element: (
            <ProtectedRoute>
              <Deals />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/deals/edit',
          element: (
            <ProtectedRoute>
              <DealForm />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/sponsor/edit',
          element: (
            <ProtectedRoute>
              <SponsorForm />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/sponsor/activity',
          element: (
            <ProtectedRoute>
              <InvestorActivity />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/deal/:deal_id',
          element: (
            <ProtectedRoute>
              <Deal />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/sponsor/:sponsor_id',
          element: (
            <ProtectedRoute>
              <Sponsor />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/search/sponsors',
          element: <SponsorSearch />,
          children: [],
        },
        {
          path: '/search/deals',
          element: (
            <ProtectedRoute>
              <DealSearch />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/activity',
          element: (
            <ProtectedRoute>
              <Activity />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/admin',
          element: (
            <ProtectedRoute admin>
              <AdminDashboard />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/admin/deal/edit',
          element: (
            <ProtectedRoute admin>
              <DealForm admin />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/admin/sponsor/edit',
          element: (
            <ProtectedRoute admin>
              <SponsorForm admin />
            </ProtectedRoute>
          ),
          children: [],
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_startTransition: true,
    },
  }
);

function App() {
  return <RouterProvider router={router} fallbackElement={<Initializing />} />;
}

export default App;
