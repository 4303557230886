import api from '../apis/api';

export const getUser = async () => {
  const resp = await api.get('/user');
  return resp.data;
};

export const updateUser = async (values) => {
  const resp = await api.put('/user', values);
  return resp.data;
};

export const deleteUser = async () => {
  const resp = await api.delete('/user');
  return resp.data;
};

export const createUser = async () => {
  const resp = await api.post('/user');
  return resp.data;
};
