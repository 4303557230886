import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../providers/actionTypes';
import { useUser } from '../../../providers/UserContext';
import InvestorDetailsDependentsField from './InvestorDetailsDependentsField';
import InvestorDetailsAccreditedField from './InvestorDetailsAccreditedField';
import InvestorDetailsProfessionalField from './InvestorDetailsProfessionalField';
import InvestorDetailsEmploymentStatusField from './InvestorDetailsEmploymentStatusField';
import InvestorDetailsAnnualIncomeField from './InvestorDetailsAnnualIncomeField';
import InvestorDetailsCashAndInvestmentsField from './InvestorDetailsCashAndInvestmentTypeField';
import InvestorDetailsInvestmentExperienceField from './InvestorDetailsInvestmentExperienceField';
import InvestorDetailsInvestmentGoalField from './InvestorDetailsInvestmentGoalField';
import InvestorDetailsInvestmentHorizonField from './InvestorDetailsInvestmentHorizonField';
import InvestorDetailsMaritalStatusField from './InvestorDetailsMaritalStatusField';
import InvestorDetailsNetWorthField from './InvestorDetailsNetWorthField';
import InvestorDetailsRiskToleranceField from './InvestorDetailsRiskToleranceField';
import InvestorDetailsSourceOfFundsField from './InvestorDetailsSourceOfFundsField';
import InvestorDetailsWithdrawLikelihoodField from './InvestorDetailsWithdrawLikelihoodField';

function InvestorDetailsForm({ setValues }) {
  const user = useUser();
  const formErrorDispatch = useFormErrorDispatch();
  const [revision, setRevision] = useState(0);
  const [accredited, setAccredited] = useState(user.accredited || false);
  const [dependents, setDependents] = useState(user.dependents);
  const [professional, setProfessional] = useState(user.professional || false);
  const [dependents_error, setDependentsError] = useState(false);
  const [annual_income_type, setAnnualIncomeType] = useState(
    user.annual_income_type
  );
  const [cash_and_investment_type, setCashAndInvestmentType] = useState(
    user.cash_and_investment_type
  );
  const [employment_status, setEmploymentStatus] = useState(
    user.employment_status
  );
  const [investment_experience_type, setInvestmentExperienceType] = useState(
    user.investment_experience_type
  );
  const [investment_goal, setInvestmentGoal] = useState(user.investment_goal);
  const [investment_horizon, setInvestmentHorizon] = useState(
    user.investment_horizon
  );
  const [marital_status, setMaritalStatus] = useState(user.marital_status);
  const [net_worth_type, setNetWorthType] = useState(user.net_worth_type);
  const [risk_tolerance_type, setRiskToleranceType] = useState(
    user.risk_tolerance_type
  );
  const [source_of_funds, setSourceOfFunds] = useState(user.source_of_funds);
  const [withdraw_likelihood, setWithdrawLikelihood] = useState(
    user.withdraw_likelihood
  );

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [user]);

  useEffect(() => {
    if (dependents_error) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [formErrorDispatch, dependents_error]);

  useEffect(() => {
    setValues({
      accredited,
      dependents: parseInt(dependents),
      professional,
      annual_income_type,
      cash_and_investment_type,
      employment_status,
      investment_experience_type,
      investment_goal,
      investment_horizon,
      marital_status,
      net_worth_type,
      risk_tolerance_type,
      source_of_funds,
      withdraw_likelihood,
    });
  }, [
    setValues,
    accredited,
    dependents,
    professional,
    annual_income_type,
    cash_and_investment_type,
    employment_status,
    investment_experience_type,
    investment_goal,
    investment_horizon,
    marital_status,
    net_worth_type,
    risk_tolerance_type,
    source_of_funds,
    withdraw_likelihood,
  ]);
  return (
    <Box width='100%' padding='15px'>
      {/* <Paper sx={{ display: 'flex', width: '100%' }}> */}
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 600,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsEmploymentStatusField
                  key={`employment_status_${revision}`}
                  value={employment_status}
                  setValue={setEmploymentStatus}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsAnnualIncomeField
                  key={`annual_income_type_${revision}`}
                  value={annual_income_type}
                  setValue={setAnnualIncomeType}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsMaritalStatusField
                  key={`marital_status_${revision}`}
                  value={marital_status}
                  setValue={setMaritalStatus}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsDependentsField
                  key={`dependents_${revision}`}
                  value={dependents}
                  error={dependents_error}
                  setValue={setDependents}
                  setError={setDependentsError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsCashAndInvestmentsField
                  key={`cash_and_investment_type_${revision}`}
                  value={cash_and_investment_type}
                  setValue={setCashAndInvestmentType}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsNetWorthField
                  key={`net_worth_type_${revision}`}
                  value={net_worth_type}
                  setValue={setNetWorthType}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsSourceOfFundsField
                  key={`source_of_funds_${revision}`}
                  value={source_of_funds}
                  setValue={setSourceOfFunds}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsAccreditedField
                  key={`accredited_${revision}`}
                  value={accredited}
                  setValue={setAccredited}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsProfessionalField
                  key={`professional_${revision}`}
                  value={professional}
                  setValue={setProfessional}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsInvestmentExperienceField
                  key={`investment_experience_type_${revision}`}
                  value={investment_experience_type}
                  setValue={setInvestmentExperienceType}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsInvestmentGoalField
                  key={`investment_goal_${revision}`}
                  value={investment_goal}
                  setValue={setInvestmentGoal}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsInvestmentHorizonField
                  key={`investment_horizon_${revision}`}
                  value={investment_horizon}
                  setValue={setInvestmentHorizon}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsRiskToleranceField
                  key={`risk_tolerance_type_${revision}`}
                  value={risk_tolerance_type}
                  setValue={setRiskToleranceType}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <InvestorDetailsWithdrawLikelihoodField
                  key={`withdraw_likelihood_${revision}`}
                  value={withdraw_likelihood}
                  setValue={setWithdrawLikelihood}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </Paper> */}
    </Box>
  );
}

export default InvestorDetailsForm;
