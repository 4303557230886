import React from 'react';
import dayjs from 'dayjs';
import { FormControl, Box, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DealOfferStartDateField({
  value,
  error,
  setValue,
  setError,
  minDate,
  maxDate,
}) {
  const handleChange = (value) => {
    setValue(value.format('L'));
  };
  return (
    <Box>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Offering Start Date'
            value={dayjs(value)}
            onChange={handleChange}
            aria-describedby='offer-start-date-helper-text'
            id='offer_start_date'
            onError={(newError) => setError(newError)}
            minDate={minDate}
            maxDate={maxDate}
          />
        </LocalizationProvider>

        {error ? (
          <FormHelperText id='offer-start-date-helper-text'>
            Offering start date must be before end date.
          </FormHelperText>
        ) : (
          <FormHelperText id='offer-start-date-helper-text'>
            Enter the date the offering will open to new investors.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
