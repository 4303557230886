import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Avatar,
  Divider,
  Typography,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import { useMetadata } from '../../../../providers/MetadataContext';
import { Link } from 'react-router-dom';

function InvestorProfile({ investor, open, handleClose }) {
  const metadata = useMetadata();
  if (!investor) {
    return (
      <Dialog onClose={() => handleClose()} open={open}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              Investor Not Found
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={() => handleClose()} open={open} maxWidth={800}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs={2}>
            <Avatar
              // sx={{ bgcolor: red[500] }}
              aria-label='team member'
              alt={investor.first_name}
              src={investor.picture}
              sx={{ width: 56, height: 56 }}
            ></Avatar>
          </Grid>
          <Grid item xs>
            {investor.first_name} {investor.last_name}
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            maxWidth: 800,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant='h5'>Investing Needs</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>Accredited</Typography>
                {investor.accredited ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>Professional Investor</Typography>
                {investor.professional ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>1031 Exchange Eligible</Typography>
                {investor.ten_thirty_one ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Investment Experience:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (
                      metadata?.user?.lists?.investment_experience_types || []
                    ).find(
                      (option) =>
                        option.entity_id === investor.investment_experience_type
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Investment Goal:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.investment_goals || []).find(
                      (option) => option.entity_id === investor.investment_goal
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Investment Horizon:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.investment_horizons || []).find(
                      (option) =>
                        option.entity_id === investor.investment_horizon
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Risk Tolerance:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.risk_tolerance_types || []).find(
                      (option) =>
                        option.entity_id === investor.risk_tolerance_type
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Likelihood of Withdraw:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.withdraw_likelihoods || []).find(
                      (option) =>
                        option.entity_id === investor.withdraw_likelihood
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider variant='middle' flexItem />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography variant='h5'>Financial Profile</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Employment Status:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.employment_statuses || []).find(
                      (option) =>
                        option.entity_id === investor.employment_status
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Annual Income:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.annual_income_types || []).find(
                      (option) =>
                        option.entity_id === investor.annual_income_type
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Cash and Investments:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (
                      metadata?.user?.lists?.cash_and_investment_types || []
                    ).find(
                      (option) =>
                        option.entity_id === investor.cash_and_investment_type
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Net Worth:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.net_worth_types || []).find(
                      (option) => option.entity_id === investor.net_worth_type
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Source of Funds:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.sources_of_funds || []).find(
                      (option) => option.entity_id === investor.source_of_funds
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%' }}>
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='body1'>
                  <b>Martial Status:</b>
                </Typography>
                <Typography variant='body1'>
                  {
                    (metadata?.user?.lists?.marital_statuses || []).find(
                      (option) => option.entity_id === investor.marital_status
                    )?.name
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider variant='middle' flexItem />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant='h6'>Contact Information</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>
                  <b>Email:</b>
                </Typography>
                <Typography
                  variant='body1'
                  component={Link}
                  to={`mailto:${investor.email}`}
                >
                  {investor.email}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>
                  <b>Phone:</b>
                </Typography>
                <Typography
                  variant='body1'
                  component={Link}
                  to={`tel:${investor.phone}`}
                >
                  {investor.phone}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>
                  <b>Address:</b>
                </Typography>
                <Typography variant='body1'>
                  {investor.address || 'Not Provided'}
                </Typography>
              </Stack>
            </Grid>
            {investor.address2 ? (
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction='row' spacing={1}>
                  <Typography variant='body1'>
                    <b>Address (Line 2):</b>
                  </Typography>
                  <Typography variant='body1'>
                    {investor.address2 || 'Not Provided'}
                  </Typography>
                </Stack>
              </Grid>
            ) : undefined}

            <Grid item xs={12} md={6} lg={6}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>
                  <b>City:</b>
                </Typography>
                <Typography variant='body1'>
                  {investor.city || 'Not Provided'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>
                  <b>State:</b>
                </Typography>
                <Typography variant='body1'>
                  {investor.state || 'Not Provided'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>
                  <b>Postal Code:</b>
                </Typography>
                <Typography variant='body1'>
                  {investor.postal_code || 'Not Provided'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>
                  <b>Country:</b>
                </Typography>
                <Typography variant='body1'>
                  {investor.country || 'Not Provided'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default InvestorProfile;
