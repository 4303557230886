import React, { useEffect, useState } from 'react';

import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../providers/FormErrorContext';
import { FORM_CLEAR_ERROR, FORM_SET_ERROR } from '../../providers/actionTypes';

function TermsAndConditions({ setValues }) {
  const [accepted, setAccepted] = useState(false);
  const formErrorDispatch = useFormErrorDispatch();

  useEffect(() => {
    if (!accepted) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [accepted, formErrorDispatch]);

  useEffect(() => {
    setValues({
      terms_version: accepted ? '2023-09-26' : null,
    });
  }, [accepted, setValues]);

  return (
    <Box width='100%' padding='15px'>
      {/* <Paper sx={{ display: 'flex', width: '100%' }}> */}
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box>
            <Grid container spacing={6}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h3'>Terms Of Service</Typography>
                <br />
                <Typography variant='body1'>
                  Welcome to the InvestOnMain.com/IOM.ai website, owned and
                  operated by Invest On Main LLC ("IOM” or the "Company”).
                  Please read these Terms of Service (the "Terms”) carefully
                  before using the services offered by IOM. These Terms sets
                  forth the legally binding terms and conditions for your use of
                  the website at IOM.ai, all other sites owned and operated by
                  IOM that redirect to IOM.ai, and all subdomains (collectively,
                  the "Site”), and the services provided by the Company (the
                  "Services”). By accessing, visiting, browsing, or contributing
                  to the Site, you agree to be bound by these Terms, the Privacy
                  Policy, and all other operating rules, policies and procedures
                  that may be published by IOM from time to time on the Site
                  (collectively, the "Published Information”), each of which is
                  incorporated by reference and each of which may be updated by
                  IOM.ai from time to time without notice to you. In order to
                  access the Site and utilize any of the Services, you must
                  accept these Terms, the Privacy Policy and any Published
                  Information.
                </Typography>
                <br />
                <Typography variant='body1'>
                  Security facilities have been introduced by us for protecting
                  your personal data when you enter, transmit or work with your
                  personal information. The following terms and conditions
                  govern all use of the IOM.ai website and all content, services
                  and products available at or through the IOM.ai website (taken
                  together, the Website). The IOM.ai website is offered subject
                  to your acceptance without modification of all of the terms
                  and conditions contained herein and all other operating rules,
                  policies (including, without limitation, InvestOnMain LLC and
                  IOM.ai Privacy Policy) and procedures that may be published
                  from time to time on this site by InvestOnMain LLC
                  (collectively, the "Agreement”).
                </Typography>
                <br />
                <Typography variant='body1'>
                  Please read this Agreement carefully before accessing or using
                  the IOM.ai Website. By accessing or using any part of the web
                  site, you agree to become bound by the terms and conditions of
                  this agreement. If you do not agree to all the terms and
                  conditions of this agreement, then you may not access the
                  IOM.ai Website or use any services. If these terms and
                  conditions are considered an offer by InvestOnMain LLC,
                  acceptance is expressly limited to these terms. The IOM.ai
                  Website is available only to individuals who are at least 18
                  years old.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>
                  PAYMENT, INVESTMENT & RENEWAL
                </Typography>
                <br />
                <Typography variant='body1'>
                  Your use of the Site and Services is governed by the then
                  current version of these Terms in effect on the date of such
                  use. IOM may, at its sole discretion, modify and replace these
                  Terms at any time and without prior notice to you by posting
                  the updated Terms on the Site. By using and accessing the
                  Site, you acknowledge and agree to review the then-current
                  version of the Terms prior to each such use. Your continued
                  use of the Site after any modifications to the Terms have been
                  published on the Site, or otherwise provided to you,
                  constitutes your acknowledgement of, and binding agreement to,
                  the then-current Terms. Notwithstanding the foregoing, the
                  resolution of any dispute that arises between you and IOM will
                  be governed by the Terms in effect at the time of the events
                  giving rise to such dispute. IOM further reserves the right,
                  in its sole discretion, to block or otherwise discontinue your
                  access and use of the Site at any time and for any reason. You
                  agree that IOM and its subsidiaries and affiliates will not be
                  liable to you or to any third party for any such modification,
                  suspension or discontinuance.
                </Typography>
                <br />
                <Typography variant='body1'>
                  Please also note that these Terms are in addition to any other
                  agreements between you and IOM, including any customer,
                  account or marketing agreements, and any other agreements that
                  govern your use of products, services, content, tools, and
                  information available on the Site. These terms do not alter in
                  any way the terms or conditions of any other agreement you may
                  have with IOM, or its subsidiaries or affiliates, for the
                  Service or otherwise. If you do not agree to all of these
                  Terms, IOM's Privacy Policy and/or any Published Information,
                  you are not eligible to participate in IOM's Services or
                  otherwise use this Site.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>USER REQUIREMENTS</Typography>
                <br />
                <Typography variant='body1'>
                  The Services are available only to individuals who are at
                  least 18 years old (and at least the legal age in the
                  jurisdiction of such user's domicile). You represent and
                  warrant that if you are an individual, you are at least 18
                  years old and of legal age in your jurisdiction to form a
                  binding contract, and that all registration information you
                  submit is accurate and truthful. IOM may, in its sole
                  discretion, refuse to offer the Services to any person or
                  entity. The Services are not available to any users suspended
                  or removed from the Site or Services by IOM. By accessing the
                  Site and/or using the Services, you represent that you have
                  not been previously suspended or removed from the Site or
                  Services.
                </Typography>
                <br />
                <Typography variant='body1'>
                  In consideration of your use of the Site, you agree to (a)
                  provide accurate, current and complete information about you
                  as may be prompted by any registration forms on the Site
                  ("Registration Data”); (b) maintain the security of your
                  password and identification; (c) maintain and promptly update
                  the Registration Data, and any other information you provide
                  to IOM, to keep it accurate, current and complete; and (d) be
                  fully responsible for all use of your account and for any
                  actions that take place using your account. You agree to
                  immediately notify IOM of any unauthorized use of your
                  password or account or any other breach of security. IOM will
                  not be liable for any loss or damage arising from your failure
                  to comply with this Section.
                </Typography>
                <br />
                <Typography variant='body1'>
                  In order to invest in any of the securities offered on this
                  Site, you must be an "Accredited Investor" as defined in Rule
                  501 of Regulation D of the Securities Act of 1933, as amended
                  (the "Securities Act”). In general, to qualify as an
                  Accredited Investor, individuals must have a net worth of more
                  than $1 million (excluding their primary residence), or gross
                  income for each of the last two years of at least $200,000
                  ($300,000 jointly with their spouse) with the expectation of a
                  similarly qualifying income during the current year. You will
                  be required to provide supporting documents to IOM to provide
                  verification that you are an Accredited Investor. Such
                  verification may require completion of an Accredited Investor
                  questionnaire, submission of an Internal Revenue Service form
                  (e.g., a W-2), completion of a satisfactory background
                  information screening, and/or other confirmations or
                  documentation. Your failure to provide any information and
                  documentation requested to confirm your status as an
                  Accredited Investor will preclude you from participating in
                  any of the investment opportunities presented on the Site.
                </Typography>
                <br />
                <Typography variant='body1'>
                  For offers and sales that occur outside of the United States,
                  persons are only allowed access to such investment
                  opportunities if such person represents that his or her access
                  does not violate the laws of his or her country of residence.
                  Pages of the Site that relate to the viewing of investment
                  opportunities or to making investments in the securities
                  offered therein may not be used by any person or entity in any
                  jurisdiction where the provision or use thereof would be
                  contrary to applicable laws, rules or regulations of any
                  governmental authority or where IOM is not authorized to
                  provide such information or services. Your use of the Service
                  or the Site constitutes your affirmative acknowledgment that
                  you are not in violation of the laws of your country of
                  residence and a waiver of the protection of any local non-U.S.
                  laws.
                </Typography>
                <br />
                <Typography variant='body1'>
                  You may not become a beneficial owner of 20% or more of any
                  issuer's outstanding voting equity securities (an "Issuer
                  Covered Person”) without becoming subject to certain "bad
                  actor” disqualifying events described in Rule 506(d) under the
                  Securities Act (a "Disqualifying Event”). You represent that
                  you are not subject to a Disqualifying Event and that you will
                  promptly notify IOM in writing should any Disqualifying Events
                  be applicable to you. IOM is not liable or responsible for
                  making Rule 506(e) disclosures where an Issuer Covered Person
                  fails to provide notice of a Disqualify Event or for
                  determining whether any Issuer Covered Person is subject to a
                  Disqualifying Event.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>
                  SECURITIES OFFERINGS; NO PROFESSIONAL ADVICE PROVIDED
                </Typography>
                <br />
                <Typography variant='body1'>
                  The securities offered on the Site have not been registered
                  under the Securities Act in reliance on the exemptive
                  provisions of Section 4(a)(2) of the Securities Act and Rule
                  506, and/or Regulation S, promulgated thereunder. Securities
                  sold through private placements are restricted and not
                  publicly traded, and are therefore illiquid. Neither the U.S.
                  Securities and Exchange Commission nor any state securities
                  commission or other regulatory authority has approved, passed
                  upon or endorsed the merits of any offering on this Site. IOM
                  is not a registered broker-dealer, funding portal, or
                  investment advisor and IOM does not conduct any activity that
                  would require such registration.
                </Typography>
                <br />
                <Typography variant='body1'>
                  Investment overviews on the Site contain summaries of the
                  purpose and principal business terms of the investment
                  opportunities. Such summaries are intended for informational
                  purposes only and do not purport to be complete, and each is
                  qualified in its entirety by reference to the more detailed
                  discussions contained in the investor document package
                  relating to such investment opportunity. The information
                  contained on the Site has been prepared without reference to
                  your investment requirements or financial situation, and
                  potential investors are encouraged to consult with
                  professional tax, legal and financial advisors before making
                  any investment.
                </Typography>
                <br />
                <Typography variant='body1'>
                  IOM does not guarantee the performance of any offerings made
                  through the Site or Services, and any and all projections,
                  estimates, and expectations for investments offered through
                  the Site or Services are merely opinions and should not be
                  relied on for any purpose whatsoever. The value of investments
                  and the income from them can fall as well as rise. Past
                  performance is not a guarantee of future performance. The
                  contents of this Site do not constitute financial, legal, or
                  tax advice. You are solely responsible for conducting any
                  legal, accounting or other due diligence review. You should
                  obtain investment and tax advice from your advisers before
                  deciding to invest. You acknowledge and agree that IOM is not
                  a registered Investment Company or Investment Advisor within
                  the meaning of the Investment Company Act of 1940 and the
                  Investment Advisors Act of 1940, respectively. You should
                  obtain investment and tax advice from your advisers before
                  deciding to invest. Investment products are not FDIC insured,
                  may lose value, and there is no bank guarantee.
                </Typography>
                <br />
                <Typography variant='body1'>
                  None of the information contained on the Site constitutes a
                  recommendation, solicitation or offer to buy or sell any
                  securities, options or other financial instruments or other
                  assets or provide any investment advice or service. The
                  information and services provided on the Site are not provided
                  to, and may not be used by, any person or entity in any
                  jurisdiction where the provision or use thereof would be
                  contrary to applicable laws, rules or regulations of any
                  governmental authority or where the IOM is not authorized to
                  provide such information or services. Some products and
                  services described in the Site may not be available in all
                  jurisdictions or to all users.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>
                  UNAUTHORIZED USE/PROHIBITED ACTIVITIES
                </Typography>
                <br />
                <Typography variant='body1'>
                  Unauthorized use of the Site and/or the Services, including,
                  but not limited to, unauthorized entry into IOM's systems, the
                  misuse or sharing of passwords or misuse of any other
                  information, is strictly prohibited. You may not use the Site
                  in any manner that could damage, disable, overburden, or
                  impair the Services or interfere with any other party's use
                  and enjoyment of the Services. You agree that you will not
                  engage in any activities related to the Site that are contrary
                  to applicable laws or regulations. You may be liable for any
                  losses incurred by IOM or others due to any unauthorized use
                  of your account.
                </Typography>
                <br />
                <Typography variant='body1'>
                  You further agree that you are responsible for any
                  unauthorized use of your password that is made before you have
                  notified IOM and before IOM has had a reasonable opportunity
                  to act on that notice. IOM reserves the right at its
                  discretion to suspend or cancel your account and/or password,
                  even without receiving such notice from you, if there is any
                  suspicion that it is being used in an unauthorized or
                  fraudulent manner.
                </Typography>
                <br />
                <Typography variant='body1'>
                  You are solely responsible for maintaining the confidentiality
                  of your account and password. You will also be liable for any
                  losses incurred by IOM or others due to any unauthorized use
                  of your account.
                </Typography>
                <br />
                <Typography variant='body1'>
                  You agree that you are responsible for your own conduct while
                  using the Site or Services and for any consequences thereof.
                  You agree to use the Services only for purposes that are
                  legal, proper and in accordance with these Terms, the IOM
                  Privacy Policy, any Published Information, and any applicable
                  law, rules or regulations (including without limitation the
                  Securities Act, the Securities Exchange Act of 1934, the
                  Investment Company Act of 1940, the Investment Advisers Act of
                  1940, any applicable state "Blue Sky” laws, any other
                  applicable United States federal or state securities laws,
                  regulations and rules, any securities exchange or
                  self-regulatory organization's rules or regulations, any
                  consumer protection, unfair competition, and
                  anti-discrimination laws or regulations and any applicable
                  foreign laws). By way of example, and not as a limitation, you
                  agree to not take any action that is listed below:
                </Typography>
                <br />
                <Typography variant='body1'>
                  <ul>
                    <li>
                      <i>
                        Infringes Rights. You agree not to upload, post, email,
                        transmit, submit, or otherwise make available through
                        the Site or Services any material or content that
                        infringes any patent, trademark, copyright, trade secret
                        or other proprietary right of any party, unless you are
                        the owner of such rights or have the permission of the
                        owner to post such content. You further agree to not
                        take any action that infringes any patent, trademark,
                        trade secret, copyright, right of publicity, or other
                        right of any other person or entity, or violates any law
                        or contract, including removing any copyright, trademark
                        or other proprietary rights notices contained in or on
                        the Site or Services.
                      </i>
                    </li>
                    <li>
                      <i>
                        Distribution without Consent. You agree not to
                        distribute in any medium any part of the Site or
                        Services or any content available thereon without IOM's
                        prior written authorization. The only exceptions are
                        where IOM makes available the means for such
                        distribution through functionality offered by the Site.
                      </i>
                    </li>
                    <li>
                      <i>
                        Alterations or Modifications. You agree not to alter or
                        modify any part of the Site or Services.
                      </i>
                    </li>
                    <li>
                      <i>
                        Submitting False, Defamatory or Harassing Information.
                        You agree not to upload, post, email, transmit or submit
                        or otherwise make available through the Site or Services
                        any inappropriate, defamatory, infringing, obscene,
                        false, misleading, inaccurate, harassing, threatening or
                        unlawful material or content or imply that such content
                        is sponsored or endorsed by IOM, any of its affiliates
                        or any third parties. You further agree not to take any
                        action that would defame, abuse, harass, stalk, threaten
                        or otherwise violate the legal rights (such as rights of
                        privacy and publicity) of others.
                      </i>
                    </li>
                    <li>
                      <i>
                        Unlawful Purposes. You agree not to use the Site or
                        Services for any illegal or unauthorized purpose
                        (including, without limitation, in violation of any
                        United States federal and state securities or blue sky
                        laws or regulations, securities exchange or
                        self-regulatory organization's rules or regulations,
                        consumer protection, unfair competition, and
                        anti-discrimination laws or regulations or equivalent
                        laws or regulations in foreign jurisdictions).
                      </i>
                    </li>
                    <li>
                      <i>
                        Non-Public Areas. You agree not to access, tamper with,
                        or use non-public areas of the Site or Services or any
                        other computer systems or networks connected to the Site
                        or Services.
                      </i>
                    </li>
                    <li>
                      <i>
                        Breach of Security Measures. You agree not to breach any
                        of IOM's security or authentication measures.
                      </i>
                    </li>
                    <li>
                      <i>
                        Unauthorized Access or Contact. You agree not to gain
                        unauthorized access to the Site, Services, any other IOM
                        website or service, or the computer systems or networks
                        connected to the Services through hacking, password
                        mining or any other means. You further agree not to
                        contact any party or user other than as allowed through
                        the Site or Services.
                      </i>
                    </li>
                    <li>
                      <i>
                        Generate Artificial Traffic. You agree not to use the
                        Services to artificially generate traffic or page links
                        to a website or for any other purpose not expressly
                        allowed under these Terms.
                      </i>
                    </li>
                    <li>
                      <i>
                        Commercial Uses. You agree not to use the Services for
                        any commercial uses or purposes, unless you obtain IOM's
                        prior written consent and approval. Such commercial uses
                        include but are not limited to: (i) soliciting other
                        users for investments of any kind; (ii) offering or
                        selling any products or services of any kind; (iii)
                        making investment recommendations to other users; and
                        (iv) advertising, sponsorships, or promotions placed on
                        or within the Site or Services.
                      </i>
                    </li>
                    <li>
                      <i>
                        Unlawful Schemes or Activities. You agree not to upload,
                        post, email or transmit, or otherwise make available
                        through the Site or Services any materials that promote
                        pyramid schemes, chain letters or disruptive commercial
                        messages or advertisements, or anything else prohibited
                        by law, these Terms, the Privacy Policy or any Published
                        Information. You further agree not to promote or provide
                        instructional information about illegal activities or
                        promote physical harm or injury against any group or
                        individual.
                      </i>
                    </li>
                    <li>
                      <i>
                        Disabling Security Features. You agree not to
                        circumvent, disable or otherwise interfere with
                        security-related features of the Services or any other
                        computer systems or networks connected to the Services.
                        You also agree not to circumvent, disable or otherwise
                        interfere with features that prevent or restrict use or
                        copying of any material or content or enforce Services
                        or content use limitations.
                      </i>
                    </li>
                    <li>
                      <i>
                        Impersonation. You agree not to impersonate any person
                        or entity, including any employee or representative of
                        IOM, or falsify or delete any author attributions, legal
                        or other proper notices or proprietary designations or
                        labels of the origin or source of any materials.
                      </i>
                    </li>
                    <li>
                      <i>
                        Other. You agree not to: (i) use the Services or take
                        any action that imposes or may impose (as determined by
                        IOM in its sole discretion) an unreasonable or
                        disproportionately large load on IOM's or its
                        third-party providers' infrastructure or that could
                        damage, disable, overburden, or impair the Services, or
                        interfere with any other party's use and enjoyment of
                        the Services; (ii) interfere or attempt to interfere
                        with the proper working of the Services or any
                        activities conducted on the Services; (iii) bypass any
                        measures IOM may use to prevent or restrict access to
                        the Services (or other accounts, computer systems, or
                        networks connected to the Services); (iv) run Maillist,
                        Listserv, or any form of auto-responder or "spam” on the
                        Services; (v) use any robot, spider, site
                        search/retrieval application, or other device to
                        retrieve or index any portion of the Services or collect
                        information about its users for any unauthorized
                        purpose; or (vi) transmit any viruses, worms, defects,
                        Trojan horses, or any items of a destructive nature.
                      </i>
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>PROPRIETARY RIGHTS</Typography>
                <br />
                <Typography variant='body1'>
                  All content on the Site, including but not limited to designs,
                  text, graphics, pictures, video, information, software, music,
                  sound and other files, and their selection and arrangement
                  (the "Site Content”), are IOM's proprietary property with all
                  rights reserved. No Site Content may be modified, copied,
                  distributed, framed, reproduced, republished, downloaded,
                  displayed, posted, transmitted, or sold in any form or by any
                  means, in whole or in part, without IOM's prior written
                  consent, except that, if you are eligible for use of the Site,
                  you are granted a limited license to access and use the Site
                  and to download or print a copy of any portion of the Site
                  Content solely for your personal use, provided that you keep
                  such portions confidential and all copyright or other
                  proprietary notices intact. You may not republish Site Content
                  on any internet, intranet or extranet site or incorporate the
                  information in any other database or compilation, and any
                  other use of the Site Content is strictly prohibited. Any use
                  of the Site or the Site Content other than as specifically
                  authorized herein, without the prior written permission of the
                  Company, is strictly prohibited and will terminate the license
                  granted herein. Such unauthorized use may also violate
                  applicable laws including without limitation copyright and
                  trademark laws and applicable communications regulations and
                  statutes. Unless explicitly stated herein, nothing in these
                  Terms shall be construed as conferring any license to
                  intellectual property rights, whether by estoppel, implication
                  or otherwise. IOM may revoke this license at any time without
                  notice and with or without cause.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>USER CONTENT</Typography>
                <br />
                <Typography variant='body1'>
                  The Services may allow you and other users to submit, post,
                  transmit and share content with other users. You are solely
                  responsible for any such content (which may include photos,
                  profiles, messages, notes, text, information, music, video,
                  contact information for you or others, advertisements or other
                  content) that you upload, publish, provide or display
                  (hereinafter, "post”) on or through the Services or the Site,
                  or transmit to or share with other users (collectively, the
                  "User Content”). It is against these Terms to contact
                  sponsoring real estate operating companies or borrowers
                  directly or to attempt to enter into any transactions with
                  such persons or entities outside of the Service. You
                  understand and agree that IOM may, but is not obligated to,
                  review and delete or remove (without notice) any User Content
                  in IOM's sole discretion, including without limitation, User
                  Content that in IOM's sole judgment violates these Terms,
                  might be offensive or illegal, or might violate the rights of,
                  harm, or threaten the safety of, Users or others.
                </Typography>
                <br />
                <Typography variant='body1'>
                  By posting User Content to any part of the Site, you
                  automatically grant, and you represent and warrant that you
                  have the right to grant IOM an irrevocable, perpetual,
                  non-exclusive, transferable, fully paid, worldwide license
                  (with the right to sublicense) to use, copy, publicly perform,
                  publicly display, reformat, translate, excerpt (in whole or in
                  part) and distribute such User Content for any purpose on or
                  in connection with the Site or the promotion thereof, to
                  prepare derivative works of, or incorporate into other works,
                  such User Content, and to grant and authorize sublicenses of
                  the foregoing. You may remove your User Content from the Site
                  at any time. If you choose to remove your User Content, the
                  license granted above will not expire.
                </Typography>
                <br />
                <Typography variant='body1'>
                  You acknowledge and agree that IOM may preserve content and
                  may also disclose content if required to do so by law or in
                  the good faith belief that such preservation or disclosure is
                  reasonably necessary to: (a) comply with legal process,
                  applicable laws or government requests; (b) enforce these
                  Terms; (c) respond to claims that any content violates the
                  rights of third parties; or (d) protect the rights, property,
                  or personal safety of IOM, its Users and the public. You
                  understand that the technical processing and transmission of
                  the Services, including your content, may involve (a)
                  transmissions over various networks; and (b) changes to
                  conform and adapt to technical requirements of connecting
                  networks or devices.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>
                  COPYRIGHT POLICY/COPYRIGHT COMPLAINTS
                </Typography>
                <br />
                <Typography variant='body1'>
                  IOM does not permit infringement of intellectual property
                  rights on the Site or Services, and IOM will remove copyright
                  infringing activities or related User Content if IOM is
                  properly notified that such User Content infringes on
                  another's intellectual property rights. If you believe that
                  your work has been copied in a way that constitutes copyright
                  infringement, or that any material on the Site violates these
                  Terms or your intellectual property rights, please notify IOM
                  by sending an email to hello@iom.ai, or by sending written
                  notification of such infringement to InvestOnMain Equity
                  Management LLC, 30 N Gould St Ste 1546 Sheridan, WY 82801,
                  with the following information pursuant to the Digital
                  Millennium Copyright Act ("DMCA”): (i) a physical or
                  electronic signature of a person authorized to act on behalf
                  of the owner of an exclusive right that is allegedly
                  infringed; (ii) identification of the copyrighted work claimed
                  to have been infringed, or, if multiple copyrighted works at a
                  single online site are covered by a single notification, a
                  representative list of such works at that site; (iii)
                  identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit IOM to locate
                  the material; (iv) information reasonably sufficient to permit
                  IOM to contact you, such as an address, telephone number and,
                  if available, an electronic mail address at which you may be
                  contacted; (v) a statement that you have a good-faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent or the law; and
                  (vi) a statement that the information in the notification is
                  accurate, and under penalty of perjury, that the you are
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>
                  CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES
                </Typography>
                <br />
                <Typography variant='body1'>
                  Because IOM offers its Services on this Site and on the
                  Internet, it is necessary for you to consent to transact
                  business with IOM online and electronically. Before you decide
                  to do business electronically with IOM, you must satisfy the
                  following computer hardware and software requirements: access
                  to the Internet; an email account and related software capable
                  of receiving email through the Internet; a web browser which
                  is SSL-compliant and supports secure sessions; and hardware
                  capable of running this software.
                </Typography>
                <br />
                <Typography variant='body1'>
                  IOM works with third-party escrow agents and administrators
                  and uses third-party bank accounts through which it receives
                  payments, and makes disbursements, through electronic funds
                  transfers (ACH transfers) using the bank (or other financial
                  institution) account information you may provide to IOM. If
                  you elect to send or receive electronic fund transfer, you
                  authorize such bank or other financial account to pay any
                  amounts described herein, and authorize IOM's agents to make
                  any and all investment disbursements to such account. You
                  agree to provide IOM with updated information regarding your
                  bank or other account upon IOM's request and at any time that
                  the information earlier provided is no longer valid.
                </Typography>
                <br />
                <Typography variant='body1'>
                  By using the Services, you consent to receive from IOM certain
                  disclosures electronically, either via the Site or to the
                  email address you provide to IOM, and by these Terms you agree
                  to receive electronically all documents, communications,
                  notices, contracts, and agreements, including any IRS Form
                  1099 or other tax forms, schedules or information statements,
                  arising from or relating to your registration as an investor
                  on the Site, any investments you may make, your use of this
                  Service, and the servicing of any investment you may make
                  (each, a "Disclosure”), from IOM or any of its service
                  providers. The decision to do business with IOM electronically
                  is yours. This document informs you of your rights concerning
                  Disclosures.
                </Typography>
                <br />
                <Typography variant='body1'>
                  Your consent to receive Disclosures and transact business
                  electronically, and IOM's agreement to do so, applies to any
                  transactions to which such Disclosures relate between you and
                  IOM. Your consent will remain in effect for so long as you are
                  a User and, if you are no longer a User, will continue until
                  such a time as all Disclosures relevant to transactions that
                  occurred while you were a User have been made. You may not
                  withdraw such consent as long as you have any outstanding
                  investments made through the Site. If you have no outstanding
                  investments made through the Site and wish to withdraw consent
                  to doing business electronically, IOM will terminate your
                  registered User account.
                </Typography>
                <br />
                <Typography variant='body1'>
                  You also expressly consent to receiving messages, including
                  emails (including SMS/text messages), and substantially
                  similar messages widely distributed on telephone answering or
                  voicemail systems from IOM, IOM's affiliates, marketing
                  partners, agents and others calling at their request or on
                  their behalf, at any telephone numbers that you have provided
                  or may provide in the future (including any cellular telephone
                  numbers). Your cellular or mobile telephone provider will
                  charge you according to the type of plan you carry. If you are
                  accessing the Site and the Disclosures electronically via a
                  mobile device (such as a smartphone, tablet, and the like), in
                  addition to the above requirements you must make sure that you
                  have software on your mobile device that allows you to print
                  and save the Disclosures presented to you during the
                  application process. These applications can be found for most
                  mobile devices in the device's respective "app store.” If you
                  do not have these capabilities on your mobile device, please
                  access the Site through a device that provides these
                  capabilities.
                </Typography>
                <br />
                <Typography variant='body1'>
                  Electronic Communication Privacy Act Notice (18 U.S.C.
                  §§2701-2711): IOM makes no guarantee of confidentiality or
                  privacy of any communication or information transmitted on the
                  Site or any website linked to the Site. IOM will not be liable
                  for the privacy of email addresses, registration and
                  identification information, disk space, communications,
                  confidential or trade-secret information, or any other content
                  stored on IOM's equipment, transmitted over networks accessed
                  by the Site, or otherwise connected with your use of the
                  Services.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>MODIFICATION</Typography>
                <br />
                <Typography variant='body1'>
                  IOM reserves the right, in its sole and absolute discretion
                  and without any prior notice to you, to modify, correct or
                  replace any of the terms of these Terms of Service, or change,
                  suspend, or discontinue the Site or the Services (including
                  without limitation, the availability of any feature, database
                  or content) at any time. Such modification shall become a part
                  of these Terms and is automatically effective once posted on
                  the Site. These Terms may not be orally amended. IOM may also
                  impose limits on certain features and services or restrict
                  your access to all or a part of the Site or Services without
                  notice or liability. It is your responsibility to check these
                  Terms periodically for changes. Your continued use of the
                  Services following the posting of any changes to the Terms of
                  Service constitutes your acceptance of those changes.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>LINKED SITES</Typography>
                <br />
                <Typography variant='body1'>
                  The Site may contain links to third party websites ("Linked
                  Sites”). Linked Sites are provided only as a convenience, and
                  when you access Linked Sites, you do so at your own risk.
                  Linked Sites are not under IOM's control, and you acknowledge
                  that IOM is not responsible or liable for the content,
                  functions, accuracy, legality, appropriateness or any other
                  aspect of such websites or resources. The inclusion of any
                  Linked Site on the Site does not imply an affiliation,
                  sponsorship, or endorsement by IOM or any association with its
                  operators. You further acknowledge and agree that IOM shall
                  not be responsible or liable, directly or indirectly, for any
                  damage or loss caused or alleged to be caused by or in
                  connection with the use of or reliance on any information,
                  goods or services available on or through any Linked Site or
                  resource. You should also be aware that the terms and
                  conditions and privacy policy of each Linked Site may be
                  different from these Terms applicable to the Site and
                  Services.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>TERMINATION</Typography>
                <br />
                <Typography variant='body1'>
                  IOM may terminate your access to all or any part of the Site
                  and Services at any time, with or without cause, with or
                  without notice, effective immediately, which may result in the
                  forfeiture and destruction of all information associated with
                  your account. If you wish to terminate your account, you may
                  do so by following the instructions on the Site. All
                  provisions of these Terms shall survive termination,
                  including, without limitation, ownership provisions, warranty
                  disclaimers, indemnity and limitations of liability.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>LIMITATION OF LIABILITY</Typography>
                <br />
                <Typography variant='body1'>
                  EXCEPT IN JURISDICTIONS WHERE SUCH PROVISIONS ARE RESTRICTED
                  AND EXCEPT AS MAY BE REQUIRED BY APPLICABLE SECURITIES LAWS,
                  IN NO EVENT WILL IOM OR ANY OF ITS DIRECTORS, OFFICERS, EQUITY
                  HOLDERS, MEMBERS, PARTNERS, EMPLOYEES OR REPRESENTATIVES BE
                  LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT,
                  CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
                  DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING
                  FROM YOUR USE OF THE SITE OR THE SERVICES (INCLUDING ANY
                  INVESTMENTS MADE) OR ANY OF THE SITE CONTENT OR OTHER
                  MATERIALS ON OR ACCESSED THROUGH THE SITE, EVEN IF WE ARE
                  AWARE OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </Typography>
                <br />
                <Typography variant='body1'>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                  EXCEPT AS MAY BE REQUIRED BY APPLICABLE SECURITIES LAW OR
                  OTHER LAWS, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY,
                  AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM IOM OF
                  ITS DIRECTORS, OFFICERS, EQUITY HOLDERS, PARTNERS, EMPLOYEES
                  OR REPRESENTATIVES, REGARDLESS OF THE CAUSE OF ACTION.
                </Typography>
                <br />
                <Typography variant='body1'>
                  THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY TO THE
                  FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
                  JURISDICTION. THIS LIMITATION OF LIABILITY ALSO APPLIES WITH
                  RESPECT TO DAMAGES INCURRED BY YOU BY REASON OF ANY PRODUCTS
                  OR SERVICES SOLD OR PROVIDED ON ANY LINKED SITES OR OTHERWISE
                  BY THIRD PARTIES OTHER THAN IOM AND RECEIVED THROUGH THE
                  SERVICES OR ANY LINKED SITES.
                </Typography>
                <br />
                <Typography variant='body1'>
                  CERTAIN FEDERAL AND STATE LAWS DO NOT ALLOW THE EXCLUSION OR
                  LIMITATION OF CERTAIN DAMAGES OR LIMITATIONS ON IMPLIED
                  WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                  ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO
                  YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>DISCLAIMERS</Typography>
                <br />
                <Typography variant='body1'>
                  You agree that your use of the site and services and any
                  content, products, services, or features made in conjunction
                  with or through the services shall be at your sole risk and
                  unless otherwise expressly stated by IOM, are provided "as is”
                  and "as available”. To the fullest extent permitted by law,
                  IOM, its officers, directors, employees, and agents disclaim
                  all warranties, express or implied, in connection with the
                  site and the services and your use thereof, including, but not
                  limited to, implied warranties of merchantability, fitness for
                  a particular purpose, non-infringement of proprietary rights,
                  compliance with united states federal and state securities or
                  Blue Sky laws or regulations, securities exchange or
                  self-regulatory organization's rules or regulations, and
                  equivalent laws or regulations in foreign jurisdictions,
                  correctness, accuracy and reliability.
                </Typography>
                <br />
                <Typography variant='body1'>
                  IOM does not guarantee the accuracy of any User Content or
                  third party content. IOM does not control and is not
                  responsible for what users post on the Site and are not
                  responsible for any offensive, inappropriate, obscene,
                  unlawful or otherwise objectionable content you may encounter
                  on the Site or in connection with any User Content or third
                  party content. We are not responsible for the conduct, whether
                  online or offline, of any user of the Site or Services. We
                  cannot guarantee and do not promise any specific results
                  (relating to investments or otherwise) from use of the Site
                  and/or the Services.
                </Typography>
                <br />
                <Typography variant='body1'>
                  IOM has no special relationship with or fiduciary duty to you
                  and without limiting the foregoing, makes no representation or
                  warranty with respect to the merits and risks of any
                  investments made by using or through the services. As with the
                  purchase of a product or service through any medium or in any
                  environment, you should use your best judgment and exercise
                  caution where appropriate. IOM is not responsible for
                  verifying that any investor or user is an accredited investor
                  beyond reviewing information submitted directly to IOM or its
                  designated agents. IOM does not recommend any investment
                  opportunities specifically to or for any user or endorse their
                  suitability for investment by any specific user.
                </Typography>
                <br />
                <Typography variant='body1'>
                  The Site and the Services may be temporarily unavailable from
                  time to time for maintenance or other reasons. IOM assumes no
                  responsibility for any error, omission, interruption,
                  deletion, defect, delay in operation or transmission,
                  communications line failure, theft or destruction or
                  unauthorized access to, or alteration of, User communications.
                  IOM is not responsible for any problems or technical
                  malfunction of any telephone network or lines, computer online
                  systems, servers or providers, computer equipment, software,
                  failure of email or players on account of technical problems
                  or traffic congestion on the Internet or on the Site or
                  combination thereof, including injury or damage to users or to
                  any other person's computer related to or resulting from
                  participating or downloading materials in connection with the
                  Internet and/or in connection with the Site or the Services.
                  Under no circumstances will IOM be responsible for any loss or
                  damage, including any loss or damage to any User Content or
                  personal injury or death, resulting from anyone's use of the
                  Site or the Services, any User Content or third party content
                  posted on or through the Site or the Services or transmitted
                  to users, or any interactions between users of the Site,
                  whether online or offline.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>INDEMNIFICATION</Typography>
                <br />
                <Typography variant='body1'>
                  To the extent permitted by applicable law, you agree to
                  defend, indemnify and hold harmless IOM , its affiliates,
                  officers, directors, employees, contractors, suppliers,
                  representatives and agents, from and against any and all
                  claims, damages, obligations, losses, liabilities, costs or
                  debts, and expenses (including but not limited to attorney's
                  fees) arising from or relating to: (i) your use or misuse of
                  and access to the Site, Services, or any content thereon; (ii)
                  your violation of any of these Terms of Service, IOM' Privacy
                  Policy, or any Published Information; (iii) infringement by
                  you or any third party using your account of any third party
                  right, including without limitation any copyright, property,
                  or privacy right; or (iv) any claim that your User Content
                  caused damage to a third party.
                </Typography>
                <br />
                <Typography variant='body1'>
                  This defense and indemnification obligation will survive these
                  Terms and your use of the Service. IOM reserves the right to
                  assume the exclusive defense and control of any matter
                  otherwise subject to indemnification by you, in which event
                  you will assist and cooperate with the Company in asserting
                  any available defenses.
                </Typography>
                <br />
                <Typography variant='body1'>
                  If you are a California resident, you waive California Civil
                  Code Section 1542, which says: ”A general release does not
                  extend to claims which the creditor does not know or suspect
                  to exist in his favor at the time of executing the release,
                  which if known by him must have materially affected his
                  settlement with the debtor.” If you are a resident of another
                  jurisdiction, you waive any comparable statute or doctrine. In
                  addition, you hereby release any claims you may have against
                  IOM and any employee, officer, director or affiliate of IOM
                  that are in any way related to your use of the Site or
                  Services, including any referrals you may receive from IOM as
                  a result of your registration. You are solely responsible for
                  your use of the Site or Services, for any content you provide,
                  and for any consequences thereof, including the use of your
                  content by other users and third parties.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>
                  GOVERNING LAW - ARBITRATION
                </Typography>
                <br />
                <Typography variant='body1'>
                  These Terms of Service (and any further rules, policies, or
                  guidelines incorporated by reference) shall be governed by and
                  construed and enforced in accordance with the laws of the
                  state of Wyoming as they apply to agreements entered into and
                  to be performed entirely within Wyoming between Wyoming
                  residents, without giving effect to any principles of
                  conflicts of law, and without application of the Uniform
                  Computer Information Transaction Act or the United Nations
                  Convention of Controls for International Sale of Goods. By
                  your agreement to these Terms and your use of the Services,
                  you agree to submit to personal jurisdiction in Wyoming for
                  all purposes, and you agree to waive, to the maximum extent
                  permitted by law, any right to a trial by jury for any matter.
                  Unless otherwise agreed in writing by you and IOM , any
                  dispute arising out of or relating to these Terms, or any
                  breach thereof, shall be finally resolved by binding
                  arbitration administered by the American Arbitration
                  Association under its Commercial Arbitration Rules, or such
                  arbitration body as required by law, rule or regulation, and
                  final judgment upon the award rendered by the arbitrator may
                  be entered in any court having jurisdiction. The arbitration
                  will be conducted in the English language before a single
                  arbitrator in Illinois. Such arbitration must be commenced
                  within one (1) year after the claim or cause of action arises.
                  Notwithstanding the foregoing, either IOM or you may apply to
                  any court of competent jurisdiction for injunctive relief
                  without breach of this arbitration provision. You acknowledge
                  and agree that you may bring claims against IOM only in an
                  individual capacity and not as a plaintiff or class member in
                  any purported class or representative proceeding.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>OTHER</Typography>
                <br />
                <Typography variant='body1'>
                  These Terms, together with IOM's Privacy Policy, Published
                  Information, and any other legal notices published by IOM on
                  the Site, shall constitute the entire agreement between you
                  and IOM concerning the Services and supersedes all prior or
                  contemporaneous communications, agreements and understandings
                  between IOM and you with respect to the subject matter hereof.
                  To the extent these Terms conflict with the terms of IOM's
                  Privacy Policy or the Published Information, these Terms will
                  control. These Terms shall not be assignable by you, either in
                  whole or in part. IOM reserves the right to assign its rights
                  and obligations under these Terms without restriction. The
                  failure of IOM to enforce any right or provision of these
                  Terms will not constitute a waiver of future enforcement of
                  that right or provision. The waiver of such right or provision
                  may only become effective upon written agreement signed by a
                  duly authorized representative of IOM. The section titles in
                  these Terms and the Privacy Policy are for convenience only
                  and have no legal or contractual effect. If for any reason any
                  provision of these Terms, or a portion thereof, shall be
                  unenforceable, that provision shall be enforced to the maximum
                  extent permissible so as to effect the intent of these Terms,
                  and the remainder of these Terms shall continue in full force
                  and effect. If any provision of the Terms shall be unlawful,
                  void, or for any reason unenforceable, then that provision
                  will be deemed severable from the Terms and will not affect
                  the validity and enforceability of any remaining provisions. A
                  printed version of these Terms shall be admissible in judicial
                  or administrative proceedings.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='body1'>
                  <b>
                    <u>DISCLOSURES, LEGAL AND TAX COUNSEL</u>
                  </b>
                  : InvestOnMainLLC, Invest On Main LLC ("IOM”) and their
                  affiliates do not provide tax, legal or accounting advice.
                  This material has been prepared for informational purposes
                  only, and is not intended to provide, and should not be relied
                  on for, tax, legal or accounting advice. You should consult
                  your own tax, legal and accounting advisors before engaging in
                  any transaction or undertaking. IOM highly encourages
                  individuals and investors to seek the counsel of a qualified
                  attorney as well as seek the counsel of a tax professional or
                  Certified Public Accountant (CPA) to determine if there are
                  any potential tax liabilities or consequences as a result of
                  anything contained herein.{' '}
                  <b>
                    <u>NO GUARANTEE</u>
                  </b>
                  : All users of this website should understand there are{' '}
                  <b>
                    <u>NO GUARANTEES</u>
                  </b>{' '}
                  of any success, outcome or profitability of any transaction or
                  undertaking, expressed or implied by Invest On Main LLC or any
                  of their members, shareholders, officers or affiliates and
                  will NOT be liable for any financial or other losses or
                  damages incurred as a result of any undertaking.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={accepted}
                      onChange={() => {
                        setAccepted(!accepted);
                      }}
                    />
                  }
                  label='I accept the Terms and Conditions above.'
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </Paper> */}
    </Box>
  );
}

export default TermsAndConditions;
