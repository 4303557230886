import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../../providers/actionTypes';
import SponsorActiveInvestmentsAmountField from './SponsorActiveInvestmentsAmountField';
import SponsorRealizedInvestmentsAmountField from './SponsorRealizedInvestmentsAmountField';
import SponsorAverageHoldMonthsField from './SponsorAverageHoldMonthsField';
import SponsorDealHistory from './SponsorDealHistory';
import STATUSES from '../../../shared/statuses';

function SponsorTrackRecord({ admin, sponsor, setValues }) {
  const [revision, setRevision] = useState(0);
  const formErrorDispatch = useFormErrorDispatch();
  const [active_investments_amount, setActiveInvestmentsAmount] = useState(
    sponsor?.active_investments_amount || ''
  );
  const [realized_investments_amount, setRealizedInvestmentsAmount] = useState(
    sponsor?.realized_investments_amount || ''
  );
  const [average_hold_months, setAverageHoldMonths] = useState(
    sponsor?.average_hold_months || ''
  );
  const [average_hold_months_error, setAverageHoldMonthsError] =
    useState(false);

  useEffect(() => {
    if (average_hold_months_error) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [formErrorDispatch, average_hold_months_error]);

  useEffect(() => {
    setValues({
      active_investments_amount,
      realized_investments_amount,
      average_hold_months: parseInt(average_hold_months),
    });
  }, [
    setValues,
    active_investments_amount,
    realized_investments_amount,
    average_hold_months,
  ]);
  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [sponsor]);

  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 1200,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Track Record Summary</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <SponsorActiveInvestmentsAmountField
                  key={`active_investments_${revision}`}
                  value={active_investments_amount}
                  setValue={setActiveInvestmentsAmount}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <SponsorRealizedInvestmentsAmountField
                  key={`realized_investments_${revision}`}
                  value={realized_investments_amount}
                  setValue={setRealizedInvestmentsAmount}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorAverageHoldMonthsField
                  key={`average_hold_months_${revision}`}
                  value={average_hold_months}
                  error={average_hold_months_error}
                  setValue={setAverageHoldMonths}
                  setError={setAverageHoldMonthsError}
                  disabled={!admin && sponsor.status === STATUSES.PENDING}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Deal History</Typography>
                <Typography variant='caption'>
                  * Changes to deal history have immediate effect
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorDealHistory admin={admin} sponsor={sponsor} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SponsorTrackRecord;
