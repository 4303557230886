import React, { useState } from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SponsorTeamMemberBioField({
  value,
  error,
  setValue,
  setError,
}) {
  const [charCount, setCharCount] = useState(value ? value.length : 0);
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue.length <= 2000) {
      setValue(newValue);
      setCharCount(newValue.length);
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          label='Bio'
          id='bio'
          multiline
          rows={10}
          aria-describedby='bio-helper-text'
          onChange={handleChange}
          value={value}
          variant='standard'
        />

        <FormHelperText id='bio-helper-text'>
          Enter a short summary of this team members experience. When this team
          member is leading a deal, potential investors will review this
          summary. ({charCount}/2000)
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
