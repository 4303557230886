import api from '../apis/api';

export const adminGetSponsors = async (status, limit = 10, offset = 0) => {
  const resp = await api.get('/admin/sponsor', {
    params: { status, limit, offset },
  });
  return resp.data;
};

export const adminCountSponsors = async (status) => {
  const resp = await api.get('/admin/sponsor/count', {
    params: { status },
  });
  return resp?.data?.total;
};

export const adminGetSponsor = async (sponsor_id) => {
  const resp = await api.get(`/admin/sponsor/${sponsor_id}`);
  return resp.data;
};

export const adminCreateSponsor = async (values) => {
  const resp = await api.post(`/admin/sponsor/`, values);
  return resp.data;
};

export const adminUpdateSponsor = async (sponsor_id, values) => {
  const resp = await api.put(`/admin/sponsor/${sponsor_id}`, values);
  return resp.data;
};

export const adminDeleteSponsor = async (sponsor_id) => {
  const resp = await api.delete(`/admin/sponsor/${sponsor_id}`);
  return resp.data;
};

export const adminSetSponsorDesignation = async (sponsor_id, designation) => {
  const resp = await api.patch(`/admin/sponsor/${sponsor_id}/designation`, {
    designation,
  });
  return resp.data;
};

export const adminSetSponsorOwner = async (sponsor_id, owner) => {
  const resp = await api.patch(`/admin/sponsor/${sponsor_id}/owner`, {
    owner,
  });
  return resp.data;
};

export const adminSetSponsorStatus = async (sponsor_id, status) => {
  const resp = await api.patch(`/admin/sponsor/${sponsor_id}/status`, {
    status,
  });
  return resp.data;
};
