import React, { useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import UploadButton from '../../../../../shared/UploadButton';
import { deleteAsset, putAsset } from '../../../../../../actions/assets';
import getFile from '../../../../../../util/getFile';

export default function SponsorTeamMemberPictureField({
  sponsor_id,
  value,
  setValue,
}) {
  const [image, setImage] = useState();

  const handleChange = async (e) => {
    const image = await getFile(e.target.files[0]);
    const suffix = image.name.split('.')[1];
    const entity_id = createId();
    const file_name = suffix ? `${entity_id}.${suffix}` : entity_id;
    const path = await putAsset(
      `sponsor/${sponsor_id}/${file_name}`,
      image.type,
      image.contents
    );

    const old_path = value?.match(/^https:\/\/.*\/static\/(?<path>.*)$/)?.groups
      ?.path;
    if (old_path) {
      await deleteAsset(old_path);
    }

    setValue(`${process.env.REACT_APP_API_URL}/v1/static/${path}`);
    setImage(image);
  };

  if (value) {
    return (
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Typography variant='body1'>Picture</Typography>
        </Grid>
        <Grid xs={12} md={6} lg={6}>
          <img
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '200px',
              maxHeight: '200px',
            }}
            alt='uploaded'
            src={value}
          />
        </Grid>
        <Grid xs={12} md={6} lg={6} display='flex' alignItems='center'>
          <UploadButton name='Upload Again' onChange={handleChange} />
        </Grid>
      </Grid>
    );
  }
  if (image) {
    return (
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Typography variant='body1'>Picture</Typography>
        </Grid>
        <Grid xs={12} md={6} lg={6}>
          <img
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '200px',
              maxHeight: '200px',
            }}
            alt='uploaded'
            src={image.base64contents}
          />
        </Grid>
        <Grid xs={12} md={6} lg={6} display='flex' alignItems='center'>
          <UploadButton name='Upload Again' onChange={handleChange} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant='body1'>Picture</Typography>
      <Paper
        variant='outlined'
        sx={{
          width: '200px',
          height: '200px',
          alignContent: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UploadButton name='Upload File' onChange={handleChange} />
      </Paper>
    </>
  );
}
