import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography, Divider, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';

import { getUser } from '../../../actions/user';
import { useUserDispatch } from '../../../providers/UserContext';
import { USER_FETCH } from '../../../providers/actionTypes';
import {
  deleteSponsor,
  getSponsor,
  submitSponsor,
  withdrawSponsor,
} from '../../../actions/sponsor';
import { useUser } from '../../../providers/UserContext';
import LoadingMask from '../../shared/LoadingMask';
import { getSponsorDeals } from '../../../actions/sponsorDeal';
import STATUSES from '../../shared/statuses';

function ManageSponsor() {
  const user = useUser();
  const dispatch = useUserDispatch();
  const navigate = useNavigate();
  const [sponsor, setSponsor] = useState(null);
  const [deals, setDeals] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (sponsor) {
      if (sponsor.status === STATUSES.ACTIVE && !deals) {
        getSponsorDeals(sponsor.entity_id).then((payload) => {
          setDeals(payload);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [sponsor, deals]);

  useEffect(() => {
    if (user.primary_sponsor && sponsor === null) {
      getSponsor(user.primary_sponsor)
        .then((payload) => {
          setSponsor(payload);
        })
        .catch((error) => {
          navigate('/404');
        });
    }
  }, [sponsor, user, navigate]);

  const handleDelete = async () => {
    setDeleteLoading(true);
    await deleteSponsor(user.primary_sponsor);
    const updated_user = await getUser();
    dispatch({ type: USER_FETCH, payload: updated_user });

    setDeleteLoading(false);
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const updated_sponsor = await submitSponsor(user.primary_sponsor);
    setSponsor(updated_sponsor);
    setSubmitLoading(false);
  };

  const handleWithdraw = async () => {
    setSubmitLoading(true);
    const updated_sponsor = await withdrawSponsor(user.primary_sponsor);
    setSponsor(updated_sponsor);
    setSubmitLoading(false);
  };

  if (loading) {
    return <LoadingMask />;
  }
  return (
    <Grid container spacing={4}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Manage Sponsor</Typography>
        <Divider />
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <Typography variant='body1'>
          {sponsor.name} - Status: <b>{sponsor.status}</b>
        </Typography>
        <br />
        <Stack direction='row' spacing={2}>
          <Button
            component={Link}
            to='/sponsor/edit'
            variant='outlined'
            color={sponsor.status === STATUSES.ACTIVE ? 'primary' : 'inherit'}
            disabled={sponsor.status === STATUSES.PENDING}
          >
            Manage
          </Button>
          <Button
            component={Link}
            to={`/sponsor/${user.primary_sponsor}`}
            variant='outlined'
            color='inherit'
          >
            View
          </Button>
          {sponsor.status === STATUSES.DRAFT ? (
            <LoadingButton
              variant='contained'
              color='primary'
              loading={submitLoading}
              onClick={handleSubmit}
              disabled={deleteLoading}
            >
              {'Submit'}
            </LoadingButton>
          ) : undefined}
          {sponsor.status === STATUSES.PENDING ? (
            <LoadingButton
              variant='contained'
              color='primary'
              loading={submitLoading}
              onClick={handleWithdraw}
              disabled={deleteLoading}
            >
              {'Withdraw'}
            </LoadingButton>
          ) : undefined}
        </Stack>
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Delete Sponsor</Typography>
        <Divider />
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        {deals?.length ? (
          <Typography variant='body1'>
            All active deals must be closed before sponsor can be deleted.
          </Typography>
        ) : (
          <Typography variant='body1'>
            Deleting your sponsor will permanently clear all data associated
            with the sponsor. This action is not recoverable.
          </Typography>
        )}
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <LoadingButton
          variant='contained'
          color='error'
          loading={deleteLoading}
          onClick={handleDelete}
          disabled={deals?.length || submitLoading}
        >
          {'Delete'}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default ManageSponsor;
