import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function DealOtherCostsField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl fullWidth>
        <NumericFormat
          value={value}
          prefix='$'
          onValueChange={handleChange}
          customInput={TextField}
          aria-describedby='other-costs-helper-text'
          label='Other Deal Costs'
          id='other_costs'
          error={Boolean(error)}
          variant='standard'
          thousandSeparator=','
          sx={{ input: { textAlign: 'right' } }}
        />

        {error ? (
          <FormHelperText id='other-costs-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='other-costs-helper-text'>
            Enter the additional costs required to purchase the assets (closing
            costs, etc.) not included in the purchase price.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
