import React, { useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import UploadButton from '../../../shared/UploadButton';
import { putAsset } from '../../../../actions/assets';
import getFile from '../../../../util/getFile';

export default function DealDocumentUploadField({
  deal_id,
  addDocument,
  disabled,
}) {
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    setLoading(true);
    const document = await getFile(e.target.files[0]);
    const { suffix } = document.name.match(
      /(?<doc_name>.*)\.(?<suffix>[^.\\/:*?"<>|\r\n]+)$/
    )?.groups;

    const entity_id = createId();
    const file_name = suffix ? `${entity_id}.${suffix}` : entity_id;
    const path = await putAsset(
      `deal/${deal_id}/${file_name}`,
      document.type,
      document.contents
    );

    addDocument({
      entity_id,
      name: document.name,
      url: `${process.env.REACT_APP_API_URL}/v1/static/${path}`,
    });
    setLoading(false);
  };

  return (
    <>
      <UploadButton
        name='Add Document'
        onChange={handleChange}
        disabled={disabled || loading}
      />
    </>
  );
}
