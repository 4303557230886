import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Box } from '@mui/material';

import LoadingMask from '../../shared/LoadingMask';
import { useUser } from '../../../providers/UserContext';

import SponsorDesktop from './SponsorDesktop';
import SponsorMobile from './SponsorMobile';
import { getSponsor } from '../../../actions/sponsor';

function Sponsor() {
  const user = useUser();
  const navigate = useNavigate();
  const { sponsor_id } = useParams();
  const [sponsor, setSponsor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user.entity_id) {
      if (sponsor_id && sponsor === null) {
        getSponsor(sponsor_id)
          .then((payload) => {
            setSponsor(payload);
            setLoading(false);
          })
          .catch((error) => {
            navigate('/404');
          });
      } else {
        setLoading(false);
      }
    }
  }, [user, sponsor_id, sponsor, navigate]);

  const fetchSponsor = useCallback(async () => {
    if (user.entity_id && sponsor_id) {
      setLoading(true);
      try {
        const payload = await getSponsor(sponsor_id);
        setSponsor(payload);
        setLoading(false);
      } catch (error) {
        navigate('/404');
      }
    }
  }, [user, sponsor_id, navigate]);

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(${sponsor.logo_rectangle});`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '300px',
        }}
      >
        <Container sx={{ padding: '15px', zIndex: 1 }}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
            <SponsorDesktop sponsor={sponsor} fetchSponsor={fetchSponsor} />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
            <SponsorMobile sponsor={sponsor} fetchSponsor={fetchSponsor} />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Sponsor;
