import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SponsorActiveInvestmentsAmountField({
  value,
  error,
  setValue,
  setError,
  ...rest
}) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl fullWidth>
        <NumericFormat
          {...rest}
          value={value}
          prefix='$'
          onValueChange={handleChange}
          customInput={TextField}
          aria-describedby='active-investments-amount-helper-text'
          label='Total Active Investments'
          id='active_investments_amount'
          error={Boolean(error)}
          variant='standard'
          thousandSeparator=','
        />

        {error ? (
          <FormHelperText id='active-investments-amount-helper-text'>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id='active-investments-amount-helper-text'>
            Enter the total value of active investments this sponsor is
            managing.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
