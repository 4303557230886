import React from 'react';

import { Typography, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useMetadata } from '../../../providers/MetadataContext';

function SponsorExpertise({ sponsor }) {
  const metadata = useMetadata();

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h4'>Expertise</Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='subtitle2'>Asset Types</Typography>
        {sponsor.asset_sub_types.map((entity_id) => {
          const entity = (metadata?.sponsor?.lists?.asset_types || []).find(
            (option) => option.entity_id === entity_id
          );
          if (entity) {
            return (
              <Chip
                sx={{ margin: '5px' }}
                label={`${entity?.group}:${entity?.name}`}
              />
            );
          } else {
            return <></>;
          }
        })}
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Typography variant='subtitle2'>Investment Types</Typography>
        {sponsor.investment_types.map((entity_id) => {
          const entity = (
            metadata?.sponsor?.lists?.investment_types || []
          ).find((option) => option.entity_id === entity_id);
          if (entity) {
            return <Chip sx={{ margin: '5px' }} label={entity?.name} />;
          } else {
            return <></>;
          }
        })}
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Typography variant='subtitle2'>Investment Benefits</Typography>
        {sponsor.investment_benefits.map((entity_id) => {
          const entity = (
            metadata?.sponsor?.lists?.investment_benefits || []
          ).find((option) => option.entity_id === entity_id);
          if (entity) {
            return <Chip sx={{ margin: '5px' }} label={entity?.name} />;
          } else {
            return <></>;
          }
        })}
      </Grid>

      <Grid xs={12} md={6} lg={4}>
        <Typography variant='subtitle2'>Investment Strategies</Typography>
        {sponsor.investment_strategies.map((entity_id) => {
          const entity = (
            metadata?.sponsor?.lists?.investment_strategies || []
          ).find((option) => option.entity_id === entity_id);
          if (entity) {
            return <Chip sx={{ margin: '5px' }} label={entity?.name} />;
          } else {
            return <></>;
          }
        })}
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Typography variant='subtitle2'>Primary Markets</Typography>
        {sponsor.primary_markets.map((entity_id) => {
          const entity = (metadata?.sponsor?.lists?.primary_markets || []).find(
            (option) => option.entity_id === entity_id
          );
          if (entity) {
            return <Chip sx={{ margin: '5px' }} label={entity?.name} />;
          } else {
            return <></>;
          }
        })}
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Typography variant='subtitle2'>Experience Rating</Typography>
        {
          (metadata?.sponsor?.lists?.designations || []).find(
            (option) => option.entity_id === sponsor.designation
          )?.name
        }
      </Grid>
    </Grid>
  );
}

export default SponsorExpertise;
