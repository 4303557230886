import React, { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../../providers/actionTypes';
import { getSponsorTeamMembers } from '../../../../actions/sponsorTeamMember';
import LoadingMask from '../../../shared/LoadingMask';
import DealTeamMemberCard from './DealTeamMemberCard';

function DealManagement({ deal, setValues }) {
  const formErrorDispatch = useFormErrorDispatch();
  const [loading, setLoading] = useState(true);
  const [deal_team_members, setDealTeamMembers] = useState(
    deal?.team_members.map((team_member) => team_member.entity_id) || []
  );
  const [sponsor_team_members, setSponsorTeamMembers] = useState(undefined);
  console.log(deal);
  useEffect(() => {
    if (deal?.sponsor?.entity_id && !sponsor_team_members) {
      getSponsorTeamMembers(deal?.sponsor?.entity_id)
        .then((payload) => {
          setSponsorTeamMembers(payload);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [sponsor_team_members, deal]);

  useEffect(() => {
    if (false) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [formErrorDispatch]);

  const toggleTeamMember = (entity_id) => {
    const new_deal_team_members = [...deal_team_members];
    if (new_deal_team_members.includes(entity_id)) {
      new_deal_team_members.splice(
        new_deal_team_members.findIndex(
          (team_member_id) => team_member_id === entity_id
        )
      );
    } else {
      new_deal_team_members.push(entity_id);
    }
    setDealTeamMembers(new_deal_team_members);
    const new_team_members = sponsor_team_members.filter((team_member) =>
      new_deal_team_members.includes(team_member.entity_id)
    );
    setValues({
      team_members: new_team_members,
    });
  };

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 800,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Deal Team</Typography>
                <Typography variant='caption'>
                  Select the sponsor team members that are managing this deal
                </Typography>
              </Grid>

              <Grid xs={12} md={12} lg={12} container spacing={2}>
                {sponsor_team_members?.map((team_member) => {
                  return (
                    <Grid xs={12} md={6} lg={4}>
                      <DealTeamMemberCard
                        team_member={team_member}
                        selected={deal_team_members.includes(
                          team_member.entity_id
                        )}
                        onSelect={toggleTeamMember}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DealManagement;
