import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Toolbar,
  Stack,
  Box,
  Tooltip,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  createSponsorTeamMember,
  updateSponsorTeamMember,
} from '../../../../../../actions/sponsorTeamMember';
import SponsorTeamMemberFirstNameField from './SponsorTeamMemberFirstNameField';
import SponsorTeamMemberLastNameField from './SponsorTeamMemberLastNameField';
import SponsorTeamMemberTitleField from './SponsorTeamMemberTitleField';
import SponsorTeamMemberLeadershipField from './SponsorTeamMemberLeadershipField';
import SponsorTeamMemberLinkedinField from './SponsorTeamMemberLinkedinField';
import SponsorTeamMemberPictureField from './SponsorTeamMemberPictureField';
import SponsorTeamMemberBioField from './SponsorTeamMemberBioField';
import {
  adminCreateSponsorTeamMember,
  adminUpdateSponsorTeamMember,
} from '../../../../../../actions/adminSponsorTeamMember';

export default function SponsorTeamMemberModal({
  open,
  admin,
  handleClose,
  sponsor_id,
  team_member,
}) {
  const [loading, setLoading] = useState(false);
  const [first_name, setFirstName] = useState(team_member?.first_name || '');
  const [first_name_error, setFirstNameError] = useState(false);
  const [last_name, setLastName] = useState(team_member?.last_name || '');
  const [last_name_error, setLastNameError] = useState(false);
  const [title, setTitle] = useState(team_member?.title || '');
  const [title_error, setTitleError] = useState(false);
  const [leadership, setLeadership] = useState(team_member?.leadership || '');
  const [linkedin, setLinkedin] = useState(team_member?.linkedin || '');
  const [linkedin_error, setLinkedinError] = useState(false);
  const [picture, setPicture] = useState(team_member?.picture || '');
  const [bio, setBio] = useState(team_member?.bio || '');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = Object.fromEntries(
        Object.entries({
          ...(team_member || {}),
          first_name,
          last_name,
          title,
          linkedin,
          leadership,
          picture,
          bio,
        }).filter(([k, v]) => v !== undefined && v !== '')
      );
      let result;
      if (team_member?.entity_id) {
        if (admin) {
          result = await adminUpdateSponsorTeamMember(
            sponsor_id,
            team_member.entity_id,
            payload
          );
        } else {
          result = await updateSponsorTeamMember(
            sponsor_id,
            team_member.entity_id,
            payload
          );
        }
      } else {
        if (admin) {
          result = await adminCreateSponsorTeamMember(sponsor_id, payload);
        } else {
          result = await createSponsorTeamMember(sponsor_id, payload);
        }
      }
      setLoading(false);
      handleClose(result);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs>
            {team_member?.entity_id
              ? `Edit ${team_member.first_name} ${team_member.last_name}`
              : 'New Team Member'}
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            maxWidth: 400,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorTeamMemberFirstNameField
                value={first_name}
                error={first_name_error}
                setValue={setFirstName}
                setError={setFirstNameError}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorTeamMemberLastNameField
                value={last_name}
                error={last_name_error}
                setValue={setLastName}
                setError={setLastNameError}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorTeamMemberTitleField
                value={title}
                error={title_error}
                setValue={setTitle}
                setError={setTitleError}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorTeamMemberLeadershipField
                value={leadership}
                setValue={setLeadership}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorTeamMemberLinkedinField
                value={linkedin}
                error={linkedin_error}
                setValue={setLinkedin}
                setError={setLinkedinError}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorTeamMemberPictureField
                value={picture}
                setValue={setPicture}
                sponsor_id={sponsor_id}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorTeamMemberBioField value={bio} setValue={setBio} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Toolbar>
          <Stack spacing={2} direction='row'>
            <Tooltip
              title={
                Boolean(
                  first_name === '' ||
                    first_name_error ||
                    last_name === '' ||
                    last_name_error ||
                    title === '' ||
                    title_error ||
                    linkedin_error
                )
                  ? 'Fix form errors to continue.'
                  : ''
              }
            >
              <span>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={Boolean(
                    first_name === '' ||
                      first_name_error ||
                      last_name === '' ||
                      last_name_error ||
                      title === '' ||
                      title_error ||
                      linkedin_error
                  )}
                >
                  {team_member?.entity_id ? `Update` : 'Add'}
                </LoadingButton>
              </span>
            </Tooltip>

            <Button
              variant='outlined'
              onClick={() => handleClose()}
              disabled={loading}
            >
              Cancel
            </Button>
          </Stack>
        </Toolbar>
      </DialogActions>
    </Dialog>
  );
}
