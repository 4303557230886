import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  styled,
  useTheme,
  useScrollTrigger,
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Badge,
  Button,
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useStytch, useStytchUser } from '@stytch/react';

import { useUser, useUserDispatch } from '../../providers/UserContext';
import {
  METADATA_DEAL_FETCH,
  METADATA_SPONSOR_FETCH,
  METADATA_USER_FETCH,
  USER_FETCH,
  USER_LOGOUT,
} from '../../providers/actionTypes';
import { createUser, getUser } from '../../actions/user';
import { RefreshSession } from '../RefreshSession';
import {
  getDealMetadata,
  getSponsorMetadata,
  getUserMetadata,
} from '../../actions/metadata';
import {
  useMetadata,
  useMetadataDispatch,
} from '../../providers/MetadataContext';

const drawerWidth = 240;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 15,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 1,
  });
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({ open, toggleDrawer, colorInvert = false, window }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [creatingUser, setSetCreatingUser] = useState(false);
  const stytch = useStytch();
  const { user: stytchUser } = useStytchUser();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const user = useUser();
  const userDispatch = useUserDispatch();
  const metadata = useMetadata();
  const metadataDispatch = useMetadataDispatch();
  const theme = useTheme();
  const { mode } = theme.palette;
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  // const [activeLink, setActiveLink] = useState('');
  // useEffect(() => {
  //   setActiveLink(window && window.location ? window.location.pathname : '');
  // }, []);

  useEffect(() => {
    if (stytchUser && !user.entity_id && !creatingUser) {
      getUser()
        .then((user) => userDispatch({ type: USER_FETCH, payload: user }))
        .catch(async (error) => {
          if (!creatingUser) {
            setSetCreatingUser(true);
            try {
              const user = await createUser();
              userDispatch({ type: USER_FETCH, payload: user });
            } catch (error) {
              console.log(error);
              // still can't figure out how to stop the double post
            }
            navigate('/account/new');
            setSetCreatingUser(false);
          }
        });
    }
  }, [stytchUser, user, creatingUser, userDispatch, navigate]);

  const logout = async () => {
    stytch.session.revoke().then((resp) => {
      userDispatch({ type: USER_LOGOUT });
      navigate('/login');
    });
  };

  useEffect(() => {
    if (!metadata.user) {
      getUserMetadata()
        .then((payload) =>
          metadataDispatch({ type: METADATA_USER_FETCH, payload })
        )
        .catch((error) => navigate('/login'));
    } else if (!metadata.sponsor) {
      getSponsorMetadata()
        .then((payload) =>
          metadataDispatch({ type: METADATA_SPONSOR_FETCH, payload })
        )
        .catch((error) => navigate('/login'));
    } else if (!metadata.deal) {
      getDealMetadata()
        .then((payload) =>
          metadataDispatch({ type: METADATA_DEAL_FETCH, payload })
        )
        .catch((error) => navigate('/login'));
    }
  }, [user, metadata, metadataDispatch, navigate]);

  const menuId = 'primary-navbar-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={Link} to='/account'>
        <IconButton size='large' color='inherit'>
          <PersonOutlineOutlinedIcon />
        </IconButton>
        <p>Account</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout();
          handleMenuClose();
        }}
      >
        <IconButton size='large' color='inherit'>
          <LogoutOutlinedIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-navbar-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size='large' color='inherit'>
          <Badge color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem component={Link} to='https://investonmain.com/resources/'>
        <IconButton size='large' color='inherit'>
          <HelpOutlineOutlinedIcon />
        </IconButton>
        <p>Documentation</p>
      </MenuItem>
      <MenuItem component={Link} to='/account'>
        <IconButton size='large' color='inherit'>
          <PersonOutlineOutlinedIcon />
        </IconButton>
        <p>Account</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout();
          handleMobileMenuClose();
        }}
      >
        <IconButton size='large' color='inherit'>
          <LogoutOutlinedIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <ElevationScroll window={window}>
        <StyledAppBar
          position={'sticky'}
          sx={{
            top: 0,
            display: 'flex',
          }}
          open={open}
        >
          <Toolbar sx={{ backgroundColor: theme.palette.background.paper }}>
            <IconButton
              size='large'
              edge='start'
              color='primary'
              onClick={toggleDrawer}
              aria-label='menu'
              sx={{
                mr: 1,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon fontSize='large' />
            </IconButton>
            <Box
              component='a'
              href='/'
              title='IOM.ai'
              width={{ xs: 100, md: 120 }}
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={1}
              >
                <Box
                  component={'img'}
                  src={
                    mode === 'light' && !colorInvert ? '/logo.png' : '/logo.png'
                  }
                  height={1}
                  width={1}
                />
              </Box>
            </Box>
            <Box
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Box
                component='a'
                href='/'
                title='IOM.ai'
                width={{ xs: 100, md: 120 }}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  width={1}
                >
                  <Box
                    component={'img'}
                    src={
                      mode === 'light' && !colorInvert
                        ? '/logo.png'
                        : '/logo.png'
                    }
                    height={1}
                    width={1}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }} />
            {user.entity_id ? (
              <>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {/* <IconButton size='large' color='primary'>
                    <Badge color='error'>
                      <NotificationsIcon />
                    </Badge>
                  </IconButton> */}
                  <IconButton
                    size='large'
                    color='primary'
                    href='https://investonmain.com/resources/'
                    target='_blank'
                  >
                    <Badge color='error'>
                      <HelpOutlineOutlinedIcon fontSize='large' />
                    </Badge>
                  </IconButton>
                  <IconButton
                    size='large'
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={menuId}
                    aria-haspopup='true'
                    onClick={handleProfileMenuOpen}
                    // color='primary'
                  >
                    <Avatar
                      // sx={{
                      //   bgcolor: theme.palette.primary.main,
                      // }}
                      alt={user?.first_name}
                      src={user?.picture || '/bogus.jpg'}
                    />
                  </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size='large'
                    aria-label='show more'
                    aria-controls={mobileMenuId}
                    aria-haspopup='true'
                    onClick={handleMobileMenuOpen}
                    color='primary'
                  >
                    <MoreIcon fontSize='large' />
                  </IconButton>
                </Box>
              </>
            ) : (
              <Button
                component={Link}
                to='/login'
                variant='contained'
                color='primary'
                size='large'
              >
                Login
              </Button>
            )}
          </Toolbar>
        </StyledAppBar>
      </ElevationScroll>
      {renderMobileMenu}
      {renderMenu}
      {stytchUser ? <RefreshSession /> : undefined}
    </>
  );
}

export default Header;
