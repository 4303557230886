import React from 'react';
import Markdown from 'react-markdown';

import {
  Typography, //Button,
  Chip,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DealImageList from './DealImageList';
import DealNarrative from './DealNarrative';
import DealFinancialMetrics from './DealFinancialMetrics';
import DealCapitalStack from './DealCapitalStack';
import { useMetadata } from '../../../providers/MetadataContext';
import DealCategories from './DealCategories';
import DealDocumentList from './DealDocumentList';

function DealDesktop({ deal }) {
  const metadata = useMetadata();
  const asset_sub_type = (metadata?.deal?.lists?.asset_types || []).find(
    (option) => option.entity_id === deal?.asset_sub_type
  );

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h4'>{deal.name}</Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Chip label={`${asset_sub_type?.group}:${asset_sub_type?.name}`} />
        <Chip
          label={
            (metadata?.deal?.lists?.investment_strategies || []).find(
              (option) => option.entity_id === deal?.investment_strategy
            )?.name
          }
        />
      </Grid>
      <Grid
        container
        xs={8}
        md={8}
        lg={8}
        spacing={2}
        sx={{ paddingRight: '32px', display: 'block' }}
      >
        <Grid xs={12} md={12} lg={12}>
          <Typography variant='body2'>
            <Markdown>{deal.description}</Markdown>
          </Typography>
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <DealImageList images={deal.images} />
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <DealNarrative deal={deal} />
        </Grid>
      </Grid>
      <Grid
        container
        xs={4}
        md={4}
        lg={4}
        sx={{ paddingLeft: '32px', display: 'block' }}
      >
        {/* <Grid xs={12} md={12} lg={12}>
          <Button variant='contained' sx={{ width: '100%' }}>
            Invest
          </Button>
        </Grid> */}
        <Grid xs={12} md={12} lg={12}>
          <DealFinancialMetrics deal={deal} />
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <DealCapitalStack deal={deal} />
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <DealCategories deal={deal} />
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <DealDocumentList documents={deal.documents} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DealDesktop;
