const getFile = async (browserFile) => {
  const [file, file_base64] = await Promise.all([
    new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsArrayBuffer(browserFile);
    }),
    new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsDataURL(browserFile);
    }),
  ]);
  return {
    name: browserFile.name,
    type: browserFile.type,
    contents: new Uint8Array(file),
    base64contents: file_base64,
  };
};

export default getFile;
