import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SponsorRealizedInvestmentsAmountField({
  value,
  error,
  setValue,
  setError,
  ...rest
}) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl fullWidth>
        <NumericFormat
          {...rest}
          value={value}
          prefix='$'
          onValueChange={handleChange}
          customInput={TextField}
          aria-describedby='realized-investments-amount-helper-text'
          label='Total Realized Investments'
          id='realized_investments_amount'
          error={Boolean(error)}
          variant='standard'
          thousandSeparator=','
        />

        {error ? (
          <FormHelperText id='realized-investments-amount-helper-text'>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id='realized-investments-amount-helper-text'>
            Enter the total proceeds from realized investments this sponsor
            managed.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
