import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  // IconButton,
  Button,
  Chip,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Box,
} from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
import round from '../../../util/round';
import { Link } from 'react-router-dom';
import { useUser } from '../../../providers/UserContext';

function DealCard({ deal }) {
  const user = useUser();

  let view_error;
  if (!user?.entity_id) {
    view_error = 'Login to view deal details.';
  } else if (user?.entity_id && deal.accredited_only && !user?.accredited) {
    view_error = 'Deal details only available to accredited investors.';
  }
  return (
    <Card sx={{ height: '100%', borderRadius: '10px' }}>
      <Box display='flex' flexDirection='column' height='100%'>
        <CardMedia
          component='img'
          height={'150px'}
          width={'100%'}
          sx={{ objectFit: 'contain' }}
          image={deal.image}
          alt={`${deal.name} image `}
        />
        <CardContent>
          <Typography variant='h6'>{deal.name}</Typography>
          <Stack direction='row'>
            <Chip label={deal.asset_sub_type} />
            <Chip label={deal.investment_strategy} />
          </Stack>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Investment</TableCell>
                <TableCell align='right'>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(deal.minimum_investment)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell align='right'>
                  {deal.city}, {deal.state} {deal.country}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Strategy</TableCell>
                <TableCell align='right'>
                  {deal.investment_benefits.join(', ')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>IRR</TableCell>
                <TableCell align='right'>
                  {`${round(deal.irr_min * 100, 2)} - ${round(
                    deal.irr_max * 100,
                    2
                  )}%`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{ marginTop: 'auto', justifyContent: 'flex-end' }}
        >
          <Tooltip title={view_error || ''}>
            <span>
              <Button
                sx={{ fontSize: 'medium' }}
                disabled={Boolean(view_error)}
                component={Link}
                to={`/deal/${deal.entity_id}`}
              >
                View
              </Button>
            </span>
          </Tooltip>
          {/* <IconButton aria-label='add to favorites'>
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label='share'>
          <ShareIcon />
        </IconButton> */}
        </CardActions>
      </Box>
    </Card>
  );
}

export default DealCard;
