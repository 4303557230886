import React, { useState } from 'react';

import { Container, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// import { useStytchUser } from '@stytch/react';
// import { useUser } from '../../providers/UserContext';

import DealList from './DealList';
import { useMetadata } from '../../../providers/MetadataContext';
import LoadingMask from '../../shared/LoadingMask';
import DealSearchFilters from './DealSearchFilters';

function DealSearch() {
  const [filters, setFilters] = useState({});

  const metadata = useMetadata();

  if (!metadata?.deal) {
    return <LoadingMask />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={2}>
          <Grid lg={6} md={6} xs={12}>
            <Typography variant='h4' gutterBottom>
              Find Deals
            </Typography>
          </Grid>
          <Grid lg={12} md={12} xs={12}>
            <DealSearchFilters values={filters} setValues={setFilters} />
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <DealList filters={filters} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default DealSearch;
