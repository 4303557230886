import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function AccountCityField({ value, error, setValue, setError }) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    // if (newValue === '') {
    //   setError('City cannot be blank');
    // } else {
    //   setError(false);
    // }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          label='City'
          id='city'
          aria-describedby='city-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          variant='standard'
        />
        {error ? (
          <FormHelperText id='city-helper-text'>{error}</FormHelperText>
        ) : undefined}
      </FormControl>
    </Box>
  );
}
