import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useDrop } from 'react-dnd';
import Image from './Image';

export default function DealImageListEditor({ value, setValue }) {
  const findImage = useCallback(
    (id) => {
      const image = value.filter((image) => `${image.entity_id}` === id)[0];
      return {
        image,
        index: value.indexOf(image),
      };
    },
    [value]
  );
  const removeImage = useCallback(
    (id) => {
      setValue((prevImages) => {
        const new_images = [...prevImages];

        new_images.splice(
          prevImages.findIndex((image) => image.entity_id === id),
          1
        );
        return new_images;
      });
    },
    [setValue]
  );
  const moveImage = useCallback(
    (id, atIndex) => {
      const { index } = findImage(id);
      const new_images = [...value];
      const swap = new_images[index];
      new_images[index] = new_images[atIndex];
      new_images[atIndex] = swap;

      setValue(new_images);
    },
    [findImage, value, setValue]
  );
  const [, drop] = useDrop(() => ({ accept: 'image' }));

  return (
    <Box ref={drop}>
      <Grid container spacing={2}>
        {value.map((image) => (
          <Image
            key={image.entity_id}
            id={`${image.entity_id}`}
            url={image.url}
            moveImage={moveImage}
            findImage={findImage}
            removeImage={removeImage}
          />
        ))}
      </Grid>
    </Box>
  );
}
