import React, { useState } from 'react';
import MDEditor, { commands } from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

import { FormControl, Box, FormHelperText, Typography } from '@mui/material';

export default function DealDescriptionField({
  value,
  error,
  setValue,
  setError,
}) {
  const MAX_LENGTH = 1000;
  const [charCount, setCharCount] = useState(value ? value.length : 0);
  const handleChange = (text) => {
    if (text.length <= MAX_LENGTH) {
      setValue(text);
      setCharCount(text.length);
    }
  };

  return (
    <Box>
      <FormControl
        fullWidth
        required
        aria-describedby='description-helper-text'
      >
        <Typography variant='caption' color={'rgba(0, 0, 0, 0.6)'}>
          Description
        </Typography>

        <MDEditor
          commands={[
            commands.title3,
            commands.title4,
            commands.bold,
            commands.italic,
            commands.strikethrough,
            commands.link,
            commands.unorderedListCommand,
            commands.orderedListCommand,
            commands.checkedListCommand,
          ]}
          preview='edit'
          data-color-mode='light'
          value={value}
          onChange={handleChange}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />

        <FormHelperText id='description-helper-text'>
          Enter a description of the deal. This will be the first thing
          investors read about it when they are evaluating the deal. (
          {charCount}/{MAX_LENGTH})
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
