import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function ClosedDealTotalCapitalField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl required fullWidth>
        <NumericFormat
          value={value}
          prefix='$'
          onValueChange={handleChange}
          customInput={TextField}
          aria-describedby='total-capital-helper-text'
          label='Total Capital'
          id='total_capital'
          error={Boolean(error) || value === ''}
          variant='standard'
          thousandSeparator=','
          required
        />

        {error ? (
          <FormHelperText id='total-capital-helper-text'>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id='total-capital-helper-text'>
            Enter the total capital of the purchase (including all debt and
            equity).
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
