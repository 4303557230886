import React from 'react';

import { FormControlLabel, Switch, Box, FormHelperText } from '@mui/material';

export default function DealIRAField({ value, error, setValue, setError }) {
  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
        }
        label='IRA Eligible'
      />
      <FormHelperText>
        Investors can hold this investment in their self-directed IRA.
      </FormHelperText>
    </Box>
  );
}
