import api from '../apis/api';

export const getSponsorInvestorSponsorInquiries = async (sponsor_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}/inquiry`);
  return resp.data;
};

export const getInvestorSponsorInquiries = async () => {
  const resp = await api.get(`/user/inquiry`);
  return resp.data;
};

export const createInvestorSponsorInquiry = async (sponsor_id) => {
  const resp = await api.post(`/sponsor/${sponsor_id}/inquiry`, {});
  return resp.data;
};

export const updateInvestorSponsorInquiry = async (
  sponsor_id,
  inquiry_id,
  values
) => {
  const resp = await api.put(
    `/sponsor/${sponsor_id}/inquiry/${inquiry_id}`,
    values
  );
  return resp.data;
};

export const deleteInvestorSponsorInquiry = async (inquiry_id) => {
  const resp = await api.delete(`/user/inquiry/${inquiry_id}`);
  return resp.data;
};
