import React, { useEffect, useState } from 'react';

import { Alert, Stack, Typography, Box, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import useInfiniteScroll from '../../../util/useInfiniteScroll';
import { getActiveDeals } from '../../../actions/deal';
import { useGridColumnCount } from '../../../util/useGridColumnCount';
import LoadingMask from '../../shared/LoadingMask';
import DealCard from './DealCard';

function DealCardWrapper({ deal, index }) {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  const columnCount = useGridColumnCount();
  return (
    <Grid
      ref={ref}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.8, delay: (index % columnCount) * 0.1 }}
      exit={{ opacity: 0 }}
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      key={deal.id}
    >
      <DealCard key={deal.id} deal={deal} />
    </Grid>
  );
}

function DealList({ filters }) {
  const [deals, setDeals] = useState(undefined);
  const [end, setEnd] = useState(false);
  const [resetting, setResetting] = useState(false);

  const fetchMoreItems = () => {
    if (!end && !resetting) {
      setIsFetching(true);
      getActiveDeals(20, deals?.length, filters).then((payload) => {
        if (payload.length) {
          setDeals((prevState) => {
            return [...prevState, ...payload];
          });
        } else {
          setEnd(true);
        }
        setIsFetching(false);
      });
    } else {
      setIsFetching(false);
    }
  };

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems);

  useEffect(() => {
    setResetting(true);
    setEnd(false);
    setDeals(undefined);
    getActiveDeals(20, 0, filters).then((payload) => {
      setDeals(payload);
      setResetting(false);
    });
  }, [filters]);

  if (!deals) {
    return <LoadingMask />;
  }

  return (
    <Grid container spacing={2}>
      {deals && !deals.length ? (
        <Grid xs={12} md={12} lg={12}>
          <Alert severity='info'>
            No results found - Change filters to see more deals
          </Alert>
        </Grid>
      ) : (
        deals.map((deal, index) => {
          return <DealCardWrapper deal={deal} index={index} />;
        })
      )}
      {end ? (
        <Grid xs={12} md={12} lg={12}>
          <Alert severity='info'>
            No more results - Change or reset filters to see more deals
          </Alert>
        </Grid>
      ) : undefined}
      {!end && isFetching ? (
        <Grid xs={12} md={12} lg={12}>
          <Alert severity='info'>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography>Loading...</Typography>
              <Box sx={{ minWidth: '200px', width: '100%' }}>
                <LinearProgress color='primary' />
              </Box>
            </Stack>
          </Alert>
        </Grid>
      ) : undefined}
    </Grid>
  );
}

export default DealList;
