import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function AccountStateField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    setError(false);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          label='State'
          id='state'
          aria-describedby='state-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          variant='standard'
        />
        {error ? (
          <FormHelperText id='state-helper-text'>{error}</FormHelperText>
        ) : undefined}
      </FormControl>
    </Box>
  );
}
