import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Button,
  CardHeader,
  CardActions,
  Avatar,
  Divider,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMetadata } from '../../../providers/MetadataContext';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../providers/UserContext';

function SponsorCard({ sponsor, openLoginDialog }) {
  const metadata = useMetadata();
  const user = useUser();
  const navigate = useNavigate();

  const [assetTypesExpanded, setAssetTypesExpanded] = useState(false);
  const [typesExpanded, setTypesExpanded] = useState(false);
  const [benefitsExpanded, setBenefitsExpanded] = useState(false);

  return (
    <Card sx={{ height: '100%', borderRadius: '10px' }}>
      <Box display='flex' flexDirection='column' height='100%'>
        <CardMedia
          component='img'
          height={'150px'}
          width={'100%'}
          sx={{ objectFit: 'contain' }}
          image={sponsor.logo_rectangle}
          alt={`${sponsor.name} image `}
        />
        <CardHeader
          avatar={
            <Avatar
              // sx={{ bgcolor: red[500] }}
              aria-label='sponsor'
              variant='square'
              alt={sponsor.name}
              src={sponsor.logo_square}
              sx={{ width: 56, height: 56 }}
            ></Avatar>
          }
          title={sponsor.name}
          titleTypographyProps={{ variant: 'h6' }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Typography variant='caption'>Sectors</Typography>
            </Grid>
            <Grid xs={12}>
              {sponsor?.asset_sub_types.map((entity_id, i) => {
                if (i < 2 || assetTypesExpanded) {
                  const entity = (
                    metadata?.sponsor?.lists?.asset_types || []
                  ).find((option) => option.entity_id === entity_id);
                  return (
                    <Chip
                      sx={{ margin: '5px' }}
                      label={`${entity?.group}: ${entity?.name}`}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
              {sponsor?.asset_sub_types?.length > 2 ? (
                <Button
                  sx={{ fontSize: 'x-small' }}
                  onClick={() => setAssetTypesExpanded(!assetTypesExpanded)}
                >
                  {assetTypesExpanded ? 'Less' : 'More'}
                </Button>
              ) : undefined}
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <Typography variant='caption'>Types</Typography>
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              {sponsor.investment_types.map((entity_id, i) => {
                if (i < 2 || typesExpanded) {
                  const entity = (
                    metadata?.sponsor?.lists?.investment_types || []
                  ).find((option) => option.entity_id === entity_id);
                  return <Chip sx={{ margin: '5px' }} label={entity?.name} />;
                } else {
                  return <></>;
                }
              })}
              {sponsor?.investment_types?.length > 2 ? (
                <Button
                  sx={{ fontSize: 'x-small' }}
                  onClick={() => setTypesExpanded(!typesExpanded)}
                >
                  {typesExpanded ? 'Less' : 'More'}
                </Button>
              ) : undefined}
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <Typography variant='caption'>Benefits</Typography>
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              {sponsor.investment_benefits.map((entity_id, i) => {
                if (i < 2 || benefitsExpanded) {
                  const entity = (
                    metadata?.sponsor?.lists?.investment_benefits || []
                  ).find((option) => option.entity_id === entity_id);
                  return <Chip sx={{ margin: '5px' }} label={entity?.name} />;
                } else {
                  return <></>;
                }
              })}
              {sponsor?.investment_benefits?.length > 2 ? (
                <Button
                  sx={{ fontSize: 'x-small' }}
                  onClick={() => setBenefitsExpanded(!benefitsExpanded)}
                >
                  {benefitsExpanded ? 'Less' : 'More'}
                </Button>
              ) : undefined}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{ marginTop: 'auto', justifyContent: 'flex-end' }}
        >
          <Button
            sx={{ fontSize: 'medium' }}
            onClick={
              !user?.entity_id
                ? openLoginDialog
                : () => navigate(`/sponsor/${sponsor.entity_id}`)
            }
          >
            View
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
}

export default SponsorCard;
