import { createContext, useContext, useReducer } from 'react';
import { FORM_CLEAR_ERROR, FORM_SET_ERROR } from './actionTypes';

const FormErrorContext = createContext(null);

const FormErrorDispatchContext = createContext(null);

export function FormErrorProvider({ children }) {
  const [formError, dispatch] = useReducer(formErrorReducer, {});

  return (
    <FormErrorContext.Provider value={formError}>
      <FormErrorDispatchContext.Provider value={dispatch}>
        {children}
      </FormErrorDispatchContext.Provider>
    </FormErrorContext.Provider>
  );
}

export function useFormError() {
  return useContext(FormErrorContext);
}

export function useFormErrorDispatch() {
  return useContext(FormErrorDispatchContext);
}

function formErrorReducer(formError, action) {
  switch (action.type) {
    case FORM_SET_ERROR: {
      return {
        error: action.payload,
      };
    }
    case FORM_CLEAR_ERROR: {
      return {};
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
}
