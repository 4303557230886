import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// import { BarChart } from '@mui/x-charts/BarChart';
function DealCapitalStack({ deal }) {
  return (
    <Grid container spacing={0}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Capital Stack</Typography>
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <Table>
          <TableBody>
            {deal.equity ? (
              <TableRow>
                <TableCell>Equity</TableCell>
                <TableCell align='right'>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(deal.equity)}
                </TableCell>
              </TableRow>
            ) : undefined}

            {deal.junior_debt ? (
              <TableRow>
                <TableCell>Junior Debt</TableCell>
                <TableCell align='right'>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(deal.junior_debt)}
                </TableCell>
              </TableRow>
            ) : undefined}

            <TableRow>
              <TableCell>Senior Debt</TableCell>
              <TableCell align='right'>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(deal.senior_debt)}
              </TableCell>
            </TableRow>
            {deal?.other_costs ? (
              <>
                <TableRow>
                  <TableCell>
                    <b>Purchase Price</b>
                  </TableCell>
                  <TableCell align='right'>
                    <b>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(
                        (deal?.senior_debt || 0) +
                          (deal?.junior_debt || 0) +
                          (deal?.equity || 0)
                      )}
                    </b>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Other Costs</TableCell>
                  <TableCell align='right'>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(deal?.other_costs)}
                  </TableCell>
                </TableRow>
              </>
            ) : undefined}
            <TableRow>
              <TableCell>
                <b>Deal Total</b>
              </TableCell>
              <TableCell align='right'>
                <b>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(
                    (deal?.senior_debt || 0) +
                      (deal?.junior_debt || 0) +
                      (deal?.equity || 0) +
                      (deal?.other_costs || 0)
                  )}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      {/* <Grid xs={12} md={12} lg={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            maxHeight: '400px',
          }}
        >
          <BarChart
            yAxis={[{ id: 'Capital', scaleType: 'linear' }]}
            xAxis={[{ scaleType: 'band', data: ['Capital'] }]}
            leftAxis={null}
            bottomAxis={null}
            topAxis={null}
            rightAxis={null}
            margin={{ top: 20, left: 10, right: 10, bottom: 30 }}
            legend={{
              direction: 'column',
              position: {
                vertical: 'middle',
                horizontal: 'right',
              },
            }}
            sx={{
              '--ChartsLegend-itemWidth': '150px',
              '--ChartsLegend-itemMarkSize': '10px',
              '--ChartsLegend-labelSpacing': '5px',
              '--ChartsLegend-rootSpacing': '5px',
            }}
            series={[
              {
                data: [deal.senior_debt],
                label: 'Senior Debt',
                stack: 'total',
                color: '#000a5a',
              },
              {
                data: [deal.junior_debt],
                label: 'Junior Debt',
                stack: 'total',
                color: '#2f76b1',
              },
              {
                data: [deal.gp_equity],
                label: 'Sponsor Equity',
                stack: 'total',
                color: '#4cb2d4',
              },
              {
                data: [deal.other_equity],
                label: 'Other Equity',
                stack: 'total',
                color: '#a1deed',
              },
              {
                data: [deal.amount],
                label: 'Deal Amount',
                stack: 'total',
                color: '#d0eff7',
              },
            ]}
          />
        </Box>
      </Grid> */}
    </Grid>
  );
}

export default DealCapitalStack;
