import React, { useEffect, useState, useMemo, useCallback } from 'react';

import {
  Container,
  Typography,
  Stack,
  CircularProgress,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';

import { useUser } from '../../../providers/UserContext';
import LoadingMask from '../../shared/LoadingMask';
import { useMetadata } from '../../../providers/MetadataContext';

import {
  adminCountSponsors,
  adminDeleteSponsor,
  adminGetSponsors,
  adminSetSponsorStatus,
  //   adminCreateSponsor,
  //   adminUpdateSponsor,
} from '../../../actions/adminSponsor';
import SponsorDesignationDialog from './SponsorDesignationDialog';
import SponsorOwnerDialog from './SponsorOwnerDialog';
import STATUSES from '../../shared/statuses';
import SponsorCreateDialog from './SponsorCreateDialog';

const sortSponsors = (sponsors) => {
  return sponsors.sort((a, b) => a?.name - b?.name);
};

const PAGE_SIZE = 10;

function AdminSponsorTab({ status }) {
  const metadata = useMetadata();
  const user = useUser();
  const navigate = useNavigate();
  const [revision, setRevision] = useState(0);
  const [sponsors, setSponsors] = useState(undefined);
  const [sponsor_total, setSponsorTotal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page_loading, setPageLoading] = useState(false);
  const [selectedSponsors, setSelectedSponsors] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [transitioningSponsors, setTransitioningSponsors] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState(undefined);
  const [ownerModalOpen, setOwnerModalOpen] = useState(false);
  const [designationModalOpen, setDesignationModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    if (user.entity_id) {
      if (sponsor_total === null) {
        adminCountSponsors(status).then((payload) => {
          setSponsorTotal(payload);
          setLoading(false);
        });
      }
    }
  }, [user, sponsor_total, status, setSponsorTotal]);

  useEffect(() => {
    if (user.entity_id) {
      setPageLoading(true);
      adminGetSponsors(
        status,
        paginationModel.pageSize,
        paginationModel.page * paginationModel.pageSize
      ).then((payload) => {
        setSponsors(sortSponsors(payload));
        setPageLoading(false);
      });
    }
  }, [user, paginationModel, status, setSponsors]);

  const removeSponsor = useCallback(async (sponsor_id) => {
    setTransitioningSponsors((prevTransitioningSponsors) => [
      ...prevTransitioningSponsors,
      sponsor_id,
    ]);
    await adminDeleteSponsor(sponsor_id);
    setSponsors((prevSponsors) =>
      prevSponsors.filter((inquiry) => inquiry.entity_id !== sponsor_id)
    );
    setTransitioningSponsors((prevTransitioningSponsors) =>
      prevTransitioningSponsors.filter(
        (transitioning_sponsor_id) => transitioning_sponsor_id !== sponsor_id
      )
    );
  }, []);

  const renderName = useCallback(
    (params) => {
      if (transitioningSponsors.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      return (
        <Typography
          component={Link}
          onClick={() => {
            navigate(`/sponsor/${params.row?.entity_id}`);
          }}
          variant='body2'
        >
          {params.row?.name}
        </Typography>
      );
    },
    [navigate, transitioningSponsors]
  );
  const renderOwner = useCallback((params) => {
    return (
      <Typography variant='body2'>
        {params.row?.owner?.first_name} {params.row?.owner?.last_name}
      </Typography>
    );
  }, []);

  const renderDesignation = useCallback(
    (params) => {
      const name = (metadata?.sponsor?.lists?.designations || []).find(
        (option) => option.entity_id === params?.row?.designation
      )?.name;
      if (name) {
        return (
          <Typography variant='body2'>
            {
              (metadata?.sponsor?.lists?.designations || []).find(
                (option) => option.entity_id === params?.row?.designation
              )?.name
            }
          </Typography>
        );
      } else {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <ErrorOutlineIcon color='error' />
            <Typography variant='body2'>Missing</Typography>
          </Stack>
        );
      }
    },
    [metadata?.sponsor?.lists?.designations]
  );

  const setDesignation = useCallback((sponsor) => {
    setSelectedSponsor(sponsor);
    setRevision((prevState) => prevState + 1);
    setDesignationModalOpen(true);
  }, []);

  const setOwner = useCallback((sponsor) => {
    setSelectedSponsor(sponsor);
    setRevision((prevState) => prevState + 1);
    setOwnerModalOpen(true);
  }, []);

  const approveSponsor = useCallback(async (sponsor_id) => {
    setTransitioningSponsors((prevTransitioningSponsors) => [
      ...prevTransitioningSponsors,
      sponsor_id,
    ]);
    await adminSetSponsorStatus(sponsor_id, STATUSES.ACTIVE);
    setSponsors((prevSponsors) =>
      prevSponsors.filter((sponsor) => sponsor.entity_id !== sponsor_id)
    ); // tab is status specific, approving sponsor should remove it from pending tab
    setTransitioningSponsors((prevTransitioningSponsors) =>
      prevTransitioningSponsors.filter(
        (transitioning_sponsor_id) => transitioning_sponsor_id !== sponsor_id
      )
    );
  }, []);

  const rejectSponsor = useCallback(async (sponsor_id) => {
    setTransitioningSponsors((prevTransitioningSponsors) => [
      ...prevTransitioningSponsors,
      sponsor_id,
    ]);
    await adminSetSponsorStatus(sponsor_id, STATUSES.DRAFT);
    setSponsors((prevSponsors) =>
      prevSponsors.filter((sponsor) => sponsor.entity_id !== sponsor_id)
    ); // tab is status specific, rejecting sponsor should remove it from pending tab
    setTransitioningSponsors((prevTransitioningSponsors) =>
      prevTransitioningSponsors.filter(
        (transitioning_sponsor_id) => transitioning_sponsor_id !== sponsor_id
      )
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 2,
        renderCell: renderName,
      },
      {
        field: 'designation',
        headerName: 'Designation',
        type: 'string',
        flex: 2,
        renderCell: renderDesignation,
      },
      {
        field: 'owner',
        headerName: 'Owner',
        type: 'string',
        flex: 2,
        renderCell: renderOwner,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        type: 'string',
        // renderCell: renderCreatedDate,
        minWidth: 150,
        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];
          if ([STATUSES.DRAFT, STATUSES.PENDING].includes(params.row?.status)) {
            actions.push(
              <GridActionsCellItem
                icon={<ThumbUpAltOutlinedIcon />}
                label='Activate Sponsor'
                onClick={() => approveSponsor(params.id)}
                showInMenu
              />
            );
          }
          if (params.row?.status === STATUSES.PENDING) {
            actions.push(
              <GridActionsCellItem
                icon={<ThumbDownOffAltOutlinedIcon />}
                label='Reject Sponsor'
                onClick={() => rejectSponsor(params.id)}
                showInMenu
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<ClassOutlinedIcon />}
              label='Set Designation'
              onClick={() => setDesignation(params.row)}
              showInMenu
            />
          );
          actions.push(
            <GridActionsCellItem
              icon={<PersonOutlinedIcon />}
              label='Set Owner'
              onClick={() => setOwner(params.row)}
              showInMenu
            />
          );
          actions.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              onClick={() =>
                navigate({
                  pathname: '/admin/sponsor/edit',
                  search: `?sponsor=${params.id}`,
                })
              }
              showInMenu
            />
          );
          actions.push(
            <GridActionsCellItem
              icon={<DeleteOutlinedIcon color='error' />}
              label='Remove'
              onClick={() => removeSponsor(params.id)}
              showInMenu
            />
          );
          return actions;
        },
      },
    ],
    [
      navigate,
      renderName,
      renderOwner,
      renderDesignation,
      removeSponsor,
      approveSponsor,
      rejectSponsor,
      setDesignation,
      setOwner,
    ]
  );

  const SponsorsGridToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <Stack direction='row' spacing={1}>
          {status === STATUSES.DRAFT ? (
            <Button
              color='primary'
              variant='outlined'
              onClick={() => setCreateModalOpen(true)}
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <AddCircleOutlineIcon />
                <Typography variant='button' display='block'>
                  Create
                </Typography>
              </Stack>
            </Button>
          ) : undefined}
          {status === STATUSES.PENDING ? (
            <>
              <Button
                variant='outlined'
                disabled={!selectedSponsors.length}
                onClick={async () => {
                  await Promise.all(
                    [].concat(
                      selectedSponsors.map(async (sponsor_id) => {
                        await approveSponsor(sponsor_id);
                      })
                    )
                  );
                }}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <ThumbUpAltOutlinedIcon />
                </Stack>
              </Button>
              <Button
                variant='outlined'
                color='error'
                disabled={!selectedSponsors.length}
                onClick={async () => {
                  await Promise.all(
                    [].concat(
                      selectedSponsors.map(async (sponsor_id) => {
                        await rejectSponsor(sponsor_id);
                      })
                    )
                  );
                }}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <ThumbDownOffAltOutlinedIcon />
                </Stack>
              </Button>
            </>
          ) : undefined}
          <GridToolbarColumnsButton variant='outlined' color='inherit' />
          <GridToolbarFilterButton variant='outlined' color='inherit' />
        </Stack>
      </GridToolbarContainer>
    );
  }, [approveSponsor, rejectSponsor, selectedSponsors, status]);

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Container sx={{ padding: '15px' }}>
      <Grid container spacing={4}>
        <Grid xs={12} md={12} lg={12}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  autoHeight
                  checkboxSelection
                  getRowId={(row) => row.entity_id}
                  columns={columns}
                  rows={sponsors || []}
                  rowCount={sponsor_total}
                  loading={page_loading}
                  pageSizeOptions={[PAGE_SIZE]}
                  paginationModel={paginationModel}
                  paginationMode='server'
                  onPaginationModelChange={setPaginationModel}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectedSponsors(newSelectionModel);
                  }}
                  onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectedSponsors(newSelectionModel);
                  }}
                  rowSelectionModel={selectedSponsors}
                  slots={{
                    toolbar: SponsorsGridToolbar,
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <SponsorDesignationDialog
        key={`sponsor_designation_${revision}`}
        sponsor={selectedSponsor}
        open={designationModalOpen}
        handleClose={() => {
          setDesignationModalOpen(false);
          setSelectedSponsor(undefined);
          setRevision((prevState) => prevState + 1);
        }}
        updateSponsor={(updated_sponsor) => {
          setSponsors((prevSponsors) => {
            const index = prevSponsors.findIndex(
              (sponsor) => sponsor.entity_id === updated_sponsor.entity_id
            );

            const newSponsors = [...prevSponsors];
            newSponsors[index] = updated_sponsor;
            return newSponsors;
          });
        }}
      />
      <SponsorOwnerDialog
        key={`sponsor_owner_${revision}`}
        sponsor={selectedSponsor}
        open={ownerModalOpen}
        handleClose={() => {
          setOwnerModalOpen(false);
          setSelectedSponsor(undefined);
          setRevision((prevState) => prevState + 1);
        }}
        updateSponsor={(updated_sponsor) => {
          setSponsors((prevSponsors) => {
            const index = prevSponsors.findIndex(
              (sponsor) => sponsor.entity_id === updated_sponsor.entity_id
            );

            const newSponsors = [...prevSponsors];
            newSponsors[index] = updated_sponsor;
            return newSponsors;
          });
        }}
      />
      <SponsorCreateDialog
        key={`sponsor_create_${revision}`}
        open={createModalOpen}
        handleClose={() => {
          setCreateModalOpen(false);
          setRevision((prevState) => prevState + 1);
        }}
      />
    </Container>
  );
}

export default AdminSponsorTab;
