import React from 'react';

import { MobileStepper, Box, Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function DealMobileImageList({ images }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex' }} justifyContent={'center'}>
        <Box
          component='img'
          sx={{
            maxHeight: 300,
            display: 'block',
            overflow: 'hidden',
          }}
          loading='lazy'
          src={images[activeStep]?.url}
          alt={images[activeStep]?.entity_id}
        />
      </Box>

      <MobileStepper
        variant='dots'
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </Box>
  );
}

export default DealMobileImageList;
