const userInvestorProfileComplete = (user) => {
  if (!user.entity_id) {
    return false;
  }
  return Boolean(
    user.accredited !== null &&
      user.professional !== null &&
      user.dependents !== null &&
      user.annual_income_type &&
      user.cash_and_investment_type &&
      user.employment_status &&
      user.investment_experience_type &&
      user.investment_goal &&
      user.investment_horizon &&
      user.marital_status &&
      user.net_worth_type &&
      user.risk_tolerance_type &&
      user.source_of_funds &&
      user.withdraw_likelihood
  );
};

export default userInvestorProfileComplete;
