import React, { useState } from 'react';

import {
  Box,
  TextField,
  Autocomplete,
  FormControl,
  FormHelperText,
} from '@mui/material';
import countries from '../../../util/countries';

export default function AccountCountryField({
  value,
  error,
  setValue,
  setError,
}) {
  const [selected, setSelected] = useState(
    countries.find((country) => country.abbr === value)
  );

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setValue(newValue.abbr);
      setError(false);
    } else {
      setError('Country cannot be blank');
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Autocomplete
          id='country'
          aria-required
          aria-describedby='country-helper-text'
          options={countries}
          value={selected}
          onChange={handleChange}
          getOptionLabel={(option) => `${option ? option.name : ''}`}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Country'
              required
              error={Boolean(error || value === '')}
            />
          )}
        />
        {error ? (
          <FormHelperText id='country-helper-text'>{error}</FormHelperText>
        ) : undefined}
      </FormControl>
    </Box>
  );
}
