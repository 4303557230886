import React from 'react';

import { Drawer, Divider } from '@mui/material';

import SidebarHeader from './SidebarHeader';
import SidebarContent from './SidebarContent';

const drawerWidth = 240;

function Sidebar({ open, toggleDrawer }) {
  return (
    <>
      <Drawer
        variant='temporary'
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <SidebarHeader toggleDrawer={toggleDrawer} />
        <Divider />
        <SidebarContent />
      </Drawer>
      <Drawer
        variant='persistent'
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open={open}
      >
        <SidebarHeader toggleDrawer={toggleDrawer} />
        <Divider />
        <SidebarContent toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
}

export default Sidebar;
