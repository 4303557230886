import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import SponsorDesignationField from './SponsorDesignationField';
import { adminSetSponsorDesignation } from '../../../actions/adminSponsor';

function SponsorDesignationDialog({
  sponsor,
  open,
  handleClose,
  updateSponsor,
}) {
  const [designation, setDesignation] = useState(sponsor?.designation || '');
  const [loading, setLoading] = useState(false);

  const updateDesignation = async () => {
    if (designation !== sponsor?.designation) {
      const updated_sponsor = await adminSetSponsorDesignation(
        sponsor.entity_id,
        designation
      );

      updateSponsor(updated_sponsor);
    }
  };

  if (!sponsor) {
    return (
      <Dialog onClose={() => handleClose()} open={open}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              Sponsor Not Found
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs>
            {sponsor.name}
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            display: 'flex',
            maxWidth: 800,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={12} md={12} lg={12}>
              <SponsorDesignationField
                value={designation}
                setValue={setDesignation}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button color='inherit' onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await updateDesignation();
              handleClose();
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SponsorDesignationDialog;
