import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../providers/actionTypes';
import { useUser } from '../../../providers/UserContext';
import AccountFirstNameField from './AccountFirstNameField';
import AccountLastNameField from './AccountLastNameField';
import AccountEmailField from './AccountEmailField';
import AccountPhoneField from './AccountPhoneField';
import AccountAddressField from './AccountAddressField';
import AccountAddress2Field from './AccountAddress2Field';
import AccountCityField from './AccountCityField';
import AccountStateField from './AccountStateField';
import AccountPostalCodeField from './AccountPostalCodeField';
import AccountCountryField from './AccountCountryField';
import AccountPictureField from './AccountPictureField';

function AccountContactForm({ setValues }) {
  const user = useUser();
  const formErrorDispatch = useFormErrorDispatch();
  const [revision, setRevision] = useState(0);
  const [first_name, setFirstName] = useState(user.first_name || '');
  const [first_name_error, setFirstNameError] = useState(false);
  const [last_name, setLastName] = useState(user.last_name || '');
  const [last_name_error, setLastNameError] = useState(false);
  const [phone, setPhone] = useState(user.phone || '');
  const [phone_error, setPhoneError] = useState(false);
  const [email, setEmail] = useState(user.email || '');
  const [email_error, setEmailError] = useState(false);
  const [address, setAddress] = useState(user.address || '');
  const [address_error, setAddressError] = useState(false);
  const [address2, setAddress2] = useState(user.address2 || '');
  const [address2_error, setAddress2Error] = useState(false);
  const [city, setCity] = useState(user.city || '');
  const [city_error, setCityError] = useState(false);
  const [state, setState] = useState(user.state || '');
  const [state_error, setStateError] = useState(false);
  const [postal_code, setPostalCode] = useState(user.postal_code || '');
  const [postal_code_error, setPostalCodeError] = useState(false);
  const [country, setCountry] = useState(user.country || '');
  const [country_error, setCountryError] = useState(false);
  const [picture, setPicture] = useState(user.picture);

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [user]);
  useEffect(() => {
    if (
      first_name_error ||
      first_name === '' ||
      last_name_error ||
      last_name === '' ||
      email_error ||
      email === '' ||
      phone_error ||
      phone === '' ||
      address_error ||
      city_error ||
      state_error ||
      postal_code_error ||
      country_error ||
      country === '' ||
      address2_error
    ) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [
    formErrorDispatch,
    first_name,
    email,
    last_name,
    phone,
    address,
    city,
    state,
    postal_code,
    country,
    first_name_error,
    last_name_error,
    email_error,
    phone_error,
    address_error,
    city_error,
    state_error,
    postal_code_error,
    country_error,
    address2_error,
  ]);

  useEffect(() => {
    setValues({
      first_name,
      email,
      last_name,
      phone,
      address,
      city,
      state,
      postal_code,
      country,
      address2,
      picture,
    });
  }, [
    setValues,
    first_name,
    email,
    last_name,
    phone,
    address,
    city,
    state,
    postal_code,
    country,
    address2,
    picture,
  ]);

  return (
    <Box width='100%' padding='15px'>
      {/* <Paper sx={{ display: 'flex', width: '100%' }}> */}
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 600,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <AccountFirstNameField
                  key={`first_name_${revision}`}
                  value={first_name}
                  error={first_name_error}
                  setValue={setFirstName}
                  setError={setFirstNameError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <AccountLastNameField
                  key={`last_name_${revision}`}
                  value={last_name}
                  error={last_name_error}
                  setValue={setLastName}
                  setError={setLastNameError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <AccountEmailField
                  key={`email_${revision}`}
                  value={email}
                  error={email_error}
                  setValue={setEmail}
                  setError={setEmailError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <AccountPictureField
                  key={`picture_${revision}`}
                  value={picture}
                  setValue={setPicture}
                  user_id={user.entity_id}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <AccountPhoneField
                  key={`phone_${revision}`}
                  value={phone}
                  error={phone_error}
                  setValue={setPhone}
                  setError={setPhoneError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <AccountAddressField
                  key={`address_${revision}`}
                  value={address}
                  error={address_error}
                  setValue={setAddress}
                  setError={setAddressError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <AccountAddress2Field
                  key={`address2_${revision}`}
                  value={address2}
                  error={address2_error}
                  setValue={setAddress2}
                  setError={setAddress2Error}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <AccountCityField
                  key={`city_${revision}`}
                  value={city}
                  error={city_error}
                  setValue={setCity}
                  setError={setCityError}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <AccountStateField
                  key={`state_${revision}`}
                  value={state}
                  error={state_error}
                  setValue={setState}
                  setError={setStateError}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <AccountPostalCodeField
                  key={`postal_code_${revision}`}
                  value={postal_code}
                  error={postal_code_error}
                  setValue={setPostalCode}
                  setError={setPostalCodeError}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <AccountCountryField
                  key={`country_${revision}`}
                  value={country}
                  error={country_error}
                  setValue={setCountry}
                  setError={setCountryError}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </Paper> */}
    </Box>
  );
}

export default AccountContactForm;
