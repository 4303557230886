import React from 'react';
import Markdown from 'react-markdown';

import { Typography, Chip, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMetadata } from '../../../providers/MetadataContext';
import DealFinancialMetrics from './DealFinancialMetrics';
import DealCapitalStack from './DealCapitalStack';
import DealNarrative from './DealNarrative';
import DealMobileImageList from './DealMobileImageList';

function DealMobile({ deal }) {
  const metadata = useMetadata();
  const asset_sub_type = (metadata?.deal?.lists?.asset_types || []).find(
    (option) => option.entity_id === deal?.asset_sub_type
  );
  return (
    <Grid container spacing={4}>
      <Grid xs={12} md={12} lg={12}>
        <DealMobileImageList images={deal?.images || []} />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h4'>{deal.name}</Typography>
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <Chip label={`${asset_sub_type?.group}:${asset_sub_type?.name}`} />
        <Chip
          label={
            (metadata?.deal?.lists?.investment_strategies || []).find(
              (option) => option.entity_id === deal?.investment_strategy
            )?.name
          }
        />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='body2'>
          <Markdown>{deal.description}</Markdown>
        </Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>
          <b>At A Glance</b>
        </Typography>
        <Divider />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <DealFinancialMetrics deal={deal} />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <DealCapitalStack deal={deal} />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <DealNarrative deal={deal} />
      </Grid>
    </Grid>
  );
}

export default DealMobile;
