import React, { useEffect, useState, useCallback } from 'react';

import { Typography, Button, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoadingMask from '../../../../shared/LoadingMask';
import {
  deleteSponsorTeamMember,
  getSponsorTeamMembers,
} from '../../../../../actions/sponsorTeamMember';
import SponsorTeamMemberCard from './SponsorTeamMemberCard';
import SponsorTeamMemberModal from './SponsorTeamMemberModal';
import {
  adminDeleteSponsorTeamMember,
  adminGetSponsorTeamMembers,
} from '../../../../../actions/adminSponsorTeamMember';
import STATUSES from '../../../../shared/statuses';
function SponsorTeamMembers({ admin, sponsor }) {
  const [team_members, setTeamMembers] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [editingTeamMember, setEditingTeamMember] = useState(undefined);

  useEffect(() => {
    if (sponsor?.entity_id && !team_members) {
      if (admin) {
        adminGetSponsorTeamMembers(sponsor.entity_id)
          .then((payload) => {
            setTeamMembers(payload);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        getSponsorTeamMembers(sponsor.entity_id)
          .then((payload) => {
            setTeamMembers(payload);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  }, [admin, team_members, sponsor]);

  const handleModalResult = useCallback((result) => {
    if (!result) {
      return;
    }
    setTeamMembers((prev_team_members) => {
      const index = prev_team_members.findIndex(
        (team_member) => team_member.entity_id === result.entity_id
      );
      if (index === -1) {
        // new deal
        return [...prev_team_members, result];
      } else {
        const new_team_members = [...prev_team_members];
        new_team_members[index] = result;
        return new_team_members;
      }
    });
  }, []);

  const handleDelete = useCallback(
    async (member_id) => {
      if (admin) {
        await adminDeleteSponsorTeamMember(sponsor.entity_id, member_id);
      } else {
        await deleteSponsorTeamMember(sponsor.entity_id, member_id);
      }

      setTeamMembers((prev_team_members) => {
        const index = prev_team_members.findIndex(
          (team_member) => team_member.entity_id === member_id
        );
        const new_team_members = [...prev_team_members];
        if (index !== -1) {
          new_team_members.splice(index, 1);
        }
        return new_team_members;
      });
    },
    [admin, sponsor]
  );

  if (loading) {
    return <LoadingMask />;
  }
  return (
    <Grid container spacing={2}>
      {team_members?.map((team_member) => {
        return (
          <Grid xs={12} md={6} lg={4}>
            <SponsorTeamMemberCard
              team_member={team_member}
              actions={
                <>
                  {' '}
                  <Button
                    color='inherit'
                    variant='outlined'
                    onClick={async () => {
                      setEditingTeamMember(team_member);
                      setFormOpen(true);
                    }}
                    disabled={!admin && sponsor.status === STATUSES.PENDING}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <EditIcon />
                      <Typography variant='button' display='block'>
                        Edit
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    color='inherit'
                    variant='outlined'
                    onClick={async () => {
                      handleDelete(team_member.entity_id);
                    }}
                    disabled={!admin && sponsor.status === STATUSES.PENDING}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <DeleteIcon />
                      <Typography variant='button' display='block'>
                        Delete
                      </Typography>
                    </Stack>
                  </Button>
                </>
              }
            />
          </Grid>
        );
      })}
      <Grid xs={12} md={12} lg={12}>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => {
            setFormOpen(true);
          }}
          disabled={!admin && sponsor.status === STATUSES.PENDING}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <AddCircleOutlineIcon />
            <Typography variant='button' display='block'>
              Add
            </Typography>
          </Stack>
        </Button>
      </Grid>
      {formOpen ? (
        <SponsorTeamMemberModal
          open
          admin={admin}
          handleClose={(deal) => {
            if (deal) {
              handleModalResult(deal);
            }
            setEditingTeamMember(undefined);
            setFormOpen(false);
          }}
          team_member={editingTeamMember}
          sponsor_id={sponsor?.entity_id}
        />
      ) : undefined}
    </Grid>
  );
}

export default SponsorTeamMembers;
