import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useStytch, useStytchUser } from '@stytch/react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../providers/UserContext';

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const AuthCallback = () => {
  const navigate = useNavigate();
  const stytch = useStytch();
  const user = useUser();

  // Get the Stytch User object if available
  const { user: stytchUser } = useStytchUser();

  const [error, setError] = useState(false);

  console.log(error);
  useEffect(() => {
    // If the stytch SDK is available, and there is no existing user check for a token value in query params
    if (stytch && !stytchUser) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      const tokenType = queryParams.get('stytch_token_type');

      // If a token is found, authenticate it with the appropriate method
      if (token && tokenType) {
        if (tokenType === 'magic_links') {
          stytch.magicLinks
            .authenticate(token, {
              session_duration_minutes: 60,
            })

            .catch((error) => {
              console.log(error);
              setError(true);
            });
        } else if (tokenType === 'oauth') {
          stytch.oauth
            .authenticate(token, {
              session_duration_minutes: 60,
            })

            .catch((error) => {
              console.log(error);
              setError(true);
            });
        }
      }
    }
  }, [stytch, stytchUser]);

  if (user.entity_id) {
    navigate('/');
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Typography>
            Authentication error, please try again. If this error persists,
            contact support at{' '}
            <a href='mailto:support@iom.ai'>support@iom.ai</a>
          </Typography>
        </Container>
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <Backdrop open={!user.entity_id}>
          <Box
            sx={{ display: 'flex' }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress color='inherit' size={50} />
          </Box>
        </Backdrop>
      </Container>
    </Box>
  );
};

export default AuthCallback;
