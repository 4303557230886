import React, { useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { useMetadata } from '../../../../providers/MetadataContext';

function DealDistributionFrequencyField({ value, setValue }) {
  const metadata = useMetadata();
  const [selected, setSelected] = useState(
    (metadata?.deal?.lists?.distribution_frequencies || []).find(
      (option) => option.entity_id === value
    )
  );

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue.entity_id);
  };

  return (
    <Box>
      <Autocomplete
        id='distribution-frequency-field'
        options={metadata?.deal?.lists?.distribution_frequencies || []}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        renderInput={(params) => (
          <TextField {...params} label='Distribution Frequency' />
        )}
      />
    </Box>
  );
}

export default DealDistributionFrequencyField;
