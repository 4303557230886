import React from 'react';
import { Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useFormError } from '../../providers/FormErrorContext';

function FormUpdateButton({ handleUpdate, loading, label }) {
  const formError = useFormError();
  return (
    <Tooltip title={Boolean(formError.error) ? 'Fix form errors to save.' : ''}>
      <span>
        <LoadingButton
          loading={loading}
          onClick={handleUpdate}
          disabled={Boolean(formError.error)}
          variant='outlined'
        >
          {label}
        </LoadingButton>
      </span>
    </Tooltip>
  );
}

export default FormUpdateButton;
