import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../../providers/actionTypes';
import DealImageUploadField from './DealImageUploadField';
import DealImageListEditor from './DealImageListEditor';
import DealDocumentListEditor from './DealDocumentListEditor';
import DealDocumentUploadField from './DealDocumentUploadField';

function DealMediaDocuments({ deal, setValues }) {
  const formErrorDispatch = useFormErrorDispatch();
  const [images, setImages] = useState(
    deal?.images.sort((a, b) => a.order - b.order) || []
  );
  const [documents, setDocuments] = useState(
    deal?.documents.sort((a, b) => a.order - b.order) || []
  );

  useEffect(() => {
    if (false) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [formErrorDispatch]);

  useEffect(() => {
    setValues({
      images: images.map((image, order) => {
        return { ...image, order };
      }),
      documents: documents.map((document, order) => {
        return { ...document, order };
      }),
    });
  }, [setValues, images, documents]);

  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 800,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Images</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='caption'>
                  Upload images showcasing the property and/or any artist
                  renderings or diagrams
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealImageUploadField
                  deal_id={deal.entity_id}
                  disabled={images.length >= 10}
                  addImage={(values) =>
                    setImages((prevImages) => {
                      return [...prevImages, values];
                    })
                  }
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='caption'>
                  The first image below will be used as the thumbnail image when
                  investors search for deals. Drag-and-drop to reorder images.
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DndProvider backend={HTML5Backend}>
                  <DealImageListEditor value={images} setValue={setImages} />
                </DndProvider>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <br />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Documents</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealDocumentUploadField
                  deal_id={deal.entity_id}
                  disabled={documents.length >= 10}
                  addDocument={(values) =>
                    setDocuments((prevDocuments) => {
                      return [...prevDocuments, values];
                    })
                  }
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealDocumentListEditor
                  value={documents}
                  setValue={setDocuments}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DealMediaDocuments;
