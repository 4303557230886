import React from 'react';
import dayjs from 'dayjs';
import { FormControl, Box, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function ClosedDealInceptionDateField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (value) => {
    setValue(value.format('L'));
  };
  return (
    <Box>
      <FormControl fullWidth required>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Inception Date'
            value={dayjs(value)}
            onChange={handleChange}
            aria-describedby='inception-date-helper-text'
            id='inception_date'
            onError={(newError) => setError(newError)}
            disableFuture
            required
          />
        </LocalizationProvider>

        {error ? (
          <FormHelperText id='inception-date-helper-text'>
            Inception date must be in the past.
          </FormHelperText>
        ) : (
          <FormHelperText id='inception-date-helper-text'>
            Enter the start date of the investment.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
