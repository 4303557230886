import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SponsorFoundingYearField({
  value,
  error,
  setValue,
  setError,
  ...rest
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (newValue === '') {
      setError('Founding year cannot be blank');
    } else if (!/^[0-9]{4}$/.test(newValue)) {
      setError('Founding year must be 4-digit number');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          {...rest}
          label='Year Founded'
          id='founding_year'
          aria-describedby='founding-year-helper-text'
          onChange={handleChange}
          error={Boolean(error || value === '')}
          value={value}
          variant='standard'
          required
        />
        {error ? (
          <FormHelperText id='founding-year-helper-text'>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id='founding-year-helper-text'>
            Enter the year the sponsor was founded
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
