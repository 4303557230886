import React from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SponsorTeamMembers from './SponsorTeamMembers';

function SponsorTeam({ admin, sponsor }) {
  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 1200,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Management Team</Typography>
                <Typography variant='caption'>
                  * Changes to management team have immediate effect
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <SponsorTeamMembers admin={admin} sponsor={sponsor} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SponsorTeam;
