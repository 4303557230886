import api from '../apis/api';

export const getSponsorTeamMembers = async (sponsor_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}/team`);
  return resp.data;
};

export const getSponsorTeamMember = async (sponsor_id, member_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}/team/${member_id}`);
  return resp.data;
};

export const createSponsorTeamMember = async (sponsor_id, values) => {
  const resp = await api.post(`/sponsor/${sponsor_id}/team`, values);
  return resp.data;
};

export const updateSponsorTeamMember = async (
  sponsor_id,
  member_id,
  values
) => {
  const resp = await api.put(
    `/sponsor/${sponsor_id}/team/${member_id}`,
    values
  );
  return resp.data;
};

export const deleteSponsorTeamMember = async (sponsor_id, member_id) => {
  const resp = await api.delete(`/sponsor/${sponsor_id}/team/${member_id}`);
  return resp.data;
};
