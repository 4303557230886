import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function DealInvestmentPeriodField({
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (!/^[0-9]*$/.test(newValue)) {
      setError('Investment Period must be an integer');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          label='Investment Period'
          id='investment_period'
          aria-describedby='investment-period-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          variant='standard'
        />
        {error ? (
          <FormHelperText id='investment-period-helper-text'>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id='investment-period-helper-text'>
            Enter the investment period in years.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
