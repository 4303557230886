import { Container, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../providers/UserContext';
import LoginBox from './LoginBox';
import { useStytchUser } from '@stytch/react';
import LoadingMask from './shared/LoadingMask';

const ProtectedRoute = ({ admin, children }) => {
  const user = useUser();
  const { user: stytchUser } = useStytchUser();

  if (!stytchUser) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                You must login to access this page.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <LoginBox />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (stytchUser && !user.entity_id) {
    return <LoadingMask />;
  }

  if (user.entity_id && !user.terms_version) {
    return <Navigate to='/account/new' />;
  }

  if (admin && !user.admin) {
    return (
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid xs={12} md={12} lg={12} justifyContent='center' display='flex'>
            <Typography variant='h5'>
              Only administrators can access this page
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children ? children : <Outlet />;
};
export default ProtectedRoute;
