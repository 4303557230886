import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Link,
  Container,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

function Oops() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <>
      <Box
        //   bgcolor={theme.palette.alternate.main}
        position={'relative'}
        minHeight={'calc(100vh - 247px)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <Grid container>
            <Grid
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
            >
              <Box height={1} width={1} maxWidth={500}>
                <Box
                  component={'img'}
                  src={'/img/not-found.svg'}
                  width={1}
                  height={1}
                  sx={{
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.8)'
                        : 'none',
                  }}
                />
              </Box>
            </Grid>
            <Grid
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
            >
              <Box>
                <Typography
                  variant='h6'
                  component='p'
                  color='text.secondary'
                  align={isMd ? 'left' : 'center'}
                >
                  Oops! Looks like you followed a bad link.
                  <br />
                  If you think this is a problem with us, please{' '}
                  <Link
                    href={'mailto:webmaster@iom.ai?subject=Broken%20Link'}
                    underline='none'
                  >
                    tell us
                  </Link>
                </Typography>
                <Box
                  marginTop={4}
                  display={'flex'}
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  <Button
                    component={Link}
                    variant='contained'
                    color='primary'
                    size='large'
                    href={'/'}
                  >
                    Back home
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Oops;
