import React, { useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from './Header';

import { MetadataProvider } from '../providers/MetadataContext';
import { UserProvider } from '../providers/UserContext';
import Sidebar from './Sidebar';

const Layout = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <UserProvider>
      <MetadataProvider>
        <Header open={open} toggleDrawer={toggleDrawer} />
        <Sidebar
          sx={{ display: 'flex' }}
          open={open}
          toggleDrawer={toggleDrawer}
        />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Outlet />
          <ScrollRestoration />
        </Box>
      </MetadataProvider>
    </UserProvider>
  );
};

export default Layout;
