import React from 'react';

import { Box } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
// import {
//   useFormErrorDispatch,
//   useFormError,
// } from '../../../providers/FormErrorContext';

// import { useUser, useUserDispatch } from '../../../providers/UserContext';

import AccountDelete from './AccountDelete';

function AccountActions() {
  // const user = useUser();
  // const formErrorDispatch = useFormErrorDispatch();
  // const userDispatch = useUserDispatch();
  // const formError = useFormError();

  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 600,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <AccountDelete />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AccountActions;
