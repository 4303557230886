import React, { useState } from 'react';

import { Box, Chip, TextField, Autocomplete } from '@mui/material';
import { useMetadata } from '../../../../providers/MetadataContext';

function SponsorInvestmentBenefitsField({ value, setValue, ...rest }) {
  const metadata = useMetadata();
  const [selected, setSelected] = useState(
    value.map((entity_id) => {
      return (metadata?.sponsor?.lists?.investment_benefits || []).find(
        (option) => option.entity_id === entity_id
      );
    })
  );

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue.map((option) => option.entity_id));
  };

  return (
    <Box>
      <Autocomplete
        {...rest}
        multiple
        id='investment-benefits-field'
        options={metadata?.sponsor?.lists?.investment_benefits || []}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label='Investment Benefits' />
        )}
      />
    </Box>
  );
}

export default SponsorInvestmentBenefitsField;
