import { createContext, useContext, useReducer } from 'react';
import {
  METADATA_DEAL_FETCH,
  METADATA_SPONSOR_FETCH,
  METADATA_USER_FETCH,
} from './actionTypes';
import buildEntityToNameLookup from '../util/buildEntityToNameLookup';
import flattenAssetTypes from './flattenAssetTypes';

const MetadataContext = createContext(null);

const MetadataDispatchContext = createContext(null);

export function MetadataProvider({ children }) {
  const [metadata, dispatch] = useReducer(metadataReducer, {});

  return (
    <MetadataContext.Provider value={metadata}>
      <MetadataDispatchContext.Provider value={dispatch}>
        {children}
      </MetadataDispatchContext.Provider>
    </MetadataContext.Provider>
  );
}

export function useMetadata() {
  return useContext(MetadataContext);
}

export function useMetadataDispatch() {
  return useContext(MetadataDispatchContext);
}

function metadataReducer(metadata, action) {
  switch (action.type) {
    case METADATA_DEAL_FETCH: {
      const lists = { ...action.payload };
      if (action.payload.asset_types) {
        lists.asset_types = flattenAssetTypes(action.payload.asset_types);
      }
      const lookups = {};
      Object.entries(lists).forEach(([dimension_name, dimension_list]) => {
        lookups[dimension_name] = buildEntityToNameLookup(dimension_list);
      });
      const deal = {
        lists,
        lookups,
      };
      return {
        ...metadata,
        deal,
      };
    }
    case METADATA_SPONSOR_FETCH: {
      const lists = { ...action.payload };
      if (action.payload.asset_types) {
        lists.asset_types = flattenAssetTypes(action.payload.asset_types);
      }
      const lookups = {};
      Object.entries(lists).forEach(([dimension_name, dimension_list]) => {
        lookups[dimension_name] = buildEntityToNameLookup(dimension_list);
      });
      const sponsor = {
        lists,
        lookups,
      };
      return {
        ...metadata,
        sponsor,
      };
    }
    case METADATA_USER_FETCH: {
      const lists = { ...action.payload };
      const lookups = {};
      Object.entries(lists).forEach(([dimension_name, dimension_list]) => {
        lookups[dimension_name] = buildEntityToNameLookup(dimension_list);
      });
      const user = {
        lists,
        lookups,
      };
      return {
        ...metadata,
        user,
      };
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
}
