import React from 'react';
import dayjs from 'dayjs';
import { FormControl, Box, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function ClosedDealRealizationDateField({
  value,
  error,
  setValue,
  setError,
  minDate,
}) {
  const handleChange = (value) => {
    if (value) {
      setValue(value.format('L'));
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Realization Date'
            value={value === '' ? null : dayjs(value)}
            onChange={handleChange}
            aria-describedby='realization-date-helper-text'
            id='realization_date'
            onError={(newError) => {
              setError(newError);
            }}
            minDate={minDate}
            disableFuture
            slotProps={{
              field: { clearable: true, onClear: () => setValue('') },
            }}
          />
        </LocalizationProvider>

        {error ? (
          <FormHelperText id='realization-date-helper-text'>
            Realization date must be in the past and after inception date (Leave
            blank for active, unrealized deals).
          </FormHelperText>
        ) : (
          <FormHelperText id='realization-date-date-helper-text'>
            Enter the date the investment was realized.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
