import api from '../apis/api';

export const adminGetSponsorTeamMembers = async (sponsor_id) => {
  const resp = await api.get(`/admin/sponsor/${sponsor_id}/team`);
  return resp.data;
};

export const adminGetSponsorTeamMember = async (sponsor_id, member_id) => {
  const resp = await api.get(`/admin/sponsor/${sponsor_id}/team/${member_id}`);
  return resp.data;
};

export const adminCreateSponsorTeamMember = async (sponsor_id, values) => {
  const resp = await api.post(`/admin/sponsor/${sponsor_id}/team`, values);
  return resp.data;
};

export const adminUpdateSponsorTeamMember = async (
  sponsor_id,
  member_id,
  values
) => {
  const resp = await api.put(
    `/admin/sponsor/${sponsor_id}/team/${member_id}`,
    values
  );
  return resp.data;
};

export const adminDeleteSponsorTeamMember = async (sponsor_id, member_id) => {
  const resp = await api.delete(
    `/admin/sponsor/${sponsor_id}/team/${member_id}`
  );
  return resp.data;
};
