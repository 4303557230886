import React, { useEffect } from 'react';
import { useStytch } from '@stytch/react';

export const RefreshSession = () => {
  const stytchClient = useStytch();

  useEffect(() => {
    const refresh = () => {
      if (stytchClient.session.getSync()) {
        stytchClient.session.authenticate({
          session_duration_minutes: parseInt(
            process.env.REACT_APP_LOGIN_SESSION_MINUTES
          ),
        });
      }
    };
    // JWT is 5 minutes always, so refresh session every 4 minutes
    let interval = setInterval(refresh, 4 * 60 * 1000);
    return () => clearInterval(interval);
  }, [stytchClient]);

  return <></>;
};
