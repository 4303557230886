import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SponsorPhoneField({
  value,
  error,
  setValue,
  setError,
  ...rest
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (newValue === '') {
      setError('Phone number cannot be blank');
    } else if (
      !/^\+?((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){6,20}\d$/.test(
        newValue
      )
    ) {
      setError('Phone number is not a valid');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          {...rest}
          label='Phone Number'
          id='phone'
          aria-describedby='phone-helper-text'
          onChange={handleChange}
          error={Boolean(error || value === '')}
          value={value}
          variant='standard'
          required
        />
        {error ? (
          <FormHelperText id='phone-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='phone-helper-text'>
            Enter the sponsor contact phone number
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
