import api from '../apis/api';

export const adminGetSponsorDealHistory = async (sponsor_id) => {
  const resp = await api.get(`/admin/sponsor/${sponsor_id}/history`);
  return resp.data;
};

export const adminGetSponsorClosedDeal = async (sponsor_id, deal_id) => {
  const resp = await api.get(`/admin/sponsor/${sponsor_id}/history/${deal_id}`);
  return resp.data;
};

export const adminCreateSponsorClosedDeal = async (sponsor_id, values) => {
  const resp = await api.post(`/admin/sponsor/${sponsor_id}/history`, values);
  return resp.data;
};

export const adminUpdateSponsorClosedDeal = async (
  sponsor_id,
  deal_id,
  values
) => {
  const resp = await api.put(
    `/admin/sponsor/${sponsor_id}/history/${deal_id}`,
    values
  );
  return resp.data;
};

export const adminDeleteSponsorClosedDeal = async (sponsor_id, deal_id) => {
  const resp = await api.delete(
    `/admin/sponsor/${sponsor_id}/history/${deal_id}`
  );
  return resp.data;
};
