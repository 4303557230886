import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function DealDebtField({ value, error, setValue, setError }) {
  const handleChange = (values) => {
    setValue(values.floatValue);
  };
  return (
    <Box>
      <FormControl fullWidth>
        <NumericFormat
          value={value}
          suffix='%'
          onValueChange={handleChange}
          customInput={TextField}
          label='Debt Ratio'
          id='debt'
          aria-describedby='debt-helper-text'
          error={Boolean(error)}
          variant='standard'
          thousandSeparator=','
        />
        {error ? (
          <FormHelperText id='debt-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='debt-helper-text'>
            Enter the projected Debt Ratio
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
