import React from 'react';

import { FormControlLabel, Switch, Box, FormHelperText } from '@mui/material';

export default function InvestorDetailsProfessionalField({
  value,
  error,
  setValue,
  setError,
}) {
  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
        }
        label='I am an Investment Professional.'
      />
      <FormHelperText>
        You meet the SEC requirements for
        <a
          href='https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor'
          target='_blank'
          rel='noreferrer'
        >
          investment professional
        </a>
        .
      </FormHelperText>
    </Box>
  );
}
