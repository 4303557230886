import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormErrorDispatch } from '../../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../../providers/actionTypes';
import round from '../../../../util/round';
import DealExecSummaryField from './DealExecSummaryField';
import DealBusinessPlanField from './DealBusinessPlanField';
import DealPropertyDescriptionField from './DealPropertyDescriptionField';
import DealMarketAnalysisField from './DealMarketAnalysisField';
import DealOtherNarrativeField from './DealOtherNarrativeField';
import DealMarketPopulationField from './DealMarketPopulationField';
import DealMarketMedianIncomeField from './DealMarketMedianIncomeField';
import DealMarketMedianPropertyValueField from './DealMarketMedianPropertyValueField';
import DealMarketMedianAgeField from './DealMarketMedianAgeField';
import DealMarketUnemploymentRateField from './DealMarketUnemploymentRateField';

function DealNarrative({ deal, setValues }) {
  const formErrorDispatch = useFormErrorDispatch();
  const [exec_summary, setExecSummary] = useState(deal?.exec_summary || '');
  const [business_plan, setBusinessPlan] = useState(deal?.business_plan || '');
  const [property_description, setPropertyDescription] = useState(
    deal?.property_description || ''
  );
  const [market_analysis, setMarketAnalysis] = useState(
    deal?.market_analysis || ''
  );
  const [other_narrative, setOtherNarrative] = useState(
    deal?.other_narrative || ''
  );
  const [market_population, setMarketPopulation] = useState(
    deal?.market_population || ''
  );
  const [market_median_income, setMedianIncome] = useState(
    deal?.market_median_income || ''
  );
  const [market_median_property_value, setMedianPropertyValue] = useState(
    deal?.market_median_property_value || ''
  );
  const [market_median_age, setMedianAge] = useState(
    deal?.market_median_age || ''
  );
  const [market_unemployment_rate, setUnemploymentRate] = useState(
    round(deal?.market_unemployment_rate * 100.0, 2) || ''
  );

  useEffect(() => {
    if (false) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [formErrorDispatch]);

  useEffect(() => {
    setValues({
      exec_summary,
      business_plan,
      property_description,
      market_analysis,
      other_narrative,
      market_population: Boolean(market_population && market_population !== '')
        ? round(market_population, 0)
        : null,
      market_median_income: Boolean(
        market_median_income && market_median_income !== ''
      )
        ? round(market_median_income, 2)
        : null,
      market_median_property_value: Boolean(
        market_median_property_value && market_median_property_value !== ''
      )
        ? round(market_median_property_value, 2)
        : null,
      market_median_age: Boolean(market_median_age && market_median_age !== '')
        ? round(market_median_age, 2)
        : null,
      market_unemployment_rate: Boolean(
        market_unemployment_rate && market_unemployment_rate !== ''
      )
        ? round(market_unemployment_rate / 100.0, 4)
        : null,
    });
  }, [
    setValues,
    exec_summary,
    business_plan,
    property_description,
    market_analysis,
    other_narrative,
    market_population,
    market_median_income,
    market_median_property_value,
    market_median_age,
    market_unemployment_rate,
  ]);

  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={4}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 800,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Deal Narrative</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealExecSummaryField
                  value={exec_summary}
                  setValue={setExecSummary}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealPropertyDescriptionField
                  value={property_description}
                  setValue={setPropertyDescription}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealBusinessPlanField
                  value={business_plan}
                  setValue={setBusinessPlan}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Market</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealMarketAnalysisField
                  value={market_analysis}
                  setValue={setMarketAnalysis}
                />
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealMarketPopulationField
                  value={market_population}
                  setValue={setMarketPopulation}
                />
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealMarketMedianIncomeField
                  value={market_median_income}
                  setValue={setMedianIncome}
                />
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealMarketMedianPropertyValueField
                  value={market_median_property_value}
                  setValue={setMedianPropertyValue}
                />
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealMarketMedianAgeField
                  value={market_median_age}
                  setValue={setMedianAge}
                />
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealMarketUnemploymentRateField
                  value={market_unemployment_rate}
                  setValue={setUnemploymentRate}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Other</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealOtherNarrativeField
                  value={other_narrative}
                  setValue={setOtherNarrative}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DealNarrative;
