import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
});

api.interceptors.request.use((config) => {
  // add token to request headers
  const token = document.cookie
    .split('; ')
    .find((row) => row.startsWith('stytch_session_jwt='))
    ?.split('=')[1];
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

export default api;
