import React, { useEffect, useState, useMemo, useCallback } from 'react';

import {
  Container,
  Typography,
  Toolbar,
  Stack,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import DeleteIcon from '@mui/icons-material/Delete';

import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../providers/UserContext';
import { deleteSponsorDeal, getSponsorDeals } from '../../actions/sponsorDeal';
import LoadingMask from '../shared/LoadingMask';
import { getSponsor } from '../../actions/sponsor';
import STATUSES from '../shared/statuses';

function Deals() {
  const user = useUser();
  const navigate = useNavigate();
  const [sponsor, setSponsor] = useState(undefined);
  const [deals, setDeals] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [transitioningDeals, setTransitioningDeals] = useState([]);

  useEffect(() => {
    if (sponsor && sponsor.status === STATUSES.ACTIVE) {
      if (!deals) {
        getSponsorDeals(sponsor.entity_id).then((payload) => {
          setDeals(payload);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [sponsor, deals]);

  useEffect(() => {
    if (user.primary_sponsor && !sponsor) {
      getSponsor(user.primary_sponsor).then((payload) => {
        setSponsor(payload);
      });
    }
  }, [user, sponsor]);

  const removeDeal = useCallback(
    async (deal_id) => {
      setTransitioningDeals((prevTransitioningDeals) => [
        ...prevTransitioningDeals,
        deal_id,
      ]);
      await deleteSponsorDeal(sponsor.entity_id, deal_id);
      setDeals((prevDeals) =>
        prevDeals.filter((deal) => deal.entity_id !== deal_id)
      );
      setTransitioningDeals((prevTransitioningDeals) =>
        prevTransitioningDeals.filter(
          (transitioning_deal_id) => transitioning_deal_id !== deal_id
        )
      );
    },
    [sponsor]
  );

  const renderStatus = useCallback(
    (params) => {
      if (transitioningDeals.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      return <Typography variant='body2'>{params.row.status}</Typography>;
    },
    [transitioningDeals]
  );

  const renderName = useCallback((params) => {
    return (
      <Typography component={Link} to={`/deal/${params.id}`} variant='body2'>
        {params.row.name}
      </Typography>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 2,
        renderCell: renderName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: 'offer_start_date',
        headerName: 'Start Date',
        type: 'string',
        // renderCell: renderStatus,
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: 'offer_end_date',
        headerName: 'End Date',
        type: 'string',
        // renderCell: renderStatus,
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        type: 'string',
        // renderCell: renderCreatedDate,
        minWidth: 150,
        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              onClick={() =>
                navigate({
                  pathname: '/deals/edit',
                  search: `?deal=${params.id}`,
                })
              }
              showInMenu
            />
          );
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Remove'
              onClick={() => removeDeal(params.id)}
              showInMenu
            />
          );
          return actions;
        },
      },
    ],
    [renderName, renderStatus, navigate, removeDeal]
  );

  if (user.entity_id && !user.primary_sponsor) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                You must create a sponsor to list deals.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                component={Link}
                to='/account'
                variant='contained'
                color='primary'
              >
                My Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (sponsor && sponsor.status !== STATUSES.ACTIVE) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                Only accounts with active sponsors can list deals.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                component={Link}
                to='/account'
                variant='contained'
                color='primary'
              >
                My Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Container sx={{ padding: '15px' }}>
      <Grid container spacing={4}>
        <Grid xs={12} md={12} lg={12}>
          <Toolbar>
            <Stack spacing={2} direction='row' alignItems='center'>
              <Typography variant='h4'>{sponsor.name} Deals</Typography>
              <Button component={Link} to='/deals/edit' color='primary'>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <AddCircleOutlineIcon />
                  <Typography variant='button' display='block'>
                    Create
                  </Typography>
                </Stack>
              </Button>

              <Button
                color='inherit'
                disabled={selectedDeals.length !== 1}
                onClick={() => {
                  navigate({
                    pathname: '/deals/edit',
                    search: `?deal=${selectedDeals[0]}`,
                  });
                }}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <EditIcon />
                  <Typography variant='button' display='block'>
                    Edit
                  </Typography>
                </Stack>
              </Button>

              <Button
                color='inherit'
                disabled={!selectedDeals.length}
                onClick={async () => {
                  await Promise.all(
                    [].concat(
                      selectedDeals.map(async (deal) => {
                        await removeDeal(deal);
                      })
                    )
                  );
                  setSelectedDeals([]);
                }}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <DeleteIcon />
                  <Typography variant='button' display='block'>
                    Delete
                  </Typography>
                </Stack>
              </Button>
            </Stack>
          </Toolbar>
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  autoHeight
                  checkboxSelection
                  getRowId={(row) => row.entity_id}
                  columns={columns}
                  rows={deals || []}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectedDeals(newSelectionModel);
                  }}
                  onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectedDeals(newSelectionModel);
                  }}
                  rowSelectionModel={selectedDeals}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Deals;
