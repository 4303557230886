import React, { useState, useEffect } from 'react';

import {
  Container,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Backdrop,
  CircularProgress,
  Stack,
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';

import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useUser, useUserDispatch } from '../../providers/UserContext';
import { FormErrorProvider } from '../../providers/FormErrorContext';
import AccountContactForm from './AccountContactForm';
import InvestorDetailsForm from './InvestorDetailsForm';
import { USER_FETCH } from '../../providers/actionTypes';
import AccountActions from './AccountActions';
import FormUpdateButton from './FormUpdateButton';
import { updateUser } from '../../actions/user';
import AccountSponsor from './AccountSponsor';

function Account() {
  const user = useUser();
  const [revision, setRevision] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userUpdate, setUserUpdate] = useState({});

  const userDispatch = useUserDispatch();

  const handleUserUpdate = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({ ...user, ...userUpdate }).filter(
        ([k, v]) => v !== undefined && v !== ''
      )
    );
    const updated_user = await updateUser(payload);
    userDispatch({ type: USER_FETCH, payload: updated_user });
    setLoading(false);
  };

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [user]);

  const sections = [
    {
      id: 'contact',
      label: 'Contact Info',
      component: (
        <FormErrorProvider>
          <AccountContactForm
            key={`contact_info_${revision}`}
            setValues={setUserUpdate}
          />
        </FormErrorProvider>
      ),
      icon: <PersonOutlineOutlinedIcon />,
      actions: (
        <FormErrorProvider>
          <FormUpdateButton
            handleUpdate={handleUserUpdate}
            loading={loading}
            label='Update'
          />
        </FormErrorProvider>
      ),
    },
    {
      id: 'investor',
      label: 'Investor Details',
      component: (
        <FormErrorProvider>
          <InvestorDetailsForm
            key={`investor_details_${revision}`}
            setValues={setUserUpdate}
          />
        </FormErrorProvider>
      ),
      icon: <AccountBalanceOutlinedIcon />,
      actions: (
        <FormErrorProvider>
          <FormUpdateButton
            handleUpdate={handleUserUpdate}
            loading={loading}
            label='Update'
          />
        </FormErrorProvider>
      ),
    },
    {
      id: 'sponsor',
      label: user.primary_sponsor ? 'My Sponsor' : 'Enable Deal Listing',
      component: <AccountSponsor key={`sponsor_${revision}`} />,
      icon: <BusinessOutlinedIcon />,
    },
    {
      id: 'account',
      label: 'Account',
      component: <AccountActions key={`actions_${revision}`} />,
      icon: <SettingsOutlinedIcon />,
    },
  ];

  if (!user.entity_id) {
    return (
      <Backdrop open={!user.entity_id}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={4}>
          <Grid xs={12} md={12} lg={12}>
            <Typography variant='h4'>My Account</Typography>
          </Grid>
          <Grid xs={12} md={4} lg={4}>
            {/* <Paper> */}
            <List>
              {sections.map((section, index) => (
                <ListItemButton
                  onClick={() => setSelectedIndex(index)}
                  key={section.id}
                  selected={index === selectedIndex}
                >
                  <ListItemIcon>{section.icon}</ListItemIcon>
                  <ListItemText primary={section.label} />
                </ListItemButton>
              ))}
            </List>
            {/* </Paper> */}
          </Grid>
          <Grid xs={12} md={8} lg={8}>
            <Stack direction='column'>
              {sections[selectedIndex].component}
              {sections[selectedIndex].actions ? (
                <Box width='100%' padding='15px' paddingLeft={'30px'}>
                  {sections[selectedIndex].actions}
                </Box>
              ) : undefined}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Account;
