import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stepper, Step, StepLabel, Box, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useFormError } from '../../../providers/FormErrorContext';

function DealDesktopWizard({ steps, onNext }) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formError = useFormError();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setLoading(true);
    await onNext();
    setLoading(false);
    if (activeStep === steps.length - 1) {
      navigate('/deals');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = async () => {
    if (activeStep === 0) {
      navigate('/deals');
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!steps[activeStep].optional) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel>{step.name}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {steps[activeStep].component}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button color='inherit' onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {steps[activeStep].optional && (
          <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        <Tooltip
          title={Boolean(formError.error) ? 'Fix form errors to continue.' : ''}
        >
          <span>
            <LoadingButton
              onClick={handleNext}
              loading={loading}
              disabled={Boolean(formError.error)}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </LoadingButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default DealDesktopWizard;
