import React, { useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { useMetadata } from '../../../providers/MetadataContext';

function InvestorDetailsWithdrawLikelihoodField({ value, setValue }) {
  const metadata = useMetadata();
  const [selected, setSelected] = useState(
    (metadata?.user?.lists?.withdraw_likelihoods || []).find(
      (option) => option.entity_id === value
    )
  );

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue?.entity_id);
  };

  return (
    <Box>
      <Autocomplete
        id='withdraw-likelihood-field'
        options={metadata?.user?.lists?.withdraw_likelihoods || []}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        renderInput={(params) => (
          <TextField {...params} label='Withdraw Likelihood' />
        )}
      />
    </Box>
  );
}

export default InvestorDetailsWithdrawLikelihoodField;
