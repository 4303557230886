import React from 'react';

import { FormControlLabel, Switch, Box, FormHelperText } from '@mui/material';

export default function DealAccreditedOnlyField({
  value,
  error,
  setValue,
  setError,
}) {
  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
        }
        label='Accredited Investors Only'
      />
      <FormHelperText>
        Only Accredited Investors can invest in this deal.
      </FormHelperText>
    </Box>
  );
}
