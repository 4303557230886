import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Toolbar,
  Stack,
  Box,
  Tooltip,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import ClosedDealNameField from './ClosedDealNameField';
import ClosedDealAssetTypeField from './ClosedDealAssetTypeField';
import ClosedDealInceptionDateField from './ClosedDealInceptionDateField';
import ClosedDealAmountField from './ClosedDealAmountField';
import ClosedDealTotalCapitalField from './ClosedDealTotalCapitalField';
import ClosedDealRealizationDateField from './ClosedDealRealizationDateField';
import ClosedDealTotalProceedsField from './ClosedDealTotalProceedsField';
import ClosedDealRealizedIRRField from './ClosedDealRealizedIRRField';
import ClosedDealRealizedMultipleField from './ClosedDealRealizedMultipleField';
import round from '../../../../../../util/round';
import {
  createSponsorClosedDeal,
  updateSponsorClosedDeal,
} from '../../../../../../actions/sponsorDealHistory';
import dayjs from 'dayjs';
import {
  adminCreateSponsorClosedDeal,
  adminUpdateSponsorClosedDeal,
} from '../../../../../../actions/adminSponsorDealHistory';

export default function ClosedDealModal({
  open,
  admin,
  handleClose,
  sponsor_id,
  deal,
}) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(deal?.name || '');
  const [name_error, setNameError] = useState(false);
  const [asset_sub_type, setAssetSubType] = useState(deal?.asset_sub_type);
  const [inception_date, setInceptionDate] = useState(
    deal?.inception_date || ''
  );
  const [inception_date_error, setInceptionDateError] = useState(false);
  const [amount, setAmount] = useState(deal?.amount || '');
  const [total_capital, setTotalCapital] = useState(deal?.total_capital || '');
  const [realization_date, setRealizationDate] = useState(
    deal?.realization_date || ''
  );
  const [realization_date_error, setRealizationDateError] = useState(false);
  const [total_proceeds, setTotalProceeds] = useState(
    deal?.total_proceeds || ''
  );
  const [realized_irr, setRealizedIrr] = useState(
    round(deal?.realized_irr * 100.0, 2) || ''
  );
  const [realized_multiple, setRealizedMultiple] = useState(
    deal?.realized_multiple || ''
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = Object.fromEntries(
        Object.entries({
          ...(deal || {}),
          name,
          asset_sub_type,
          inception_date,
          amount: Boolean(amount && amount !== '') ? round(amount, 0) : null,
          total_capital: Boolean(total_capital && total_capital !== '')
            ? round(total_capital, 0)
            : null,
          realization_date,
          total_proceeds: Boolean(total_proceeds && total_proceeds !== '')
            ? round(total_proceeds, 0)
            : null,
          realized_irr: Boolean(realized_irr && realized_irr !== '')
            ? round(realized_irr / 100.0, 4)
            : null,
          realized_multiple: Boolean(
            realized_multiple && realized_multiple !== ''
          )
            ? round(realized_multiple, 2)
            : null,
        }).filter(([k, v]) => v !== undefined && v !== '')
      );
      let result;
      if (deal?.entity_id) {
        if (admin) {
          result = await adminUpdateSponsorClosedDeal(
            sponsor_id,
            deal.entity_id,
            payload
          );
        } else {
          result = await updateSponsorClosedDeal(
            sponsor_id,
            deal.entity_id,
            payload
          );
        }
      } else {
        if (admin) {
          result = await adminCreateSponsorClosedDeal(sponsor_id, payload);
        } else {
          result = await createSponsorClosedDeal(sponsor_id, payload);
        }
      }
      setLoading(false);
      handleClose(result);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs>
            {deal?.entity_id ? `Edit ${deal.name}` : 'New Deal'}
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            maxWidth: 400,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <ClosedDealNameField
                value={name}
                error={name_error}
                setValue={setName}
                setError={setNameError}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ClosedDealAssetTypeField
                value={asset_sub_type}
                setValue={setAssetSubType}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ClosedDealInceptionDateField
                value={inception_date}
                error={inception_date_error}
                setValue={setInceptionDate}
                setError={setInceptionDateError}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ClosedDealAmountField value={amount} setValue={setAmount} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ClosedDealTotalCapitalField
                value={total_capital}
                setValue={setTotalCapital}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ClosedDealRealizationDateField
                value={realization_date}
                error={realization_date_error}
                setValue={setRealizationDate}
                setError={setRealizationDateError}
                minDate={dayjs(inception_date).add(1, 'day')}
              />
            </Grid>
            {Boolean(realization_date) ? (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <ClosedDealTotalProceedsField
                    value={total_proceeds}
                    setValue={setTotalProceeds}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ClosedDealRealizedIRRField
                    value={realized_irr}
                    setValue={setRealizedIrr}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ClosedDealRealizedMultipleField
                    value={realized_multiple}
                    setValue={setRealizedMultiple}
                  />
                </Grid>
              </>
            ) : undefined}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Toolbar>
          <Stack spacing={2} direction='row'>
            <Tooltip
              title={
                Boolean(
                  name === '' ||
                    name_error ||
                    !Boolean(asset_sub_type) ||
                    amount === '' ||
                    total_capital === '' ||
                    inception_date_error ||
                    realization_date_error
                )
                  ? 'Fix form errors to continue.'
                  : ''
              }
            >
              <span>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={Boolean(
                    name === '' ||
                      name_error ||
                      !Boolean(asset_sub_type) ||
                      amount === '' ||
                      total_capital === '' ||
                      inception_date_error ||
                      realization_date_error
                  )}
                >
                  {deal?.entity_id ? `Update` : 'Add'}
                </LoadingButton>
              </span>
            </Tooltip>

            <Button
              variant='outlined'
              onClick={() => handleClose()}
              disabled={loading}
            >
              Cancel
            </Button>
          </Stack>
        </Toolbar>
      </DialogActions>
    </Dialog>
  );
}
