import React from 'react';
import Markdown from 'react-markdown';

import { Typography, Stack, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import round from '../../../util/round';

function DealMarketAnalysis({ deal }) {
  return (
    <Grid container spacing={1}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Metrics</Typography>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>Metro Population</Typography>
          <Typography variant='body1'>
            {new Intl.NumberFormat('en-US').format(deal.market_population)}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>Median Income</Typography>
          <Typography variant='body1'>
            ${new Intl.NumberFormat('en-US').format(deal.market_median_income)}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>Median Property Value</Typography>
          <Typography variant='body1'>
            $
            {new Intl.NumberFormat('en-US').format(
              deal.market_median_property_value
            )}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>Median Age</Typography>
          <Typography variant='body1'>{deal.market_median_age}</Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>Unemployment Rate</Typography>
          <Typography variant='body1'>
            {round(deal.market_unemployment_rate * 100, 4)}%
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Analysis</Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='body1'>
          <Markdown>{deal.market_analysis}</Markdown>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DealMarketAnalysis;
