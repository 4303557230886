import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SponsorLinkedinField({
  value,
  error,
  setValue,
  setError,
  ...rest
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (
      newValue !== '' &&
      !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
        String(newValue).toLowerCase()
      )
    ) {
      setError(
        'LinkedIn page is not a valid URL (must include http(s):// prefix)'
      );
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          {...rest}
          label='Linkedin Page'
          id='linkedin'
          aria-describedby='linkedin-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          variant='standard'
        />
        {error ? (
          <FormHelperText id='linkedin-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='linkedin-helper-text'>
            Enter the LinkedIn page URL
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
