import React, { useState } from 'react';

import { Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';

import { getUser } from '../../../actions/user';
import { useUserDispatch } from '../../../providers/UserContext';
import { USER_FETCH } from '../../../providers/actionTypes';
import { createSponsor } from '../../../actions/sponsor';
import SponsorNameField from '../../Sponsors/SponsorForm/SponsorBasicInfo/SponsorNameField';

function ActivateSponsor() {
  const dispatch = useUserDispatch();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [name_error, setNameError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    await createSponsor({ name });
    const user = await getUser();
    dispatch({ type: USER_FETCH, payload: user });

    setLoading(false);
  };

  return (
    <Grid container spacing={4}>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Create a Sponsor</Typography>
        <Typography variant='body1'>
          In order to list deals, you must create a Sponsor organization. Once
          all information is submitted, Invest On Main will verify your
          organization and enable deal listing.
        </Typography>
        <br />
        <Typography variant='body1'>
          To get started, enter the DBA of the Sponsor organization you
          represent and continue.
        </Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <SponsorNameField
          value={name}
          error={name_error}
          setValue={setName}
          setError={setNameError}
        />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Tooltip
          title={
            Boolean(name_error || name === '')
              ? 'Fix form errors to continue.'
              : ''
          }
        >
          <span>
            <LoadingButton
              variant='contained'
              color='primary'
              loading={loading}
              onClick={handleSubmit}
              disabled={name_error || name === ''}
            >
              {'Activate'}
            </LoadingButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default ActivateSponsor;
