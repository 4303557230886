import api from '../apis/api';

export const getSponsorDeals = async (sponsor_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}/deal`);
  return resp.data;
};

export const getSponsorDeal = async (sponsor_id, deal_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}/deal/${deal_id}`);
  return resp.data;
};

export const createSponsorDeal = async (sponsor_id, values) => {
  const resp = await api.post(`/sponsor/${sponsor_id}/deal`, values);
  return resp.data;
};

export const updateSponsorDeal = async (sponsor_id, deal_id, values) => {
  const resp = await api.put(`/sponsor/${sponsor_id}/deal/${deal_id}`, values);
  return resp.data;
};

export const deleteSponsorDeal = async (sponsor_id, deal_id) => {
  const resp = await api.delete(`/sponsor/${sponsor_id}/deal/${deal_id}`);
  return resp.data;
};
