import React, { useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import AdminSponsorTab from './SponsorTab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function AdminSponsors() {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='Sponsors'
      >
        <Tab label='Pending' />
        <Tab label='Active' />
        <Tab label='Draft' />
      </Tabs>
      <CustomTabPanel value={tab} index={0}>
        <AdminSponsorTab status='pending' />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <AdminSponsorTab status='active' />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={2}>
        <AdminSponsorTab status='draft' />
      </CustomTabPanel>
    </>
  );
}

export default AdminSponsors;
