import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  //   typography: {
  //     fontWeight: 400,
  //     fontFamily: 'Inter',
  //     caption: {
  //       fontSize: '18px',
  //     },
  //     body1: {
  //       fontSize: '12px',
  //       fontWeight: 500,
  //     },
  //     body2: {
  //       fontSize: '12px',
  //       fontWeight: 400,
  //     },
  //     h4: {
  //       fontSize: '28px',
  //       fontWeight: 500,
  //       lineHeight: '36px',
  //     },
  //     h6: {
  //       fontSize: '24px',
  //       lineHeight: '30px',
  //     },
  //   },
});

export default theme;
