import React, { useEffect, useState } from 'react';

import { Box, Typography, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DealNameField from './DealNameField';
import { useFormErrorDispatch } from '../../../../providers/FormErrorContext';
import {
  FORM_CLEAR_ERROR,
  FORM_SET_ERROR,
} from '../../../../providers/actionTypes';
import round from '../../../../util/round';
import DealAddressField from './DealAddressField';
import DealAddress2Field from './DealAddress2Field';
import DealCityField from './DealCityField';
import DealStateField from './DealStateField';
import DealPostalCodeField from './DealPostalCodeField';
import DealCountryField from './DealCountryField';
import DealAccreditedOnlyField from './DealAccreditedOnlyField';
import Deal1031EligibleField from './Deal1031EligibleField';
import DealOpportunityZoneField from './DealOpportunityZoneField';
import DealIRAField from './DealIRAField';
import DealInvestmentStrategyField from './DealInvestmentStrategyField';
import DealAssetTypeField from './DealAssetTypeField';
import DealRegionField from './DealRegionField';
import DealCashYieldField from './DealCashYieldField';
import DealNOIField from './DealNOIField';
import DealDebtField from './DealDebtField';
import DealIRRMinField from './DealIRRMinField';
import DealIRRMaxField from './DealIRRMaxField';
import DealMultipleMinField from './DealMultipleMinField';
import DealMultipleMaxField from './DealMultipleMaxField';
import DealOfferStartDateField from './DealOfferStartDateField';
import dayjs from 'dayjs';
import DealOfferEndDateField from './DealOfferEndDateField';
import DealInvestmentPeriodField from './DealInvestmentPeriodField';
import DealMinimumInvestmentField from './DealMinimumInvestmentField';
import DealInvestmentStructureField from './DealInvestmentStructureField';
import DealDistributionFrequencyField from './DealDistributionFrequencyField';
import DealInvestmentBenefitsField from './DealInvestmentBenefitsField';
import DealDescriptionField from './DealDescriptionField';
import DealSeniorDebtField from './DealSeniorDebtField';
import DealJuniorDebtField from './DealJuniorDebtField';
import DealEquityField from './DealEquityField';
import DealOtherCostsField from './DealOtherCostsField';
import DealAmountField from './DealAmountField';

function DealOverview({ deal, setValues }) {
  const [name, setName] = useState(deal?.name || '');
  const [name_error, setNameError] = useState(false);
  const [description, setDescription] = useState(deal?.description || '');
  const [offer_start_date, setOfferStartDate] = useState(
    deal?.offer_start_date || ''
  );
  const [offer_end_date, setOfferEndDate] = useState(
    deal?.offer_end_date || ''
  );
  const [offer_date_error, setOfferDateError] = useState(false);
  const [address, setAddress] = useState(deal?.address || '');
  const [address_error, setAddressError] = useState(false);
  const [address2, setAddress2] = useState(deal?.address2 || '');
  const [address2_error, setAddress2Error] = useState(false);
  const [city, setCity] = useState(deal?.city || '');
  const [city_error, setCityError] = useState(false);
  const [state, setState] = useState(deal?.state || '');
  const [state_error, setStateError] = useState(false);
  const [postal_code, setPostalCode] = useState(deal?.postal_code || '');
  const [postal_code_error, setPostalCodeError] = useState(false);
  const [country, setCountry] = useState(deal?.country || '');
  const [country_error, setCountryError] = useState(false);
  const [accredited_only, setAccreditedOnly] = useState(
    deal?.accredited_only || false
  );
  const [ten_thirty_one, setTenThirtyOne] = useState(
    deal?.ten_thirty_one || false
  );
  const [opportunity_zone, setOpportunityZone] = useState(
    deal?.opportunity_zone || false
  );
  const [ira, setIra] = useState(deal?.ira || false);
  const [investment_strategy, setInvestmentStrategy] = useState(
    deal?.investment_strategy || ''
  );
  const [investment_benefits, setInvestmentBenefits] = useState(
    deal?.investment_benefits || []
  );
  const [asset_sub_type, setAssetSubType] = useState(deal?.asset_sub_type);
  const [region, setRegion] = useState(deal?.region);
  const [cash_yield, setCashYield] = useState(
    round(deal?.cash_yield * 100.0, 2) || ''
  );
  const [noi, setNoi] = useState(deal?.noi || '');
  const [debt, setDebt] = useState(round(deal?.debt * 100.0, 2) || '');
  const [investment_period, setInvestmentPeriod] = useState(
    deal?.investment_period || ''
  );
  const [investment_period_error, setInvestmentPeriodError] = useState(false);
  const [minimum_investment, setMinimumInvestment] = useState(
    deal?.minimum_investment || ''
  );
  const [irr_min, setIrrMin] = useState(round(deal?.irr_min * 100.0, 2) || '');
  const [irr_max, setIrrMax] = useState(round(deal?.irr_max * 100.0, 2) || '');
  const [multiple_min, setMultipleMin] = useState(deal?.multiple_min || '');
  const [multiple_max, setMultipleMax] = useState(deal?.multiple_max || '');
  const [investment_structure, setInvestmentStructure] = useState(
    deal?.investment_structure
  );
  const [distribution_frequency, setDistributionFrequency] = useState(
    deal?.distribution_frequency
  );
  const [senior_debt, setSeniorDebt] = useState(deal?.senior_debt || '');
  const [junior_debt, setJuniorDebt] = useState(deal?.junior_debt || '');
  const [equity, setEquity] = useState(deal?.equity || '');
  const [other_costs, setOtherCosts] = useState(deal?.other_costs || '');
  const [amount, setAmount] = useState(deal?.amount || '');

  const [purchase_price, setPurchasePrice] = useState(
    (senior_debt || 0) + (junior_debt || 0) + (equity || 0)
  );
  const [deal_total, setDealTotal] = useState(
    (senior_debt || 0) + (junior_debt || 0) + (equity || 0) + (other_costs || 0)
  );
  const formErrorDispatch = useFormErrorDispatch();

  useEffect(() => {
    if (
      name === '' ||
      country === '' ||
      offer_start_date === '' ||
      offer_end_date === '' ||
      description === '' ||
      amount === '' ||
      name_error ||
      offer_date_error ||
      investment_period_error ||
      address_error ||
      city_error ||
      state_error ||
      postal_code_error ||
      country_error
    ) {
      formErrorDispatch({ type: FORM_SET_ERROR, payload: true });
    } else {
      formErrorDispatch({ type: FORM_CLEAR_ERROR });
    }
  }, [
    formErrorDispatch,
    name,
    description,
    country,
    offer_start_date,
    offer_end_date,
    amount,
    name_error,
    offer_date_error,
    investment_period_error,
    address_error,
    city_error,
    state_error,
    postal_code_error,
    country_error,
  ]);

  useEffect(() => {
    setValues({
      name,
      description,
      offer_start_date,
      offer_end_date,
      accredited_only,
      ten_thirty_one,
      opportunity_zone,
      ira,
      investment_strategy,
      asset_sub_type,
      region,
      investment_benefits,
      senior_debt: Boolean(senior_debt && senior_debt !== '')
        ? round(senior_debt, 0)
        : null,
      junior_debt: Boolean(junior_debt && junior_debt !== '')
        ? round(junior_debt, 0)
        : null,
      equity: Boolean(equity && equity !== '') ? round(equity, 0) : null,
      other_costs: Boolean(other_costs && other_costs !== '')
        ? round(other_costs, 0)
        : null,
      amount: Boolean(amount && amount !== '') ? round(amount, 0) : null,
      cash_yield: Boolean(cash_yield && cash_yield !== '')
        ? round(cash_yield / 100.0, 4)
        : null,
      noi: Boolean(noi && noi !== '') ? round(noi, 2) : null,
      debt: Boolean(debt && debt !== '') ? round(debt / 100.0, 4) : null,
      irr_min: Boolean(irr_min && irr_min !== '')
        ? round(irr_min / 100.0, 4)
        : null,
      irr_max: Boolean(irr_max && irr_max !== '')
        ? round(irr_max / 100.0, 4)
        : null,
      multiple_min: Boolean(multiple_min && multiple_min !== '')
        ? round(multiple_min, 2)
        : null,
      multiple_max: Boolean(multiple_max && multiple_max !== '')
        ? round(multiple_max, 2)
        : null,
      investment_period: parseInt(investment_period),
      minimum_investment: Boolean(
        minimum_investment && minimum_investment !== ''
      )
        ? round(minimum_investment, 2)
        : null,
      investment_structure,
      distribution_frequency,
      address,
      address2,
      city,
      state,
      postal_code,
      country,
    });
  }, [
    setValues,
    name,
    description,
    offer_start_date,
    offer_end_date,
    accredited_only,
    ten_thirty_one,
    opportunity_zone,
    ira,
    investment_strategy,
    asset_sub_type,
    region,
    investment_benefits,
    senior_debt,
    junior_debt,
    equity,
    other_costs,
    amount,
    cash_yield,
    noi,
    debt,
    irr_min,
    irr_max,
    multiple_min,
    multiple_max,
    investment_period,
    minimum_investment,
    investment_structure,
    distribution_frequency,
    address,
    address2,
    city,
    state,
    postal_code,
    country,
  ]);

  useEffect(() => {
    const senior_debt_new = Boolean(senior_debt && senior_debt !== '')
      ? round(senior_debt, 0)
      : 0;
    const junior_debt_new = Boolean(junior_debt && junior_debt !== '')
      ? round(junior_debt, 0)
      : 0;
    const equity_new = Boolean(equity && equity !== '') ? round(equity, 0) : 0;
    const other_costs_new = Boolean(other_costs && other_costs !== '')
      ? round(other_costs, 0)
      : 0;

    setPurchasePrice(senior_debt_new + junior_debt_new + equity_new);
    setDealTotal(
      senior_debt_new + junior_debt_new + equity_new + other_costs_new
    );
  }, [
    setPurchasePrice,
    setDealTotal,
    senior_debt,
    junior_debt,
    equity,
    other_costs,
  ]);

  return (
    <Box width='100%' padding='15px'>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 800,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <DealNameField
                  value={name}
                  error={name_error}
                  setValue={setName}
                  setError={setNameError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealDescriptionField
                  value={description}
                  setValue={setDescription}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealOfferStartDateField
                  value={offer_start_date}
                  error={offer_date_error}
                  minDate={dayjs()}
                  maxDate={dayjs(offer_end_date)}
                  setValue={setOfferStartDate}
                  setError={setOfferDateError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealOfferEndDateField
                  value={offer_end_date}
                  error={offer_date_error}
                  minDate={dayjs(offer_start_date).add(1, 'day')}
                  maxDate={dayjs().add(5, 'year')}
                  setValue={setOfferEndDate}
                  setError={setOfferDateError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Deal Categories</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealAssetTypeField
                  value={asset_sub_type}
                  setValue={setAssetSubType}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealInvestmentStrategyField
                  value={investment_strategy}
                  setValue={setInvestmentStrategy}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealAccreditedOnlyField
                  value={accredited_only}
                  setValue={setAccreditedOnly}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <Deal1031EligibleField
                  value={ten_thirty_one}
                  setValue={setTenThirtyOne}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealOpportunityZoneField
                  value={opportunity_zone}
                  setValue={setOpportunityZone}
                />
              </Grid>

              <Grid xs={12} md={12} lg={6}>
                <DealIRAField value={ira} setValue={setIra} />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealInvestmentBenefitsField
                  value={investment_benefits}
                  setValue={setInvestmentBenefits}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealRegionField value={region} setValue={setRegion} />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Financial Overview</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h6'>Deal Structure</Typography>
              </Grid>

              <Grid xs={12} md={12} lg={12}>
                <DealAmountField value={amount} setValue={setAmount} />
              </Grid>

              <Grid xs={12} md={12} lg={6}>
                <DealInvestmentPeriodField
                  value={investment_period}
                  error={investment_period_error}
                  setValue={setInvestmentPeriod}
                  setError={setInvestmentPeriodError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealMinimumInvestmentField
                  value={minimum_investment}
                  setValue={setMinimumInvestment}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealInvestmentStructureField
                  value={investment_structure}
                  setValue={setInvestmentStructure}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <DealDistributionFrequencyField
                  value={distribution_frequency}
                  setValue={setDistributionFrequency}
                />
              </Grid>

              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h6'>Capital Stack</Typography>
              </Grid>
              <Grid
                container
                alignItems={'center'}
                spacing={4}
                xs={12}
                md={12}
                lg={12}
              >
                <Grid xs={8}>
                  <DealSeniorDebtField
                    value={senior_debt}
                    setValue={setSeniorDebt}
                  />
                </Grid>
                <Grid xs={4}>
                  <Typography variant='body1'>
                    {round(
                      ((Boolean(senior_debt && senior_debt !== '')
                        ? senior_debt
                        : 0) /
                        (purchase_price || 1)) *
                        100,
                      2
                    )}{' '}
                    %
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={'center'}
                spacing={4}
                xs={12}
                md={12}
                lg={12}
              >
                <Grid xs={8}>
                  <DealJuniorDebtField
                    value={junior_debt}
                    setValue={setJuniorDebt}
                  />
                </Grid>
                <Grid xs={4}>
                  <Typography variant='body1'>
                    {round(
                      ((Boolean(junior_debt && junior_debt !== '')
                        ? junior_debt
                        : 0) /
                        (purchase_price || 1)) *
                        100,
                      2
                    )}{' '}
                    %
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={'center'}
                spacing={4}
                xs={12}
                md={12}
                lg={12}
              >
                <Grid xs={8}>
                  <DealEquityField value={equity} setValue={setEquity} />
                </Grid>
                <Grid xs={4}>
                  <Typography variant='body1'>
                    {round(
                      ((Boolean(equity && equity !== '') ? equity : 0) /
                        (purchase_price || 1)) *
                        100,
                      2
                    )}{' '}
                    %
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={'center'}
                spacing={4}
                xs={12}
                md={12}
                lg={12}
              >
                <Grid xs={5}>
                  <Typography variant='body1'>Total Purchase Price:</Typography>
                </Grid>
                <Grid xs={3} textAlign={'right'}>
                  <Typography>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(purchase_price)}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant='body1'>
                    {round((purchase_price / (purchase_price || 1)) * 100, 2)} %
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                alignItems={'center'}
                spacing={4}
              >
                <Grid xs={8}>
                  <DealOtherCostsField
                    value={other_costs}
                    setValue={setOtherCosts}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={'center'}
                spacing={4}
                xs={12}
                md={12}
                lg={12}
              >
                <Grid xs={5}>
                  <Typography variant='body1'>Deal Total:</Typography>
                </Grid>
                <Grid xs={3} textAlign={'right'}>
                  <Typography>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(deal_total)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h6'>Ratios</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealCashYieldField
                  value={cash_yield}
                  setValue={setCashYield}
                />
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealNOIField value={noi} setValue={setNoi} />
              </Grid>
              <Grid xs={12} md={12} lg={4}>
                <DealDebtField value={debt} setValue={setDebt} />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <Typography variant='subtitle1'>Investor IRR</Typography>
                <Stack direction='row' spacing={2}>
                  <DealIRRMinField value={irr_min} setValue={setIrrMin} />
                  <Typography variant='body1'>-</Typography>
                  <DealIRRMaxField value={irr_max} setValue={setIrrMax} />
                </Stack>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <Typography variant='subtitle1'>Equity Multiple</Typography>
                <Stack direction='row' spacing={2}>
                  <DealMultipleMinField
                    value={multiple_min}
                    setValue={setMultipleMin}
                  />
                  <Typography variant='body1'>-</Typography>
                  <DealMultipleMaxField
                    value={multiple_max}
                    setValue={setMultipleMax}
                  />
                </Stack>
              </Grid>

              <Grid xs={12} md={12} lg={12}>
                <Typography variant='h5'>Property Address</Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealAddressField
                  value={address}
                  error={address_error}
                  setValue={setAddress}
                  setError={setAddressError}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <DealAddress2Field
                  value={address2}
                  error={address2_error}
                  setValue={setAddress2}
                  setError={setAddress2Error}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <DealCityField
                  value={city}
                  error={city_error}
                  setValue={setCity}
                  setError={setCityError}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <DealStateField
                  value={state}
                  error={state_error}
                  setValue={setState}
                  setError={setStateError}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <DealPostalCodeField
                  value={postal_code}
                  error={postal_code_error}
                  setValue={setPostalCode}
                  setError={setPostalCodeError}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <DealCountryField
                  value={country}
                  error={country_error}
                  setValue={setCountry}
                  setError={setCountryError}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DealOverview;
