import api from '../apis/api';

export const getDealMetadata = async () => {
  try {
    const resp = await api.get('/metadata/deal');
    return resp.data;
  } catch (error) {
    return {};
  }
};

export const getSponsorMetadata = async () => {
  try {
    const resp = await api.get('/metadata/sponsor');
    return resp.data;
  } catch (error) {
    return {};
  }
};

export const getUserMetadata = async () => {
  try {
    const resp = await api.get('/metadata/user');
    return resp.data;
  } catch (error) {
    return {};
  }
};
