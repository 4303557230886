import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function AccountAddress2Field({
  value,
  error,
  setValue,
  setError,
  ...rest
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    // if (newValue === '') {
    //   setError('Address cannot be blank');
    // } else {
    //   setError(false);
    // }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          {...rest}
          label='Address (Line 2)'
          id='address2'
          aria-describedby='address2-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          variant='standard'
        />
        {error ? (
          <FormHelperText id='address2-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='address2-helper-text'>
            Apartment, suite, unit, or building number
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
