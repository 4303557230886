import React from 'react';

import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SponsorExpertise from './SponsorExpertise';
import SponsorBanner from './SponsorBanner';
import SponsorTrackRecord from './SponsorTrackRecord';
import SponsorAbout from './SponsorAbout';

function SponsorMobile({ sponsor, fetchSponsor }) {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <SponsorBanner sponsor={sponsor} fetchSponsor={fetchSponsor} />
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <SponsorExpertise sponsor={sponsor} fetchSponsor={fetchSponsor} />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <SponsorTrackRecord sponsor={sponsor} fetchSponsor={fetchSponsor} />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <SponsorAbout sponsor={sponsor} fetchSponsor={fetchSponsor} />
      </Grid>
    </Grid>
  );
}

export default SponsorMobile;
