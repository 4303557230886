import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { Typography, Toolbar, Stack, Button, Grid } from '@mui/material';
// import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  deleteSponsorClosedDeal,
  getSponsorDealHistory,
} from '../../../../../actions/sponsorDealHistory';
import ClosedDealModal from './ClosedDealModal';
import LoadingMask from '../../../../shared/LoadingMask';
import round from '../../../../../util/round';
import {
  adminDeleteSponsorClosedDeal,
  adminGetSponsorDealHistory,
} from '../../../../../actions/adminSponsorDealHistory';
import STATUSES from '../../../../shared/statuses';

function SponsorDealHistory({ admin, sponsor, setValues }) {
  const [deal_history, setDealHistory] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [editingDeal, setEditingDeal] = useState(undefined);

  useEffect(() => {
    if (sponsor?.entity_id && !deal_history) {
      if (admin) {
        adminGetSponsorDealHistory(sponsor.entity_id).then((payload) => {
          setDealHistory(payload);
          setLoading(false);
        });
      } else {
        getSponsorDealHistory(sponsor.entity_id).then((payload) => {
          setDealHistory(payload);
          setLoading(false);
        });
      }
    } else {
      setLoading(false);
    }
  }, [admin, deal_history, sponsor]);

  const handleModalResult = useCallback((result) => {
    if (!result) {
      return;
    }
    setDealHistory((prev_deal_history) => {
      const index = prev_deal_history.findIndex(
        (deal) => deal.entity_id === result.entity_id
      );
      if (index === -1) {
        // new deal
        return [...prev_deal_history, result];
      } else {
        const new_deal_history = [...prev_deal_history];
        new_deal_history[index] = result;
        return new_deal_history;
      }
    });
  }, []);

  const handleDelete = useCallback(
    async (deal_id) => {
      if (admin) {
        await adminDeleteSponsorClosedDeal(sponsor.entity_id, deal_id);
      } else {
        await deleteSponsorClosedDeal(sponsor.entity_id, deal_id);
      }
      setDealHistory((prev_deal_history) => {
        const index = prev_deal_history.findIndex(
          (deal) => deal.entity_id === deal_id
        );
        const new_deal_history = [...prev_deal_history];
        if (index !== -1) {
          new_deal_history.splice(index, 1);
        }
        return new_deal_history;
      });
    },
    [admin, sponsor]
  );

  const currency_formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        width: 100,

        // renderCell: renderName,
      },
      {
        field: 'inception_date',
        headerName: 'Inception Date',
        type: 'date',
        valueGetter: (params) => {
          if (params.row?.inception_date) {
            return new Date(params.row.inception_date);
          }
        },
        // renderCell: renderStatus,

        flex: 0.25,
      },
      {
        field: 'total_capital',
        headerName: 'Total Capital',
        type: 'number',
        renderCell: (params) => {
          return currency_formatter.format(params.row?.total_capital);
        },
        flex: 0.25,
      },
      {
        field: 'amount',
        headerName: 'Deal Amount',
        type: 'number',
        renderCell: (params) => {
          return currency_formatter.format(params.row?.amount);
        },
        flex: 0.25,
      },
      {
        field: 'realization_date',
        headerName: 'Realization Date',
        type: 'date',
        valueGetter: (params) => {
          if (params.row?.realization_date) {
            return new Date(params.row.realization_date);
          }
        },
        flex: 0.25,
      },
      {
        field: 'realized_irr',
        headerName: 'Realized IRR',
        type: 'number',
        renderCell: (params) => {
          if (params.row?.realized_irr) {
            return `${round(params.row.realized_irr * 100, 4)}%`;
          }
        },

        flex: 0.25,
      },
      {
        field: 'realized_multiple',
        headerName: 'Realized Equity Multiple',
        type: 'number',
        renderCell: (params) => {
          if (params.row?.realized_multiple) {
            return `${params.row.realized_multiple}x`;
          }
        },

        flex: 0.25,
      },
      {
        field: 'total_proceeds',
        headerName: 'Total Proceeds',
        type: 'number',
        renderCell: (params) => {
          if (params.row?.total_proceeds) {
            return currency_formatter.format(params.row.total_proceeds);
          }
        },
        flex: 0.25,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              onClick={() => {
                setEditingDeal(params.row);
                setFormOpen(true);
              }}
              showInMenu
              disabled={!admin && sponsor.status === STATUSES.PENDING}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Remove'
              onClick={async () => {
                await handleDelete(params.id);
              }}
              showInMenu
              disabled={!admin && sponsor.status === STATUSES.PENDING}
            />
          );
          return actions;
        },
      },
    ],
    [currency_formatter, handleDelete, admin, sponsor]
  );

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12} lg={12}>
        <Toolbar>
          <Stack spacing={2} direction='row' alignItems='center'>
            <Button
              color='primary'
              onClick={() => {
                setEditingDeal(undefined);
                setFormOpen(true);
              }}
              disabled={!admin && sponsor.status === STATUSES.PENDING}
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <AddCircleOutlineIcon />
                <Typography variant='button' display='block'>
                  Create
                </Typography>
              </Stack>
            </Button>

            <Button
              color='inherit'
              disabled={
                selectedDeals.length !== 1 ||
                (!admin && sponsor.status === STATUSES.PENDING)
              }
              onClick={() => {
                const deal = deal_history.find(
                  (deal) => deal.entity_id === selectedDeals[0]
                );
                setEditingDeal(deal);
                setFormOpen(true);
              }}
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <EditIcon />
                <Typography variant='button' display='block'>
                  Edit
                </Typography>
              </Stack>
            </Button>

            <Button
              color='inherit'
              disabled={
                selectedDeals.length === 0 ||
                (!admin && sponsor.status === STATUSES.PENDING)
              }
              onClick={async () => {
                await Promise.all(
                  [].concat(
                    selectedDeals.map(async (deal) => {
                      await handleDelete(deal);
                    })
                  )
                );
                setSelectedDeals([]);
              }}
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <DeleteIcon />
                <Typography variant='button' display='block'>
                  Delete
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Toolbar>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div
              style={{
                flexGrow: 1,
                '& .deal-history-theme--header': {
                  backgroundColor: 'rgba(255, 7, 0, 0.55)',
                },
              }}
            >
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.entity_id}
                columns={columns}
                rows={deal_history || []}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedDeals(newSelectionModel);
                }}
                onRowSelectionModelChange={(newSelectionModel) => {
                  setSelectedDeals(newSelectionModel);
                }}
                rowSelectionModel={selectedDeals}
              />
            </div>
          </div>
        </div>
      </Grid>
      {formOpen ? (
        <ClosedDealModal
          open
          admin={admin}
          handleClose={(deal) => {
            if (deal) {
              handleModalResult(deal);
            }
            setEditingDeal(undefined);
            setFormOpen(false);
          }}
          deal={editingDeal}
          sponsor_id={sponsor?.entity_id}
        />
      ) : undefined}
    </Grid>
  );
}

export default SponsorDealHistory;
