import React, { useState } from 'react';

import {
  Typography,
  Box,
  Stack,
  IconButton,
  Tooltip,
  Button,
  Popover,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';

import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { createInvestorSponsorInquiry } from '../../../actions/investorSponsorInquiry';
import { useUser } from '../../../providers/UserContext';
import userInvestorProfileComplete from '../../../util/userInvestorProfileComplete';

function SponsorBanner({ sponsor, fetchSponsor }) {
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dont_ask_again, setDontAskAgain] = useState(false);

  const suppress_confirm = localStorage.getItem(
    'investor_sponsor_contact_message_suppress'
  );
  const popover_open = Boolean(anchorEl);

  const handleContactClick = async (event) => {
    if (suppress_confirm) {
      await createInquiry();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const createInquiry = async () => {
    setLoading(true);
    setAnchorEl(null);
    if (!suppress_confirm && dont_ask_again) {
      localStorage.setItem(
        'investor_sponsor_contact_message_suppress',
        dont_ask_again
      );
    }
    await createInvestorSponsorInquiry(sponsor.entity_id);
    await fetchSponsor();
    setLoading(false);
  };

  return (
    <Box
      sx={{
        height: '300px',
        color: 'white',
        alignItems: 'center',
      }}
    >
      <Grid container alignItems='center' height={'100%'}>
        <Stack direction='row' spacing={1} alignItems={'center'}>
          <img
            style={{
              borderRadius: '16px',
              width: '150px',
              height: '150px',
              maxWidth: '100%',
              maxHeight: '100%',
              backgroundColor: 'white',
            }}
            alt='logo'
            src={sponsor.logo_square}
          />

          <Stack direction='column' spacing={1}>
            <Typography variant='h3'>{sponsor.name}</Typography>
            <Stack
              component={Link}
              color={'white'}
              to={sponsor.web}
              target='_blank'
              direction='row'
              spacing={1}
            >
              <OpenInNewOutlinedIcon fontSize='small' />
              <Typography variant='body1'>{sponsor.web}</Typography>
            </Stack>
            {sponsor.investor_inquiry ||
            user.primary_sponsor === sponsor.entity_id ? (
              <Stack direction='row' color={'white'} spacing={1}>
                <IconButton
                  component={Link}
                  to={`tel:${sponsor.phone}`}
                  target='_blank'
                  sx={{ color: 'white' }}
                >
                  <PhoneOutlinedIcon fontSize='small' />
                </IconButton>
                <IconButton
                  component={Link}
                  to={`mailto:${sponsor.email}`}
                  target='_blank'
                  sx={{ color: 'white' }}
                >
                  <EmailOutlinedIcon fontSize='small' />
                </IconButton>
              </Stack>
            ) : userInvestorProfileComplete(user) ? (
              <Box>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  endIcon={<ContactMailOutlinedIcon />}
                  onClick={handleContactClick}
                >
                  Contact
                </LoadingButton>
                <Popover
                  // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                  sx={{ zIndex: 1200 }}
                  open={popover_open}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  transition
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Paper sx={{ maxWidth: 600, overflow: 'auto' }}>
                    <DialogTitle>
                      {'Send sponsor your investor profile?'}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Contacted sponsors have access to your contact
                        information and all information provided in your
                        investor profile. Once your information is submitted,
                        allow the sponsor a few business days to review your
                        request.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setAnchorEl(null);
                          setDontAskAgain(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button onClick={createInquiry}>Yes</Button>
                    </DialogActions>
                    <DialogActions>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dont_ask_again}
                            onChange={() => {
                              setDontAskAgain(!dont_ask_again);
                            }}
                          />
                        }
                        label='Do not ask me again.'
                      />
                    </DialogActions>
                  </Paper>
                </Popover>
              </Box>
            ) : (
              <Box>
                <Tooltip
                  title={`Your investor profile is not complete. Update your investor
              details on the Account Settings page to
              contact sponsors.`}
                >
                  <span>
                    <Button
                      variant='contained'
                      endIcon={<ContactMailOutlinedIcon />}
                      sx={{
                        color: 'white',
                        backgroundColor: 'grey',
                        pointerEvents: 'none',
                      }}
                    >
                      Contact
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Box>
  );
}

export default SponsorBanner;
