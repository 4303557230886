import api from '../apis/api';

export const getSponsorDealHistory = async (sponsor_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}/history`);
  return resp.data;
};

export const getSponsorClosedDeal = async (sponsor_id, deal_id) => {
  const resp = await api.get(`/sponsor/${sponsor_id}/history/${deal_id}`);
  return resp.data;
};

export const createSponsorClosedDeal = async (sponsor_id, values) => {
  const resp = await api.post(`/sponsor/${sponsor_id}/history`, values);
  return resp.data;
};

export const updateSponsorClosedDeal = async (sponsor_id, deal_id, values) => {
  const resp = await api.put(
    `/sponsor/${sponsor_id}/history/${deal_id}`,
    values
  );
  return resp.data;
};

export const deleteSponsorClosedDeal = async (sponsor_id, deal_id) => {
  const resp = await api.delete(`/sponsor/${sponsor_id}/history/${deal_id}`);
  return resp.data;
};
