import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import SponsorOwnerField from './SponsorOwnerField';
import { adminSetSponsorOwner } from '../../../actions/adminSponsor';

function SponsorOwnerDialog({ sponsor, open, handleClose, updateSponsor }) {
  const [owner, setOwner] = useState(sponsor?.owner || '');
  const [loading, setLoading] = useState(false);

  const updateOwner = async () => {
    if (owner?.entity_id !== sponsor?.owner?.entity_id) {
      const updated_sponsor = await adminSetSponsorOwner(
        sponsor.entity_id,
        owner.entity_id
      );

      updateSponsor(updated_sponsor);
    }
  };

  if (!sponsor) {
    return (
      <Dialog onClose={() => handleClose()} open={open}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              Sponsor Not Found
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid xs>{sponsor.name}</Grid>
          <Grid xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            display: 'flex',
            maxWidth: 1000,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid xs={12} md={12} lg={12}>
              <SponsorOwnerField value={owner} setValue={setOwner} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button color='inherit' onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await updateOwner();
              handleClose();
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SponsorOwnerDialog;
