import React, { useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import { Paper, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import UploadButton from '../../../shared/UploadButton';
import { putAsset } from '../../../../actions/assets';
import { LoadingButton } from '@mui/lab';
import getFile from '../../../../util/getFile';

export default function DealImageUploadField({ deal_id, addImage, disabled }) {
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const handleAdd = async () => {
    setLoading(true);
    const suffix = image.name.split('.')[1];
    const entity_id = createId();
    const file_name = suffix ? `${entity_id}.${suffix}` : entity_id;
    const path = await putAsset(
      `deal/${deal_id}/${file_name}`,
      image.type,
      image.contents
    );

    addImage({
      entity_id,
      url: `${process.env.REACT_APP_API_URL}/v1/static/${path}`,
    });
    setImage(undefined);
    setLoading(false);
  };

  const handleChange = async (e) => {
    const image = await getFile(e.target.files[0]);
    setImage(image);
  };

  if (image) {
    return (
      <Grid container spacing={2}>
        <Grid xs={12} md={6} lg={6}>
          <img
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
            }}
            alt='uploaded'
            src={image.base64contents}
          />
        </Grid>
        <Grid xs={12} md={6} lg={6} display='flex' alignItems='center'>
          <Stack spacing={4}>
            <UploadButton
              name='Change Image'
              variant='outlined'
              onChange={handleChange}
            />
            <LoadingButton
              loading={loading}
              onClick={handleAdd}
              variant='contained'
              color='primary'
              disabled={disabled}
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Paper
        variant='outlined'
        sx={{
          width: '250px',
          height: '250px',
          alignContent: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UploadButton
          name='Add New Image'
          onChange={handleChange}
          disabled={disabled}
        />
      </Paper>
    </>
  );
}
