import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Button,
  Avatar,
  CardHeader,
} from '@mui/material';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useState } from 'react';

function SponsorTeamMemberCard({ team_member, actions }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card sx={{ width: '100%', maxWidth: 500 }} variant='outlined'>
      <CardHeader
        avatar={
          <Avatar
            // sx={{ bgcolor: red[500] }}
            aria-label='team member'
            alt={team_member.first_name}
            src={team_member.picture}
            sx={{ width: 56, height: 56 }}
          ></Avatar>
        }
        title={`${team_member.first_name} ${team_member.last_name}`}
        subheader={`${team_member.title}`}
      />

      <CardContent>
        {team_member?.bio?.length > 150 ? (
          <>
            <Typography
              sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
              variant='body2'
            >
              {expanded
                ? team_member.bio
                : `${team_member.bio.substring(0, 150)}...`}
            </Typography>
            <Button onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Less' : 'More'}
            </Button>
          </>
        ) : (
          <Typography
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
            variant='body2'
          >
            {team_member.bio}
          </Typography>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton href={team_member.linkedin} aria-label='linkedin'>
          <LinkedInIcon />
        </IconButton>
      </CardActions>
      {actions ? <CardActions>{actions}</CardActions> : undefined}
    </Card>
  );
}

export default SponsorTeamMemberCard;
