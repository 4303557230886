import React, { useEffect, useState, useMemo, useCallback } from 'react';

import {
  Container,
  Typography,
  Toolbar,
  Stack,
  // Button,
  CircularProgress,
  Tooltip,
  Alert,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGrid,
  GridActionsCellItem,
  // GridToolbarContainer,
  // GridToolbarColumnsButton,
  // GridToolbarFilterButton,
} from '@mui/x-data-grid';

import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../../providers/UserContext';
import LoadingMask from '../../shared/LoadingMask';
import STATUSES from '../../shared/statuses';
import {
  deleteInvestorSponsorInquiry,
  getInvestorSponsorInquiries,
} from '../../../actions/investorSponsorInquiry';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import userInvestorProfileComplete from '../../../util/userInvestorProfileComplete';

const sortInquiries = (inquiries) => {
  return inquiries.sort((a, b) => a?.created_at - b?.created_at);
};

function Activity() {
  const user = useUser();
  const navigate = useNavigate();
  const [inquiries, setInquiries] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [selectedInquiries, setSelectedInquiries] = useState([]);
  const [transitioningInquiries, setTransitioningInquiries] = useState([]);

  useEffect(() => {
    if (user.entity_id) {
      if (!inquiries) {
        getInvestorSponsorInquiries().then((payload) => {
          setInquiries(sortInquiries(payload));
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [user, inquiries]);

  const removeInquiry = useCallback(async (inquiry_id) => {
    setTransitioningInquiries((prevTransitioningInquiries) => [
      ...prevTransitioningInquiries,
      inquiry_id,
    ]);
    await deleteInvestorSponsorInquiry(inquiry_id);
    setInquiries((prevInquiries) =>
      prevInquiries.filter((inquiry) => inquiry.entity_id !== inquiry_id)
    );
    setTransitioningInquiries((prevTransitioningInquiries) =>
      prevTransitioningInquiries.filter(
        (transitioning_inquiry_id) => transitioning_inquiry_id !== inquiry_id
      )
    );
  }, []);

  const renderStatus = useCallback(
    (params) => {
      if (transitioningInquiries.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      if (params.row.status === STATUSES.ACCEPTED) {
        return (
          <Tooltip title='Approved'>
            <CheckCircleOutlineIcon color='success' />
          </Tooltip>
        );
      }
      if (params.row.status === STATUSES.REJECTED) {
        return (
          <Tooltip title='Not Approved'>
            <CancelOutlinedIcon color='error' />
          </Tooltip>
        );
      }
      return (
        <Tooltip title='Pending Approval'>
          <PendingOutlinedIcon />
        </Tooltip>
      );
    },
    [transitioningInquiries]
  );

  const renderName = useCallback(
    (params) => {
      return (
        <Typography
          component={Link}
          onClick={() => {
            navigate(`/sponsor/${params.row.sponsor?.entity_id}`);
          }}
          variant='body2'
        >
          {params.row.sponsor?.name}
        </Typography>
      );
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 2,
        renderCell: renderName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        type: 'string',
        // renderCell: renderCreatedDate,
        minWidth: 150,
        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              icon={<DeleteOutlinedIcon color='error' />}
              label='Remove'
              onClick={() => removeInquiry(params.id)}
              showInMenu
            />
          );
          return actions;
        },
      },
    ],
    [renderName, renderStatus, removeInquiry]
  );

  // const InvestorInquiriesGridToolbar = useCallback(() => {
  //   return (
  //     <GridToolbarContainer>
  //       <Stack direction='row' spacing={1}>
  //         <Button
  //           variant='outlined'
  //           color='error'
  //           disabled={!selectedInquiries.length}
  //           onClick={async () => {
  //             await Promise.all(
  //               [].concat(
  //                 selectedInquiries.map(async (inquiry_id) => {
  //                   await removeInquiry(inquiry_id);
  //                 })
  //               )
  //             );
  //           }}
  //         >
  //           <Stack direction='row' spacing={1} alignItems='center'>
  //             <DeleteOutlinedIcon />
  //           </Stack>
  //         </Button>
  //         <GridToolbarColumnsButton variant='outlined' color='inherit' />
  //         <GridToolbarFilterButton variant='outlined' color='inherit' />
  //       </Stack>
  //     </GridToolbarContainer>
  //   );
  // }, [selectedInquiries, removeInquiry]);

  if (loading) {
    return <LoadingMask />;
  }
  if (userInvestorProfileComplete(user)) {
    return (
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={4}>
          <Grid xs={12} md={12} lg={12}>
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <Typography variant='h4'>Sponsor Inquiries</Typography>
              </Stack>
            </Toolbar>
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            {inquiries.length ? (
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      autoHeight
                      checkboxSelection
                      getRowId={(row) => row.entity_id}
                      columns={columns}
                      rows={inquiries || []}
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectedInquiries(newSelectionModel);
                      }}
                      onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectedInquiries(newSelectionModel);
                      }}
                      rowSelectionModel={selectedInquiries}
                      // slots={{
                      //   toolbar: InvestorInquiriesGridToolbar,
                      // }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Alert severity='info'>
                You have not contacted any sponsors.{' '}
                <Link to='/search/sponsors'>Search for sponsors</Link> to work
                with and send your profile.
              </Alert>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={4}>
          <Grid xs={12} md={12} lg={12}>
            <Alert severity='warning'>
              Your investor profile is not complete. Update your investor
              details on the <Link to='/account'>Account Settings</Link> page to
              contact sponsors.
            </Alert>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Activity;
