import React from 'react';

import { Typography, Stack, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SponsorTeamMemberCard from '../../Sponsors/SponsorForm/SponsorTeam/SponsorTeamMembers/SponsorTeamMemberCard';

function DealManagement({ deal }) {
  return (
    <Grid container spacing={1}>
      <Grid xs={12} md={12} lg={12}>
        <Stack direction='row' spacing={1} alignItems={'center'}>
          <img
            style={{
              borderRadius: '8px',
              width: '50px',
              height: '50px',
              maxWidth: '100%',
              maxHeight: '100%',
              backgroundColor: 'white',
            }}
            alt='logo'
            src={deal?.sponsor?.logo_square}
          />

          <Stack direction='column' spacing={1}>
            <Typography variant='h6'>{deal?.sponsor?.name}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Track Record</Typography>
      </Grid>
      <Grid xs={12} md={6} lg={6}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>
            Amount of Active Investments
          </Typography>
          <Typography variant='body1'>
            $
            {new Intl.NumberFormat('en-US').format(
              deal?.sponsor?.active_investments_amount
            )}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={6} lg={6}>
        <Stack direction='column' spacing={1}>
          <Typography variant='subtitle2'>
            Amount of Realized Investments
          </Typography>
          <Typography variant='body1'>
            $
            {new Intl.NumberFormat('en-US').format(
              deal?.sponsor?.realized_investments_amount
            )}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <Typography variant='h6'>Team</Typography>
      </Grid>
      <Grid container spacing={2} xs={12} md={12} lg={12}>
        {deal.team_members?.map((team_member) => {
          return (
            <Grid xs={12} md={6} lg={4}>
              <SponsorTeamMemberCard team_member={team_member} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default DealManagement;
