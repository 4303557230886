import React from 'react';

import { Container, Box } from '@mui/material';

import { useUser } from '../../../providers/UserContext';
import ActivateSponsor from './ActivateSponsor';
import ManageSponsor from './ManageSponsor';

function AccountSponsor() {
  const user = useUser();

  if (user.primary_sponsor) {
    return (
      <Container sx={{ padding: '15px' }}>
        <Box sx={{ width: '100%' }}>
          <ManageSponsor />
        </Box>
      </Container>
    );
  } else {
    return (
      <Container sx={{ padding: '15px' }}>
        <Box sx={{ width: '100%' }}>
          <ActivateSponsor />
        </Box>
      </Container>
    );
  }
}

export default AccountSponsor;
