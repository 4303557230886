import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useGridColumnCount = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  let columnCount = 4;
  if (isXs) {
    columnCount = 1;
  } else if (isSm) {
    columnCount = 2;
  } else if (isMd) {
    columnCount = 3;
  }

  return columnCount;
};